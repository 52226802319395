import React, { useState, useEffect } from "react";

import { IoTrashBin } from "react-icons/io5";
import { FaChartBar } from "react-icons/fa";
import { toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import {
  getAdminLogs,
  getUserLogs,
  getReviewsLogs,
  getNewsLogs,
  deleteAdminLog,
  pastHoursLogs,
  customHoursLogs,
  getAdsLogs,
  reset,
} from "../../Redux/logsReducer";

import { getBrands } from "../../Redux/BrandsReducer";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Logs = () => {
  const dispatch = useDispatch();

  const { brands } = useSelector((state) => state.brands);

  const [brand, setBrand] = useState("");

  const [mobileBrands, setMobileBrands] = useState([]);

  const [orignalLogs, setOrignalLogs] = useState([]);

  const [filteredLogs, setFilteredLogs] = useState([]);

  const [adminLogsHook, setAdminLogs] = useState([]);

  const [newsLogsHook, setNewsLogs] = useState([]);

  const {
    adminLogs,
    userLogs,
    newsLogs,
    reviewsLogs,
    adsLogs,
    status,
    message,
    isDeleted,
    logsByDate,
    hoursBefore,
  } = useSelector((state) => state.logs);

  useEffect(() => {
    if (logsByDate) setFilteredLogs(logsByDate);
  }, [logsByDate]);

  useEffect(() => {
    if (hoursBefore) setFilteredLogs(hoursBefore);
  }, [hoursBefore]);

  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    setMobileBrands(brands);
  }, [brands, dispatch]);

  useEffect(() => {
    dispatch(getAdminLogs());
    dispatch(getUserLogs());
    dispatch(getNewsLogs());
    dispatch(getReviewsLogs());
    dispatch(getAdsLogs());
  }, [dispatch]);

  useEffect(() => {
    if (userLogs.length > 0) {
      setOrignalLogs(userLogs);
    }
  }, [userLogs]);

  useEffect(() => {
    if (adminLogs.length > 0) {
      setAdminLogs(adminLogs);
    }
  }, [adminLogs]);

  useEffect(() => {
    if (newsLogs.length > 0) {
      setNewsLogs(newsLogs);
    }
  }, [newsLogs]);

  useEffect(() => {
    if (isDeleted) {
      toast.success(message);
      toast.success("REFRESH TO SEE UPDATED RECOD");
      dispatch(reset());
      // window.location.reload("/");
    }
  }, [isDeleted, message]);

  function handleBrands(value) {
    const filtered = orignalLogs.filter((item) => {
      const parts = item.url.split("/");

      const brandsExtracted = parts[3];

      if (brandsExtracted === value) {
        return item;
      }
    });

    setBrand(value);

    setFilteredLogs(filtered);
  }

  function handleDate(value) {
    if (filteredLogs.length === 0) {
      const newFilter = [...orignalLogs].sort((a, b) =>
        value === "ASC"
          ? new Date(a.activityTime) - new Date(b.activityTime)
          : new Date(b.activityTime) - new Date(a.activityTime)
      );

      setOrignalLogs(newFilter);
    } else {
      const newFilter = [...filteredLogs].sort((a, b) =>
        value === "ASC"
          ? new Date(a.activityTime) - new Date(b.activityTime)
          : new Date(b.activityTime) - new Date(a.activityTime)
      );

      setFilteredLogs(newFilter);
    }
  }

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(true);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen5, setIsDropdownOpen5] = useState(false);

  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    setIsDropdownOpen1(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
  };
  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
    setIsDropdownOpen2(false);
    setIsDropdownOpen1(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen5(false);
  };
  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen1(false);
    setIsDropdownOpen5(false);
  };
  const toggleDropdown5 = () => {
    setIsDropdownOpen5(!isDropdownOpen4);
    setIsDropdownOpen2(false);
    setIsDropdownOpen4(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen1(false);
  };
  useEffect(() => {
    dispatch(getAdminLogs());
  }, [dispatch]);

  function handlePastHourLog(value) {
    dispatch(pastHoursLogs(value));
  }

  function handleDateAndTime(value) {
    dispatch(customHoursLogs(value));
  }

  // PAGINATION FOR TABLE #1

  const [currentPage1, setCurrentPage1] = useState(1);
  const itemsPerPage1 = 5;
  const displayItems1 = adminLogsHook;
  const currentItems1 = displayItems1.slice(
    (currentPage1 - 1) * itemsPerPage1,
    currentPage1 * itemsPerPage1
  );

  const onChange1 = (page) => {
    setCurrentPage1(page);
  };

  // PAGINATION FOR TABLE #2

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const displayItems = filteredLogs.length ? filteredLogs : orignalLogs;
  const currentItems = displayItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const onChange = (page) => {
    setCurrentPage(page);
  };

  // PAGINATION FOR TABLE #3

  const [currentPage2, setCurrentPage2] = useState(1);
  const itemsPerPage2 = 5;
  const displayItems2 = newsLogsHook;
  const currentItems2 = displayItems2.slice(
    (currentPage2 - 1) * itemsPerPage2,
    currentPage2 * itemsPerPage2
  );

  const onChange2 = (page) => {
    setCurrentPage2(page);
  };

  // PAGINATION FOR TABLE #4

  const [currentPage3, setCurrentPage3] = useState(1);
  const itemsPerPage3 = 5;
  const displayItems3 = reviewsLogs;
  const currentItems3 = displayItems3.slice(
    (currentPage3 - 1) * itemsPerPage3,
    currentPage3 * itemsPerPage3
  );

  const onChange3 = (page) => {
    setCurrentPage3(page);
  };

  // PAGINATION FOR TABLE #5

  const [currentPage4, setCurrentPage4] = useState(1);
  const itemsPerPage4 = 5;
  const displayItems4 = adsLogs;
  const currentItems4 = displayItems4.slice(
    (currentPage4 - 1) * itemsPerPage4,
    currentPage4 * itemsPerPage4
  );

  const onChange4 = (page) => {
    setCurrentPage4(page);
  };

  return (
    <div>
      <div>
        <h1 className="top-heading text-3xl font-semibold text-blue-950 px-3 mb-2 mt-2">
          History log
        </h1>
      </div>

      {/* BUTTONS */}

      <button
        onClick={toggleDropdown1}
        className="inline-flex  btn active:border active:border-black active:border-solid mt-2  items-center justify-between  lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>ADMIN Login </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown2}
        className="inline-flex   btn active:border active:border-black active:border-solid mt-2  items-center justify-between  lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>Mobile Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown3}
        className="inline-flex  btn active:border active:border-black active:border-solid mt-2  items-center justify-between  lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>News Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown4}
        className="inline-flex   btn active:border active:border-black active:border-solid mt-2  items-center justify-between lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>Reviews Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown5}
        className="inline-flex   btn active:border active:border-black active:border-solid mt-2  items-center justify-between lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>Adds Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <Link
        to="/record"
        className=" fixed right-10 inline-flex   btn  hover:bg-red-600  mt-2  items-center justify-between lg:w-[9vw]  w-[10vw] h-[50px] bg-red-500 text-white text-sm px-4 rounded mb-2"
      >
        Total Clicks
        <FaChartBar className="text-4xl" />
      </Link>

      {/* Admin log */}

      {isDropdownOpen1 && (
        <TableContainer component={Paper}>
          <Table sx={{ overflow: "hidden" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ backgroundColor: "rgb(75,85,99)" }}
                  align="left"
                  className="w-1/4"
                >
                  NAME
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "rgb(75,85,99)" }}
                  align="left"
                  className="w-1/4"
                >
                  ACTIVITY TIME
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "rgb(75,85,99)" }}
                  align="left"
                  className="w-1/4"
                >
                  OPERATION
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "rgb(75,85,99)" }}
                  align="left"
                  className="w-1/4"
                >
                  DELETE
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {status === "loading"
                ? "Loading"
                : currentItems1.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell
                        align="left"
                        scope="row"
                        className="w-1/4"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="w-1/4">
                        {row.activityTime.split("T")[0]}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="w-1/4">
                        {row.operation}
                      </StyledTableCell>
                      <StyledTableCell align="left" className="w-1/4">
                        <p>DELETE LOG</p>
                        <IoTrashBin
                          onClick={() => dispatch(deleteAdminLog(row._id))}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
            <Pagination
              current={currentPage1}
              total={displayItems1.length}
              pageSize={itemsPerPage1}
              onChange={onChange1}
            />
          </Table>
        </TableContainer>
      )}

      {/* Mobile log */}

      {isDropdownOpen2 && (
        <div className="dropDown">
          {/* FILTERS */}
          <div>
            <h2 className="underline">Filters</h2>
          </div>
          <div className="mb-4">
            <div className="w-[200px]  inline-flex   justify-between h-10 mt-6 mx-3 text-[18px] bg-ray-700 px-2 text-white-500 ">
              <select
                className=" bg-gray-300  text-blac text-[17px]"
                value={brand}
                onChange={(e) => handleBrands(e.target.value)}
              >
                <option value="">Select Brand</option>
                {mobileBrands.map((item) => (
                  <option key={item.brand} value={item.brand}>
                    {item.brand}
                  </option>
                ))}
              </select>
            </div>

            <div className=" w-[200px] inline-flex  mx-3 border-black  justify-between h-10 mt-6 text-[18px] ">
              <select
                className="w-[175px] border-black  bg-gray-300  text-blac text-[17px]"
                onChange={(e) => handlePastHourLog(e.target.value)}
              >
                <option value="">SORT BY TIME</option>
                <option value="1">1 HOUR</option>
                <option value="2">2 HOUR</option>
                <option value="3">3 HOUR</option>
                <option value="4">4 HOUR</option>
                <option value="5">5 HOUR</option>
              </select>
            </div>

            <div className=" w-[200px] inline-flex   border-black  justify-between h-10 mt-6 text-[18px] ">
              <select
                className="w-[175px] border-black  bg-gray-300  text-blac text-[17px]"
                onChange={(e) => handleDate(e.target.value)}
              >
                <option value="">SORT BY DATE</option>
                <option value="ASC">ASSEENDING</option>
                <option value="DES">DECENDING</option>
              </select>
            </div>

            <div className=" w-[200px] inline-flex   border-black  justify-between h-10 mt-6 text-[18px] ">
              <input
                type="date"
                className="w-[175px] border-black  bg-gray-300  text-black text-[17px]"
                onChange={(e) => handleDateAndTime(e.target.value)}
              />
            </div>
          </div>
          <hr className="mb-3" />

          {/* Table */}

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500, overflow: "hidden" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    DATE
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    IP
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    REGION
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    URL
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogs.length === 0
                  ? status === "loading"
                    ? "Loading !!"
                    : currentItems.map((log, index) => (
                        <StyledTableRow key={log._id}>
                          <StyledTableCell align="left" className="w-1/4">
                            {log.activityTime.split("T")[0]}
                          </StyledTableCell>

                          <StyledTableCell align="left" className="w-1/4">
                            {log.ip}
                          </StyledTableCell>

                          <StyledTableCell align="left" className="w-1/4">
                            {log.region}
                          </StyledTableCell>

                          <StyledTableCell align="left" className="w-1/4">
                            {log.url}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                  : currentItems.map((log, index) => (
                      <StyledTableRow key={log._id}>
                        <StyledTableCell align="left">
                          {log.activityTime.split("T")[0]}
                        </StyledTableCell>

                        <StyledTableCell align="left" className="w-1/4">
                          {log.ip}
                        </StyledTableCell>

                        <StyledTableCell align="left" className="w-1/4">
                          {log.region}
                        </StyledTableCell>

                        <StyledTableCell align="left" className="w-1/4">
                          {log.url}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
              <Pagination
                current={currentPage}
                total={displayItems.length}
                pageSize={itemsPerPage}
                onChange={onChange}
              />
            </Table>
          </TableContainer>
        </div>
      )}

      {/* News log */}

      {isDropdownOpen3 && (
        <div className="dropDown">
          <TableContainer component={Paper}>
            <Table sx={{ overflow: "hidden" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    ACTIVITY TIME
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    a
                    align="left"
                    className="w-1/4"
                  >
                    REGION
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    a
                    align="left"
                    className="w-1/4"
                  >
                    URL
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    a
                    align="left"
                    className="w-1/4"
                  >
                    IP
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === "loading"
                  ? "Loading"
                  : currentItems2.map((row) => (
                      <StyledTableRow key={row._id}>
                        <StyledTableCell
                          align="left"
                          className="w-1/4"
                          component="th"
                          scope="row"
                        >
                          {row.activityTime.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {row.region}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {row.url}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {row.ip}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
              <Pagination
                current={currentPage2}
                total={displayItems2.length}
                pageSize={itemsPerPage2}
                onChange={onChange2}
              />
            </Table>
          </TableContainer>
        </div>
      )}

      {/* Review log   */}

      {isDropdownOpen4 && (
        <div className="dropDown">
          <TableContainer component={Paper}>
            <Table sx={{ overflow: "hidden" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    DATE
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    a
                    align="left"
                    className="w-1/4"
                  >
                    IP
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    a
                    align="left"
                    className="w-1/4"
                  >
                    REGION
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    a
                    align="left"
                    className="w-1/4"
                  >
                    URL
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === "loading"
                  ? "Loading"
                  : currentItems3.map((log, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          align="left"
                          className="w-1/4"
                          scope="row"
                        >
                          {log.activityTime.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {log.ip}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {log.region}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {log.url}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                <Pagination
                  current={currentPage3}
                  total={displayItems3.length}
                  pageSize={itemsPerPage3}
                  onChange={onChange3}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {/* Ads log   */}

      {isDropdownOpen5 && (
        <div className="dropDown">
          <TableContainer component={Paper}>
            <Table sx={{ overflow: "hidden" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    DATE
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    IP{" "}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    REGION
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ backgroundColor: "rgb(75,85,99)" }}
                    align="left"
                    className="w-1/4"
                  >
                    URL
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === "loading"
                  ? "Loading"
                  : currentItems4.map((log, index) => (
                      //
                      <StyledTableRow key={index}>
                        <StyledTableCell
                          align="left"
                          className="w-1/4"
                          component="th"
                          scope="row"
                        >
                          {log.activityTime.split("T")[0]}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {log.ip}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {log.region}
                        </StyledTableCell>
                        <StyledTableCell align="left" className="w-1/4">
                          {log.url}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                <Pagination
                  current={currentPage4}
                  total={displayItems4.length}
                  pageSize={itemsPerPage4}
                  onChange={onChange4}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default Logs;
