import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useEditor, EditorContent } from "@tiptap/react";
// import StarterKit from "@tiptap/starter-kit";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaParagraph, FaHeading, FaList, FaImage } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, resetImageState } from '../../../Redux/imageUploadSlice';


const categories = ["Diagnose", "Explore", "Ads", "Shortcuts", "Compare", "BlogPost"];

const WriteBlog = () => {
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [image, setImage] = useState("");
    const [about, setAbout] = useState("");
    const [author, setAuthor] = useState("");
    const [brand, setBrand] = useState("");
    const [content, setContent] = useState([{ type: "text", value: "" }]);
    const [dropdownIndex, setDropdownIndex] = useState(null);
    const { imageUrl, loading, error } = useSelector((state) => state.imageUpload);
    const [tempImage, setTempImage] = useState(null); // Temporary image for preview
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
    const fetchSubCategories = async () => {
        if (!category) {
            setSubCategories([]);
            return;
        }
        try {
            const response = await fetch(`https://mm.magma3c.com/feature/features/${category}`);
            if (response.ok) {
                const data = await response.json();
                setSubCategories(data.data || []); // Ensure we extract the `data` array
            } else {
                console.error("Failed to fetch subcategories");
            }
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    fetchSubCategories();
}, [category]);



    const handleAddBlock = (index, type) => {
        let newBlock = { type, value: "" };
        if (type === "list") {
            newBlock.value = [];
        }
        setContent([...content.slice(0, index + 1), newBlock, ...content.slice(index + 1)]);
        setDropdownIndex(null);
    };

    const handleChangeContent = (index, value) => {
        const updatedContent = [...content];
        updatedContent[index].value = value;
        setContent(updatedContent);
    };

    const handleListChange = (index, listIndex, value) => {
        const updatedContent = [...content];
        updatedContent[index].value[listIndex] = value;

        // Convert array into a valid HTML string
        const listType = updatedContent[index].listType || "ul";
        updatedContent[index].formattedList = `<${listType}>` +
            updatedContent[index].value.map((item) => `<li>${item}</li>`).join("") +
            `</${listType}>`;

        setContent(updatedContent);
    };

    const addListItem = (index) => {
        const updatedContent = [...content];
        updatedContent[index].value.push("");

        // Convert array into HTML on every addition
        const listType = updatedContent[index].listType || "ul";
        updatedContent[index].formattedList = `<${listType}>` +
            updatedContent[index].value.map((item) => `<li>${item}</li>`).join("") +
            `</${listType}>`;

        setContent(updatedContent);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const blogData = {
            featureBlogTitle: title,
            featureBlogCategory: category,
            featureBlogSubCategory: subCategory,
            featureBlogImage: image,
            featureBlogAbout: about,
            featureBlogContent: content,
            author: author,
            brand: brand,
        };

        try {
            const response = await fetch("https://mm.magma3c.com/featureBlogs/blogsFeature", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(blogData),
            });
            if (response.ok) {
                alert("Blog Posted Successfully!");
                navigate("/feature-blogs");
            } else {
                alert("Failed to post blog.");
            }
        } catch (error) {
            console.error("Error posting blog:", error);
            alert("An error occurred while posting.");
        }
    };
    const handleImageUpload = (file, index = null) => {
        dispatch(uploadImage(file)).unwrap()
            .then((uploadedUrl) => {
                if (index !== null) {
                    // ✅ Update only the specific content block
                    setContent((prevContent) => {
                        return prevContent.map((item, i) =>
                            i === index ? { ...item, value: uploadedUrl } : item
                        );
                    });
                } else {
                    // ✅ Update only the featureBlogImage
                    setImage(uploadedUrl);
                }
            })
            .catch((err) => {
                console.error("Image upload failed:", err);
            });
    };

    useEffect(() => {
        console.log("Updated Content:", content);
    }, [content]);

    const handleFileChange = (event, index = null) => {
        const file = event.target.files[0];
        if (file) {
            const localUrl = URL.createObjectURL(file); // Create a local preview URL

            if (index !== null) {
                // ✅ Store the temporary preview only for this specific image block
                setContent((prevContent) => {
                    return prevContent.map((item, i) =>
                        i === index ? { ...item, tempValue: localUrl } : item
                    );
                });

                // Upload image to Cloudinary
                handleImageUpload(file, index);
            } else {

                setImage(localUrl);

                // Upload image to Cloudinary
                handleImageUpload(file);
            }
        }
    };

    return (
        <div className="flex gap-6 p-6 bg-gray-100 min-h-screen">
            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
                <h1
                    contentEditable
                    suppressContentEditableWarning
                    className="text-3xl font-bold mb-4 border-b"
                    onBlur={(e) => setTitle(e.target.innerText)}
                >
                    Enter Blog Title Here
                </h1>

                <div className="space-y-4">
                    {content.map((block, index) => (
                        <div key={index} className=" p-3 rounded relative">
                            {block.type === "text" && (
                                <ReactQuill
                                    value={block.value}
                                    onChange={(value) => handleChangeContent(index, value)}
                                    placeholder="Write paragraph here..."
                                    className="w-full p-2 border rounded"
                                />
                            )}
                            {block.type === "image" && (
                                <div>
                                    <input
                                        type="text"
                                        className="w-full p-2 border rounded"
                                        placeholder="Image URL"
                                        value={block.value}
                                        onChange={(e) => handleChangeContent(index, e.target.value)}
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, index)}
                                        className="w-full p-2 border rounded mb-2"
                                    />
                                    {(block.tempValue || block.value) && (
                                        <img
                                            src={block.tempValue || block.value}
                                            alt={`Preview ${index}`}
                                            className="mt-2 rounded-lg shadow-sm max-w-[400px] max-h-[300px] w-auto h-auto"
                                        />
                                    )}
                                </div>
                            )}
                            {block.type === "heading" && (
                                <div>
                                    <select
                                        className="w-full p-2 border rounded mb-2"
                                        onChange={(e) => {
                                            const updatedContent = [...content];
                                            updatedContent[index].headingLevel = e.target.value;
                                            setContent(updatedContent);
                                        }}
                                    >
                                        <option value="h1">H1</option>
                                        <option value="h2">H2</option>
                                        <option value="h3">H3</option>
                                        <option value="h4">H4</option>
                                        <option value="h5">H5</option>
                                        <option value="h6">H6</option>
                                    </select>
                                    <input
                                        type="text"
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter heading text..."
                                        value={block.value}
                                        onChange={(e) => handleChangeContent(index, e.target.value)}
                                    />
                                </div>
                            )}
                            {block.type === "list" && (
                                <div>
                                    {/* Dropdown to select ordered or unordered list */}
                                    <select
                                        className="w-full p-2 border rounded mb-2"
                                        onChange={(e) => {
                                            const updatedContent = [...content];
                                            updatedContent[index].listType = e.target.value;
                                            setContent(updatedContent);
                                        }}
                                    >
                                        <option value="ul">Unordered List</option>
                                        <option value="ol">Ordered List</option>
                                    </select>

                                    {/* Render input fields for each list item */}
                                    {block.value.map((item, listIndex) => (
                                        <input
                                            key={listIndex}
                                            type="text"
                                            className="w-full p-2 border rounded mb-2"
                                            placeholder={`List item ${listIndex + 1}`}
                                            value={item}
                                            onChange={(e) => handleListChange(index, listIndex, e.target.value)}
                                        />
                                    ))}

                                    <button
                                        onClick={() => addListItem(index)}
                                        className="mt-2 bg-green-500 text-white px-3 py-1 rounded"
                                    >
                                        + Add List Item
                                    </button>
                                </div>
                            )}


                            <button
                                type="button"
                                onClick={() => setDropdownIndex(dropdownIndex === index ? null : index)} // Toggle dropdown
                                className="bg-black text-white opacity-50 hover:opacity-100 transition-opacity px-2 m-2"
                            >
                                +
                            </button>


                            {dropdownIndex === index && (
                                <div className="absolute bg-white shadow-md p-2 rounded-lg mt-2 grid grid-cols-2 gap-2 w-48">
                                    <button
                                        onClick={() => handleAddBlock(index, "text")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaParagraph className="text-xl" />
                                        <span className="text-sm">Text</span>
                                    </button>
                                    <button
                                        onClick={() => handleAddBlock(index, "heading")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaHeading className="text-xl" />
                                        <span className="text-sm">Heading</span>
                                    </button>
                                    <button
                                        onClick={() => handleAddBlock(index, "image")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaImage className="text-xl" />
                                        <span className="text-sm">Image</span>
                                    </button>
                                    <button
                                        onClick={() => handleAddBlock(index, "list")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaList className="text-xl" />
                                        <span className="text-sm">List</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-1/3 bg-white p-6 rounded-lg shadow-xl">
                <h2 className="text-xl font-semibold mb-4">Blog Settings</h2>
                <select
                    value={category}
                    onChange={(e) => {
                        setCategory(e.target.value);
                        setSubCategory(""); // Reset subcategory when category changes
                    }}
                    className="w-full p-3 border rounded-md mb-4"
                    required
                >
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                        <option key={cat} value={cat}>{cat}</option>
                    ))}
                </select>
                <select
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4"
                    required
                    disabled={!category} // Disable if no category is selected
                >
                    <option value="">Select Subcategory</option>
                    {subCategories.map((sub) => (
                        <option key={sub} value={sub.featureName}>{sub.featureName}</option>
                    ))}
                </select>
                <input
                    type="text"
                    placeholder="Feature Blog Image URL"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                    className="w-full p-3 border rounded-md mb-1"
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                    className="w-full p-1 border rounded-md mb-1 h-auto max-h-[40px] mb-1"
                    required
                />
                {(image) && (
                    <img
                        src={image}
                        alt={"Feature Blog Image"}
                        className="feature-image mt-1 rounded-lg shadow-sm mb-8"
                    />
                )}
                <textarea
                    placeholder="Short Blog Description"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4 mt-4"
                    required
                />
                <p className="italic text-sm mt-4">Author:</p>
                <input
                    type="text"
                    placeholder="Author Name"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4 h-auto max-h-[40px]"
                    required
                />
                <hr />

                {/* Brand */}
                <p className="italic text-sm mt-4">Brand (optional):</p>
                <input
                    type="text"
                    placeholder="Brand Name (if applicable)"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4 h-auto max-h-[40px]"
                />
                <hr />

                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-full p-4 bg-[#50d71e] text-white rounded-md"
                >
                    Publish Blog
                </button>
            </div>
        </div>
    );
};

export default WriteBlog;
