import React, { useState, useEffect } from "react";

import { IoTrashBin } from "react-icons/io5";
import { FaChartBar } from "react-icons/fa";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import {
  getAdminLogs,
  getUserLogs,
  deleteAdminLog,
  pastHoursLogs,
  customHoursLogs
} from "../../Redux/logsReducer";

import { getBrands } from "../../Redux/BrandsReducer";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Logs = () => {
  const dispatch = useDispatch();

  const { brands } = useSelector((state) => state.brands);

  const [brand, setBrand] = useState("");

  const [mobileBrands, setMobileBrands] = useState([]);

  const [orignalLogs, setOrignalLogs] = useState([]);

  const [filteredLogs, setFilteredLogs] = useState([]);

  const [adminLogsHook, setAdminLogs] = useState([]);

  const { adminLogs, userLogs, status, message, isDeleted } = useSelector(
    (state) => state.logs
  );

  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    setMobileBrands(brands);
  }, [brands, dispatch]);

  useEffect(() => {
    dispatch(getAdminLogs());
    dispatch(getUserLogs());
  }, []);

  useEffect(() => {
    if (userLogs.length > 0) {
      setOrignalLogs(userLogs);
    }
  }, [userLogs]);

  useEffect(() => {
    if (adminLogs.length > 0) {
      setAdminLogs(adminLogs);
    }
  }, [adminLogs]);

  useEffect(() => {
    if (message) alert(message);
  }, [message]);

  useEffect(() => {
    if (isDeleted) {
      window.location.reload("/");
    }
  }, [isDeleted]);

  function handleBrands(value) {
    const filtered = orignalLogs.filter((item) => {
      const parts = item.url.split("/");

      const brandsExtracted = parts[3];

      if (brandsExtracted === value) {
        return item;
      }
    });

    setBrand(value);

    setFilteredLogs(filtered);
  }

  function handleDate(value) {
    if (filteredLogs.length === 0) {
      const newFilter = [...orignalLogs].sort((a, b) =>
        value === "ASC"
          ? new Date(a.activityTime) - new Date(b.activityTime)
          : new Date(b.activityTime) - new Date(a.activityTime)
      );

      setOrignalLogs(newFilter);
    } else {
      const newFilter = [...filteredLogs].sort((a, b) =>
        value === "ASC"
          ? new Date(a.activityTime) - new Date(b.activityTime)
          : new Date(b.activityTime) - new Date(a.activityTime)
      );

      setFilteredLogs(newFilter);
    }
  }

  // let dispatch = useDispatch();
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(true);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);

  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    setIsDropdownOpen1(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen4(false);
  };
  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
    setIsDropdownOpen2(false);
    setIsDropdownOpen1(false);
    setIsDropdownOpen4(false);
  };
  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
    setIsDropdownOpen1(false);
  };
  useEffect(() => {
    dispatch(getAdminLogs());
  }, [dispatch]);

  useEffect(() => {
    if (message) alert(message);
  }, [message]);

  useEffect(() => {
    if (isDeleted) {
      window.location.reload("/");
    }
  }, [isDeleted]);

  function handlePastHourLog(value) {
    dispatch(pastHoursLogs(value));
  }

  function handleDateAndTime(value){
    dispatch(customHoursLogs(value));
  }

  // PAGINATION FOR TABLE #1

  const [currentPage1, setCurrentPage1] = useState(1);
  const itemsPerPage1 = 10;
  const displayItems1 = adminLogsHook;
  const currentItems1 = displayItems1.slice(
    (currentPage1 - 1) * itemsPerPage1,
    currentPage1 * itemsPerPage1
  );

  const onChange1 = (page) => {
    setCurrentPage1(page);
  };

  // PAGINATION FOR TABLE #2

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const displayItems = filteredLogs.length ? filteredLogs : orignalLogs;
  const currentItems = displayItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const onChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <div>
        <h1 className="top-heading text-3xl font-semibold text-blue-950 px-3 mb-2 mt-2">
          History log
        </h1>
      </div>

      {/* BUTTONS */}

      <button
        onClick={toggleDropdown1}
        className="inline-flex  btn active:border active:border-black active:border-solid mt-2  items-center justify-between  lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>ADMIN Login </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown2}
        className="inline-flex   btn active:border active:border-black active:border-solid mt-2  items-center justify-between  lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>Mobile Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown3}
        className="inline-flex  btn active:border active:border-black active:border-solid mt-2  items-center justify-between  lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>News Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown4}
        className="inline-flex   btn active:border active:border-black active:border-solid mt-2  items-center justify-between lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>Reviews Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <button
        onClick={toggleDropdown4}
        className="inline-flex   btn active:border active:border-black active:border-solid mt-2  items-center justify-between lg:w-[10vw]  w-[20vw] h-[50px] bg-gray-200 text-gray-700 text-sm px-4 rounded mb-2"
      >
        <span>Adds Logs </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-gray-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <Link
        to="/record"
        className=" fixed right-10 inline-flex   btn  hover:bg-red-600  mt-2  items-center justify-between lg:w-[9vw]  w-[10vw] h-[50px] bg-red-500 text-white text-sm px-4 rounded mb-2"
      >
        Today's Log
        <FaChartBar className="text-4xl" />
      </Link>

      {/* Admin log */}

      {isDropdownOpen1 && (
        <div className="dropDown">
          <div className=" flex">
            <p className=" border-2 nameh  p-2">Date</p>
            <p className=" border-2 nameh  p-2">NAME</p>
            <p className=" border-2 nameh  p-2">ID</p>
            <p className=" border-2 nameh  p-2">
              Event description
            </p>
            <p className="border-gray-400 border-2 nameh  p-2">OBJECT ID</p>
          </div>

          {status === "loading"
            ? "Loading !!"
            : currentItems1.map((log, index) => (
                <div key={index} className="flex text-[15px]">
                  <p className="name border-gray-400">{log.activityTime}</p>
                  <p className="name border-gray-400">{log.name}</p>
                  <p className="name border-gray-400">{log.uid}</p>
                  <p className="name border-gray-400">{log.operation}</p>
                  <p
                    className="flex name"
                    onClick={() => dispatch(deleteAdminLog(log._id))}
                  >
                    {log.objId}
                    <IoTrashBin />
                  </p>
                </div>
              ))}

          <Pagination
            current={currentPage1}
            total={displayItems1.length}
            pageSize={itemsPerPage1}
            onChange={onChange1}
          />
        </div>
      )}

      {/* Mobile log */}

      {isDropdownOpen2 && (
        <div className="dropDown">

          {/* FILTERS */}

          <div className="w-[200px]  inline-flex   justify-between h-10 mt-6 mx-3 text-[18px] bg-ray-700 px-2 text-white-500 ">
            <select
              className=" bg-gray-300  text-blac text-[17px]"
              value={brand}
              onChange={(e) => handleBrands(e.target.value)}
            >
              <option value="">Select Brand</option>
              {mobileBrands.map((item) => (
                <option key={item.brand} value={item.brand}>
                  {item.brand}
                </option>
              ))}
            </select>
          </div>

          <div className=" w-[200px] inline-flex  mx-3 border-black  justify-between h-10 mt-6 text-[18px] ">
            <select
              className="w-[175px] border-black  bg-gray-300  text-blac text-[17px]"
              onChange={(e) => handlePastHourLog(e.target.value)}
            >
              <option value="">SORT BY TIME</option>
              <option value="1">1 HOUR</option>
              <option value="2">2 HOUR</option>
              <option value="3">3 HOUR</option>
              <option value="4">4 HOUR</option>
              <option value="5">5 HOUR</option>
            </select>
          </div>

          <div className=" w-[200px] inline-flex   border-black  justify-between h-10 mt-6 text-[18px] ">
            <select
              className="w-[175px] border-black  bg-gray-300  text-blac text-[17px]"
              onChange={(e) => handleDate(e.target.value)}
            >
              <option value="">SORT BY DATE</option>
              <option value="ASC">ASSEENDING</option>
              <option value="DES">DECENDING</option>
            </select>
          </div>

          <div className=" w-[200px] inline-flex   border-black  justify-between h-10 mt-6 text-[18px] ">

         <input type="date" className="w-[175px] border-black  bg-gray-300  text-black text-[17px]" onChange={(e)=> handleDateAndTime(e.target.value)}/>
         
          </div>

          {/* Table */}

          <div className="flex h-10 mt-6 bg-white  text-gray-500 border-t border-b border-gray-400">
            <p className=" bg-gray-700 text-white w-[350px] border-r  py-1 border-gray-400">
              DATE
            </p>
            <p className=" bg-gray-700 text-white w-[450px] border-r  py-1 border-gray-400">
              IP
            </p>
            <p className=" bg-gray-700 text-white w-[450px] border-r  py-1 border-gray-400">
              REGION
            </p>
            <p className=" bg-gray-700 text-white w-[450px] border-r  py-1 border-gray-400">
              URL
            </p>
          </div>

          {filteredLogs.length === 0
            ? status === "loading"
              ? "Loading !!"
              : currentItems.map((log, index) => (
                  <div
                    key={index}
                    className=" flex justify-between text-sm bg-gray-100 px-2 text-gray-600 border-b border-gray-400"
                  >
                    <p className="w-[350px] border-r py-1 border-gray-400 text-[12px]">
                      {log.activityTime}
                    </p>
                    <p className="w-[450px] border-r px-2 py-1 border-gray-400 text-[12px]">
                      {log.ip}
                    </p>
                    <p className="w-[450px] border-r px-2 py-1 border-gray-400 text-[12px]">
                      {log.region}
                    </p>
                    <p className="w-[450px] border-r px-2 py-1 border-gray-400 text-[12px]">
                      {log.url}
                    </p>
                  </div>
                ))
            : currentItems.map((log, index) => (
                <div
                  key={index}
                  className="flex justify-between text-sm bg-gray-100 px-2 text-gray-600 border-b border-gray-400"
                >
                  <p className="w-[175px] border-r py-1 border-gray-400 text-[12px]">
                    {log.activityTime}
                  </p>
                  <p className="w-[450px] border-r px-2 py-1 border-gray-400 text-[12px]">
                    {log.ip}
                  </p>
                  <p className="w-[450px] border-r px-2 py-1 border-gray-400 text-[12px]">
                    {log.region}
                  </p>
                  <p className="w-[450px] border-r px-2 py-1 border-gray-400 text-[12px]">
                    {log.url}
                  </p>
                </div>
              ))}
          <Pagination
            current={currentPage}
            total={displayItems.length}
            pageSize={itemsPerPage}
            onChange={onChange}
          />
        </div>
      )}

      {/* News log */}

      {isDropdownOpen3 && (
        <div className="dropDown">
          <div className="flex justify-between h-10 mt-6 bg-white px-2 text-gray-500 border-t border-b border-gray-400">
            <p className="w-[170px]  bg-gray-700 text-white border-r  border-gray-400">
              Date
            </p>
            <p className="w-[245px]  bg-gray-700 text-white border-r  border-gray-400">
              NAME
            </p>
            <p className="w-[265px]  bg-gray-700 text-white border-r  border-gray-400">
              ID
            </p>
            <p className="w-[300px]  bg-gray-700 text-white border-r  border-gray-400">
              Event description
            </p>
            <p className="w-[180px]   bg-gray-700 text-white pt-2 px-2">
              OBJECT ID
            </p>
          </div>
        </div>
      )}

      {/* Review log   */}

      {isDropdownOpen4 && (
        <div className="dropDown">
          <div className="flex justify-between h-10 mt-6 bg-white px-2 text-gray-500 border-t border-b border-gray-400">
            <p className="w-[170px]  bg-gray-700 text-white border-r  border-gray-400">
              Date
            </p>
            <p className="w-[245px]  bg-gray-700 text-white border-r  border-gray-400">
              NAME
            </p>
            <p className="w-[265px]  bg-gray-700 text-white border-r  border-gray-400">
              ID
            </p>
            <p className="w-[300px]  bg-gray-700 text-white border-r  border-gray-400">
              Event description
            </p>
            <p className="w-[180px]   bg-gray-700 text-white pt-2 px-2">
              OBJECT ID
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logs;
