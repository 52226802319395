import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getGlossaryList } from "../../../Redux/GlossaryReducer";

const Glossary = ({ handleFormVisible }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const dispatch = useDispatch();
  const { glossaryList, status } = useSelector((state) => state.glossary);

  useEffect(() => {
    dispatch(getGlossaryList());
  }, [dispatch]);

  const handleDescription = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sortedData = [...glossaryList].sort((a, b) =>
    a.term.toLowerCase().localeCompare(b.term.toLowerCase())
  );

  return (
    <div className="mx-6 pb-5">
      <div className="flex justify-between text-gray-900">
        <h1 className="text-3xl text-gray-700 font-semibold mb-6">Glossary</h1>
        <button
          className="w-7 h-7 rounded-full bg-gray-950 hover:bg-gray-700 items-center text-white duration-300"
          onClick={handleFormVisible}
          title="add new term"
        >
          <IoMdAdd size={20} className="m-1" />
        </button>
      </div>
      {status === "loading" ? (
        <p>Loading...</p>
      ) : (
        sortedData.map((item, index) => (
          <div key={index}>
            <div
              onClick={() => handleDescription(index)}
              className="flex border-t py-2 border-gray-300 cursor-pointer duration-200"
            >
              <span className="w-[33rem] font-semibold px-2">{item.term}</span>
              <span className="w-[80rem] px-2">{item.definition}</span>
              {activeIndex === index ? (
                <FaAngleDown className="mr-4" size={20} />
              ) : (
                <FaAngleUp className="mr-4" size={20} />
              )}
            </div>
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-linear rounded-sm shadow-md shadow-gray-800 ${
                activeIndex === index ? "max-h-screen" : "max-h-0"
              }`}
            >
              <div className="flex justify-between space-x-1">
                <div
                  className="p-6 bg-gray-200 w-[45rem]"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                >
                  {/* {item.description}{" "} */}
                </div>
                <div className="bg-gray-200">
                  <div className="flex justify-end space-x-2 mx-2 mt-2">
                    <button className="hover:text-gray-950 duration-300">
                      <FiEdit size={15} />
                    </button>
                    <button className="hover:text-gray-950 duration-300">
                      <MdDeleteOutline size={20} />
                    </button>
                  </div>
                  <div
                    className="px-6 pb-3 text-sm w-[13rem]"
                    dangerouslySetInnerHTML={{ __html: item.relatedTerms }}
                  >
                    {/* {item.relatedTerms} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Glossary;
