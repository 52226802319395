import React, { useState } from "react";
import TotalMobileBrands from "./TotalMobileBrands";
import AddMobileBrand from "./AddMobileBrand";

const MobileBrandsMainSec = () => {

  const [addMobileForm, setAddMobileForm] = useState(false);

  const [formData, setFormData] = useState({
    brands: "",
    imageUrl: "",
    rating: "",
    quantity: "",
  });


  const handleAddMobileFormVisible = () => {
    setAddMobileForm(!addMobileForm);
  };


  return (
    <div>
      {addMobileForm && (
        <AddMobileBrand
          handleAddMobileFormVisible={handleAddMobileFormVisible}
          // handleSubmitform={handleSubmitform}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <TotalMobileBrands
        handleAddMobileFormVisible={handleAddMobileFormVisible}
        // mobileBrands={brands}
      />
    </div>
  );
};

export default MobileBrandsMainSec;




