import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";

export default function SimpleAlert(props) {
  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert
          severity="success"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => props.onClose(false)}
            >
              UNDO
            </Button>
          }
        >
          {props.message ? props.message : "UPDATED SUCCESSFULLY !!!"}
        </Alert>
      </Stack>
    </>
  );
}
