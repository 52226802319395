import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { totalAds, getClientsAds } from "../../Redux/BuyandSell";
import AdsCard from "../AdsCard";


const Card3 = (props) => {
  // Move all Hooks to the top
  const { uid } = useParams(); // Access the 'uid' parameter from the URL
  const dispatch = useDispatch();
  const [card, setCard] = useState(true);
  const [visible, setvisible] = useState(false);
  const [item, setItem] = useState({});
  const { clientsAds, status,totalAds } = useSelector((state) => state.buyAndSell);

  const [cardsCount, setcardsCount] = useState(false);

  const [trimArray, settrimArray] = useState([]);
  


  const cardOpen = () => {
    setCard(!card);
  };

  useEffect(() => {
    if (uid) {
      dispatch(totalAds(uid));
    }
  }, [uid, dispatch]);

  // Fetch clients' ads if not already loaded
  useEffect(() => {
    if (!clientsAds || clientsAds.length === 0) {
      dispatch(getClientsAds());
    }
  }, [clientsAds, dispatch]);

  // Fetch clients' ads if not already loaded
  useEffect(() => {
    if (totalAds || totalAds.length > 0) {
      const newAds = totalAds.slice(0, 5);
      console.log(newAds);
      settrimArray(newAds);
    }
  }, [clientsAds]);

  const toggle = () => {
    setvisible(!visible);
  };
  const showAds = () => {};

  // Add conditional rendering later
  if (!card) return null;

  return (
    <div className="  flex flex-row justify-between card mb-3 mt-[45px] bg-white rounded z-10 md:w-[70vw] w-[95vw] lg:w-[70vw] h-[auto] border border-gray-400   space-y-4 p-4">
      <div className="ml-4 pl-6 pt-7 w-[43%]">
        <span
          onClick={cardOpen}
          className="bg-[rgb(75,85,99)]/90 text-white center px-2 py-2 absolute top-2 left-2 cursor-pointer"
        >
          x
        </span>
        <div>
          <div className="mt-2">
            {props.img === " " ? (
              <img
                className="w-[120px] h-[120px] rounded-full"
                src="https://t4.ftcdn.net/jpg/00/97/00/09/360_F_97000908_wwH2goIihwrMoeV9QF3BW6HtpsVFaNVM.jpg"
                alt="Profile"
              />
            ) : (
              <img
                className="w-[120px] h-[120px] rounded-full"
                src={props.img}
                alt={`${props.name}'s Profile`}
              />
            )}
            <p className="!text-[18px] font-bold">{props.name}</p>
          </div>
          <div>
            <p className="md:!text-[17px] !text-[15px] sm:text-[13px] text-gray-600">
              <b>Client favourites:</b> {props.fav}
            </p>
            <p className="md:!text-[17px] !text-[15px] sm:text-[13px] text-gray-600">
              <b>Client following:</b> {props.followings}
            </p>
            <p className="md:!text-[17px] !text-[15px] sm:text-[13px] text-gray-600">
              <b>Client type:</b> {props.type}
            </p>
           
          </div>
        </div>
      </div>

      <div>
        <h1 className="font-bold text-[17px] underline">
          Total Ads : {totalAds.length}
        </h1>
        {visible && (
           <AdsCard
           description={item.description}
           publishedOn={item.publishedOn}
           mobileNo={item.mobileNo}
           images={item.images}
           price={item.price}
           brand={item.brand}
           title={item.title}
           area={item.area}
           lat={item.lat}
           long={item.long}
           country={item.country}
           uid={item.uid}
           condition={item.condition}
           likes={item.likes}
           views={item.views}
           toggle={toggle}
         />
        )}

        <div 
        onClick={toggle}  
         className=" grid grid-cols-3 gap-1 justify-items-end w-[100%] h-[auto%]">
          {trimArray.length >0 ? trimArray.map((item, index) => (
            <div className="cursor-pointer">
              <div
                key={index}
                className="p-5 border flex flex-col items-center border-gray-400 bg-gray-50 rounded mt-4 w-[90%] h-[auto] "
                onClick={() => {
                  setItem(item);
                  setvisible(true);
                }}
              >
                {item.images && item.images.length > 0 ? (
                  <img
                    className="h-[45px] w-[45px] object-contain m-auto mt-1 mb-2"
                    src={item.images[0]}
                    alt={item.title || "Image"}
                  />
                ) : (
                  <img
                    className="h-[45px] w-[45px] object-contain m-auto mt-1 mb-2"
                    src="/path/to/placeholder-image.jpg"
                    alt="Ad"
                  />
                )}

                
                <p className="center text-[11px] mt-[1px] p-[1px]">
                  <b>Brand</b>: {item.brand}
                </p>
                <p className="center text-[11px] mt-[1px] p-[1px]">
                  <b>Title</b>: {item.title}
                </p>
              </div>
            </div>
          )):(<div className="flex  justify-center items-center h-[300px] w-[300px]">

            <h1 className="text-[2rem]">No Ads Found</h1>






            </div>
          )}
          {totalAds.length > 6 && (
            <Link className="flex justify-items-start items-center mr-[50px]" to={`/totalAds/${props.uid}`}>
              <button >
                <b>see more...</b> 
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card3;
