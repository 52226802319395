import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";

const FeaturesBlogs = () => {
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch("https://mm.magma3c.com/featureBlogs/blogsFeature/3");
                const data = await response.json();
                setRecentBlogs(data);
            } catch (error) {
                console.error("Error fetching blogs:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchBlogs();
    }, []);

    const settings = {
        infinite: false,
        speed: 700,
        slidesToShow: Math.min(3, recentBlogs.length),
        slidesToScroll: 2,
        autoplay: false,
    };

    return (
        <>

            <div className="px-3 mt-4 rounded-md space-y-3 py-3 border h-[250px] border-gray-700 bg-white shadow-md shadow-gray-500">
                <div className="flex justify-between">
                    <h1 className="text-xl font-semibold text-gray-600 mb-2">Recent Blogs</h1>
                    <Link className="text-sm underline text-blue-500 font-semibold px-4" to="/feature-blogs/AllBlogs">
                        View all
                    </Link>
                </div>

                {loading ? (
                    <div className="h-full py-11 flex justify-center">
                        <Loader type="bubble-scale" bgColor={"#AEB9B8"} size={50} />
                    </div>
                ) : (
                    <Slider {...settings}>
                        {recentBlogs.map((blog) => (
                            <Link to={`/feature-blogs/${blog.featureBlogCategory}/${blog.featureBlogSubCategory}/${blog.featureBlogTitle}`} key={blog._id}>
                                <div className="space-x-3 hover:shadow-md hover:shadow-gray-600 duration-300 rounded-lg h-36 relative">
                                    <img
                                        src={blog.featureBlogImage || "/placeholder.jpg"}
                                        alt={blog.featureBlogTitle}
                                        className="relative rounded-lg w-full h-36 object-cover shadow-md"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-2 text-white">
                                        <h1 className="line-clamp-2 text-sm font-medium">{blog.featureBlogTitle}</h1>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </Slider>
                )}
            </div>
            <Link to="/feature-blogs/WriteBlog">
                <div className="write-blog-box px-3 mt-4 rounded-md space-y-3 py-3 border h-[250px] border-gray-700 bg-white shadow-md shadow-gray-500 cursor-pointer hover:shadow-lg transition-shadow">
                    <h2 className="text-xl font-semibold text-gray-600">Write a Blog</h2>
                    <div className="h-full py-11 flex justify-center">
                        <span className="text-4xl text-gray-500">➕</span>
                    </div>
                </div>
            </Link>
            <Link to="/feature-blogs/AddFeature">
                <div className="write-blog-box px-3 mt-4 rounded-md space-y-3 py-3 border h-[250px] border-gray-700 bg-white shadow-md shadow-gray-500 cursor-pointer hover:shadow-lg transition-shadow">
                    <h2 className="text-xl font-semibold text-gray-600">Add a Feature</h2>
                    <div className="h-full py-11 flex justify-center">
                        <span className="text-4xl text-gray-500">➕</span>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default FeaturesBlogs;
