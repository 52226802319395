import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../../Redux/BuyandSell";
import { IoSearchSharp } from "react-icons/io5";

//PAGINATION
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import Carddummy from "../../Carddummy";
import { TbListDetails } from "react-icons/tb";

import {
  searchClientByEmail,
  searchClientByEmailResetError,
} from "../../../Redux/BuyandSell"; // Import your action
import Card3 from "../Card3";
import { toast } from "react-toastify";
import { delClient } from "../../../Redux/BuyandSell";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

//  =======================  styles ==========================
const apisdatastyle =
  " text-sm text-center w-[400px] border-r border-gray-500   pr-2 pl-2 py-1";

const addresstyles =
  " text-sm text-center w-[400px] border-r border-gray-500  pr-2 pl-2 py-1 overflow-y-auto ";

const headerNameStyle = "text-sm  sm:w-[48vw]  text-center w-[400px] px-2";
//  =======================  styles ==========================

const Clients = () => {
  const dispatch = useDispatch();

  const { clients } = useSelector((state) => state.buyAndSell);

  useEffect(() => {
    if (clients.length === 0) {
      dispatch(getClients());
    }
  }, [clients, dispatch]);

  // Array of client fields

  // PAGINATION LOGIC

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);

  const [current, setcurrent] = useState([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const newCurrentItems = clients.slice(startIndex, endIndex);
    setcurrent(newCurrentItems);
  }, [currentPage, itemsPerPage, clients]);

  const onChange = (page) => {
    //on clicking next page set it to local storage

    setcurrentPage(page);
  };

  const [isOpen, setisOpen] = useState(false);

  function cardOpen(e, item) {
    setcurrentUser(item);
    setisOpen(true);
  }

  function cardClose(e, item) {
    setcurrentUser(item);
    setisOpen(false);
  }
  const [currentUser, setcurrentUser] = useState([]);

  const [email, setEmail] = useState("");

  const { user, status, error } = useSelector((state) => state.buyAndSell);

  useEffect(() => {
    if (error) {
      toast.error(error.error);
      dispatch(searchClientByEmailResetError());
    }
  }, [error]);

  const handleSearch = () => {
    if (email.trim()) {
      dispatch(searchClientByEmail(email));
    } else {
      alert("Please enter a valid email address.");
    }
  };
  const handleSearch2 = () => {
    if (email.trim()) {
      dispatch(searchClientByEmail(email));
    } else {
      alert("Please enter a valid email address.");
    }
  };

  function deleteClient(id) {
    window.confirm("Are You Sure You Want To Delete This Client?") &&
      console.log("deleted");

    const newItems = current.filter((item) => item.uid !== id);

    setcurrent(newItems);
  }
  const toggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <div className={` w-[100vw] h-auto `}>
      {/* ============searchBar=========== */}
      <div className="w-[190px] mt-3 text-[15px] flex bg-white h-8 duration-300 border-2 border-blue-100 rounded-md hover:border-blue-200">
        <input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type="text"
          placeholder="search by email"
          className="outline-none items-center text-[16px] px-2 border-r w-[190px] p-1"
        />
        <button
          type="button"
          onClick={handleSearch} // Call the handler on click
          onKeyDown={handleSearch2} // Call the handler on click
          className="bg-white pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150"
        >
          <IoSearchSharp className="active:scale-90" size={15} />
        </button>
      </div>
      <hr />

      {status === "loading" ? (
        <div>Loading...</div>
      ) : (
        <div className="border border-gray-400 h-[auto]   ">
          {user && Object.keys(user).length > 0 && (
            <Card3
              city={user.city}
              area={user.area}
              lat={user.lat}
              long={user.long}
              fullAddress={user.fullAddress}
              points={user.totalPoints}
              Ads={user.totalAds}
              time={user.registerationTime}
              type={user.type}
              num={user.number}
              followings={user.following.length}
              name={user.name}
              email={user.email}
              fav={user.favourites.length}
              img={user.imageUrl}
              uid={user.uid}
              cardOpen={cardOpen}
              cardClose={cardClose}
            />
          )}
        </div>
      )}

      <div className="   flex w-[97vw]  lg:w-[70vw] md:w-[97vw] sm:w-[97vw] text-white bg-gray-700  h-10 font-semibold  items-center">
        <span className="text-sm sm:w-[16vw]   text-center   w-[180px]  px-2">
          Details
        </span>

        <span className="text-sm sm:w-[48vw]   text-center   w-[390px]  px-2">
          Client Name
        </span>
        <span className="text-sm sm:w-[48vw] text-center  w-[390px]  px-2">
          Client Email
        </span>
        <span className={headerNameStyle}>Client city</span>
        <span className="text-sm sm:w-[48vw] text-center  w-[390px]  px-2">
          Delete
        </span>
      </div>

      {status === "loading"
        ? "Loading"
        : current.map((item, index) => (
            <div
              key={item._id}
              className={`flex w-[97vw] lg:w-[70vw] md:w-[97vw] sm:w-[97vw] text-gray-800 border-[1px] border-gray-500 ${
                index % 2 === 0 ? "bg-gray-200" : "bg-white"
              } hover:bg-gray-300 transition-colors`}
            >
              <span
                onClick={(e) => cardOpen(e, item)}
                // onMouseLeave={(e) => setisOpen(false)}
                className="px-2 border-r border-gray-500 cursor-pointer hover:text-red-500 w-[180px]"
              >
                {/* <TbListDetails /> */}
                <IoMdEye className="mt-2 ml-4" />
              </span>

              <span className="  border-r border-gray-500    lg:text-base text-sm md:text-[15px] break-all  px-1  py-3 text-center w-[48vw]  sm:w-[48vw]  md:w-[48vw]   h-[auto]">
                {item.name}
              </span>
              <span className="  border-r border-gray-500     lg:text-base text-sm md:text-[15px] break-all  px-1  py-3 text-center  w-[48vw] sm:w-[48vw] md:w-[48vw]   h-[auto]">
                {item.email}
              </span>

              <span className="  border-r border-gray-500      pr-[2px]   lg:text-base text-sm md:text-[15px]   break-allpx-1  py-3 text-center  w-[48vw]  sm:w-[48vw]  md:w-[48vw] ">
                {item.city}
              </span>
              <span
                onClick={(e) => {
                  deleteClient(item.uid);
                }}
                className=" border-r border-gray-500   hover:text-red-500 cursor-pointer pr-[2px]  lg:text-base text-sm md:text-[15px] break-all px-1 py-3 text-center w-[48vw] sm:w-[48vw] md:w-[48vw]"
              >
                Delete
              </span>
            </div>
          ))}
      {isOpen && (
        <Carddummy
          city={currentUser.city}
          area={currentUser.area}
          lat={currentUser.lat}
          long={currentUser.long}
          fullAddress={currentUser.fullAddress}
          points={currentUser.totalPoints}
          Ads={currentUser.totalAds}
          time={currentUser.registerationTime}
          type={currentUser.type}
          num={currentUser.number}
          followings={currentUser.following.length}
          name={currentUser.name}
          email={currentUser.email}
          fav={currentUser.favourites.length}
          img={currentUser.imageUrl}
          cardClose={cardClose}
          cardOpen={cardOpen}
          toggle={toggle}
        />
      )}

      <div className=" m-auto mt-4 mb-3 mx-[25rem]">
        <Pagination
          current={currentPage}
          total={clients.length}
          pageSize={itemsPerPage}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Clients;
