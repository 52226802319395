import React, { createContext, useState } from "react";

//CREATE CONTEXT VARIABLE

export const UserConetxt = createContext();



const UserProvider = ({ children }) => {

  const [users, setUsers] = useState({ name: "", email: "", role: "" });

  const [todo, setTodo] = useState([]);
  
  const [editTodo, setEdittodo] = useState(null);

  return (
    <div>

      {/* USE CONTEXT VARIABLE */}

      <UserConetxt.Provider
        value={{ users, setUsers, todo, setTodo, editTodo, setEdittodo }}
      >
        {/* THIS WILL BE APPLIED TO ALL CHILDRENS */}
        {children}
      </UserConetxt.Provider>
    </div>
  );
};

export default UserProvider;
