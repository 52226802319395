import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import image from "../../images/image.png";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Registering necessary chart elements with Chart.js for both bar and line charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Profile = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [singleAdminLog, setSingleAdminLog] = useState([]);

  const [countsForReviews, setCountsForReviews] = useState([]);
  const [datesForReviews, setDatesForReviews] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    fetchAPI();
  }, [currentPage, itemsPerPage, singleAdminLog]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://mm.magma3c.com/logs/lineGraphForSingleAdmin/${currentUser.uid}`
        ); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCountsForReviews(data.counts);
        setDatesForReviews(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForSingleAdmin = {
    labels: datesForReviews, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Actions", // Label for the dataset
        data: countsForReviews, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  // Chart options for the line chart
  const optionsForSingleAdmin = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Actions",
        },
      },
    },
  };

  async function fetchAPI() {
    const responce = await fetch(
      `https://mm.magma3c.com/logs/singleAdminLog/${currentUser.uid}`
    );
    const responceData = await responce.json();
    setSingleAdminLog(responceData.singleAdminLog);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const newCurrentItems = responceData.singleAdminLog.slice(
      startIndex,
      endIndex
    );

    setCurrent(newCurrentItems);
  }

  const onChange = (page) => {
    //on clicking next page set it to local storage

    setcurrentPage(page);
  };

  return (
    <div>
      <div className="grid md:grid-cols-[1fr_2fr] grid-cols-1 h-[100vh] ">
        <div className="flex flex-col justify-center items-start">
          <img src={image} alt="profile" className="rounded-full w-32" />
          
          <h2 className="text-4xl ">{currentUser.name}</h2>
          <div className="flex mt-8">
          <h2 className="text-xl"><b>Email : </b></h2>
          <h2 className="text-xl text-gray-600 ml-1">{currentUser.email}</h2>
          </div>
          <div className="flex mt-8">
          <h2 className="text-xl"><b>Role :</b></h2>
          <h2 className="text-xl text-green-800 ml-1">{currentUser.role}</h2>
          </div>
          <div className="flex mt-8 ">
          <h2 className="text-xl"><b>No Of Changes :</b></h2>
          <h2 className="text-xl text-green-800 ml-1">{singleAdminLog.length}</h2>
          </div>
          
        </div>

        <div  className="flex flex-col justify-evenly items-center cursor-pointer" onClick={()=>setIsOpen(true)}>
          <Line data={dataForSingleAdmin} options={optionsForSingleAdmin} />
        </div>
      </div>

      {
        isOpen &&  <div className="bg-black/90 z-50 w-full h-full inset-0 absolute" >
          <IoMdArrowRoundBack onClick={()=>{setIsOpen(false)}} className="z-[60] text-gray-600 text-6xl cursor-pointer relative left-0" />
            <div className="flex justify-center items-center">
            <TableContainer component={Paper} sx={{ width: "50%" }}>
          <Table sx={{ overflow: "hidden" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ backgroundColor: "rgb(75,85,99)" }}
                  align="left"
                  className="w-1/4"
                >
                  DATE
                </StyledTableCell>
                <StyledTableCell
                  style={{ backgroundColor: "rgb(75,85,99)" }}
                  a
                  align="left"
                  className="w-1/4"
                >
                  OPERATION
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {current.map((log, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left" className="w-1/4" scope="row">
                    {log.activityTime.split("T")[0]}
                  </StyledTableCell>
                  <StyledTableCell align="left" className="w-1/4">
                    {log.operation}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              <Pagination
                current={currentPage}
                total={singleAdminLog.length}
                pageSize={itemsPerPage}
                onChange={onChange}
              />
            </TableBody>
          </Table>
        </TableContainer>
            </div>

        </div>
      }

     


    </div>
  );
};

export default Profile;
