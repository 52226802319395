import React from "react";
// import profile from "./images/image.png"; // Placeholder profile image

const Card2= (props) => {
  return (
    <div className="fixed inset-0 z-50 bg-black/70" onClick={()=>props.toggle()} >
    <div className="overflow-y-auto text-sm fixed top-[10px] right-[30px] bg-gray-100 rounded z-10 w-[350px] h-[100vh] border border-gray-400 flex flex-col space-y-4 p-4">
        {props.cardOpen && ( <button onClick={props.cardClose} className="p-2 m-1 mb-2 font-bold text-white bg-gray-700 rounded border-1 hover:text-red-500"  >Close</button>)}
      <div className="flex flex-col items-center ">
      {/* Check if props.img is empty */}
      {props.img === " " ? (
        <p>no img found</p>
        
      ) : (
        <img
          className=" object-contain  w-[135px] h-[135px] "
          src={props.img} // Use actual image
          alt={`${props.title}'s Profile`}
        />
      )}
{/* <img className="m-auto w-[150px] h-[150px] object-contain" src={props.img} alt=" img" /> */}
      </div>
<p className="font-bold text-center">{props.title}</p>
<p className="text-gray-600">  <b> Id:</b> {props.id}</p>
      <p className="text-gray-600"> <b> Brand: </b>{props.brand}</p>
      <p className="text-gray-600"> <b>Condition:</b>  {props.condition}/10</p>
      <p className="text-gray-600">  <b> Price:</b> {props.price}</p>
      <p className="text-gray-600">  <b> Status:</b> {props.status}</p>
      <p className="text-gray-600">  <b> Description:</b> {props.des}</p>
      <p className="text-gray-600">  <b> Address:</b> {props.add}</p>
      <p className="text-gray-600">  <b> City:</b> {props.city}</p>
      <p className="text-gray-600">  <b> Area:</b> {props.area}</p>
      <p className="text-gray-600">  <b> Lat:</b> {props.long}</p>
      <p className="text-gray-600">  <b> Lat:</b> {props.lat}</p>
      <p className="text-gray-600">  <b> Published:</b> {props.published}</p>
      <p className="text-gray-600">  <b> Likes:</b> {props.likes_len}</p>
      <p className="text-gray-600">  <b> Views:</b> {props.views_len}</p>
    </div>
    </div>
  );
};

export default Card2;
