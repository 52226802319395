import { configureStore } from "@reduxjs/toolkit";

import SingleBrandReducer from "./SingleBrandReducer";

import SingleMobileDetailsReducer from "./SingleMobileDetails";

import NewsReducer from "./NewsReducer";

import brandsReducer from "./BrandsReducer";
import GlossaryReducer from "./GlossaryReducer";
import UserReducer from "./UserReducer";
import AllMobiles from "./AllMobiles";
import ImageUpload from "./ImageUpload";
import UsersReducer from "./UsersReducer";
import AnalyticsReducer from "./analytics";
import AdminLogsReducer from "./logsReducer";
import BuyAndSellReducer from "./BuyandSell";
import AdsReducer from "./AdsReducer";

const store = configureStore({
  reducer: {
    news: NewsReducer,
    singleMobileBrand: SingleBrandReducer,
    singleMobileDetails:SingleMobileDetailsReducer,
    brands:brandsReducer,
    glossary: GlossaryReducer,
    user: UserReducer,
    users:UsersReducer,
    mobiles: AllMobiles,
    imageUplaod: ImageUpload,
    analytics: AnalyticsReducer,
    logs: AdminLogsReducer,
    buyAndSell: BuyAndSellReducer,
    ads:AdsReducer
  },
});

export default store;