import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delAds, getClientsAds, changeStatus } from "../../../Redux/BuyandSell";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
//PAGINATION
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import Card2 from "../Card2";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { mobilesPerBrand } from "../../../Redux/logsReducer";
import { TbListDetails } from "react-icons/tb";

const Ads = () => {
  const dispatch = useDispatch();

  const { clientsAds, status } = useSelector((state) => state.buyAndSell);

  useEffect(() => {
    if (clientsAds.length > 0) countBrands();
  }, [clientsAds]);

  useEffect(() => {
    if (!clientsAds || clientsAds.length === 0) {
      dispatch(getClientsAds());
    }
  }, [clientsAds, dispatch]);

  function deleteADs(id) {
    window.confirm("Are You Sure You Want To Delete This Ad?") &&
      dispatch(delAds(id));

    const newItems = current.filter((item) => item._id !== id);

    setcurrent(newItems);
  }

  const [inActiveAds, setinActiveAds] = useState(0);
  const [ActiveAds, setActiveAds] = useState(0);
  const [expireAds, setexpireAds] = useState(0);
  const [soldAds, setsoldAds] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("");
  const [currentAds, setcurrentAds] = useState({});
  const [isOpen, setisOpen] = useState(false);


  // PAGINATION LOGIC

  const [currentPage, setcurrentPage] = useState(1);
  const [currentPage2, setcurrentPage2] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(5);

  const [current, setcurrent] = useState([]);
  const [filtered, setfiltered] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);

  

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const newCurrentItems = clientsAds.slice(startIndex, endIndex);
    console.log(newCurrentItems);
    setcurrent(newCurrentItems);
  }, [currentPage, itemsPerPage, clientsAds]);

  useEffect(() => {
    const startIndex = (currentPage2 - 1) * itemsPerPage;
    const endIndex = currentPage2 * itemsPerPage;
    const newCurrentItems = filtered.slice(startIndex, endIndex);
    setfiltered(newCurrentItems);
  }, [currentPage2, itemsPerPage, filtered]);

  useEffect(() => {
    const inActiveAds = clientsAds.filter((item) => item.status == 0);
    setinActiveAds(inActiveAds.length);

    const activeAds = clientsAds.filter((item) => item.status == 1);
    setActiveAds(activeAds.length);
    const expireAds = clientsAds.filter((item) => item.status == 2);
    setexpireAds(expireAds.length);
    const soldAds = clientsAds.filter((item) => item.status == 3);
    setsoldAds(soldAds.length);
  }, [clientsAds, ActiveAds, expireAds, soldAds]);

  const onChange = (page) => {
    //on clicking next page set it to local storage

    setcurrentPage(page);
  };
  const onChange2 = (page) => {
    //on clicking next page set it to local storage

    setcurrentPage2(page);
  };

  //UPDATE ALL MOBILES PAGINATION


  function cardOpen(e, item) {
    setcurrentAds(item);
    setisOpen(true);
  }
  function cardClose(e, item) {
    setcurrentAds(item);
    setisOpen(false);
  }

  const toggle = () => {
    setisOpen(!isOpen);
  };
 

  const countBrands = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    clientsAds.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.brand === brand) {
          updatedCounts[brand] += 1;
        }
      });
    });
    dispatch(mobilesPerBrand(updatedCounts));
  };

  const { brandsMobilesCount } = useSelector((state) => state.logs);

  // Define an array of colors for each bar
  const barColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#FF9F40",
    "#9966FF",
    "#FF3366",
    "#FF33CC",
    "#33FF57",
    "#FF5733",
    "#33B5E5",
    "#FFB74D",
    "#009688",
    "#E91E63",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#00BCD4",
    "#FF5722",
    "#2196F3",
    "#673AB7",
    "#9C27B0",
    "#FF9800",
    "#FFEB3B",
    "#607D8B",
    "#FF4081",
  ];

  // Preparing the data for the Bar chart
  const chartData = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Ads per Brand",
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(
          0,
          Object.keys(brandsMobilesCount).length
        ), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options for better presentation
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Mobile Ads per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Brands",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Ads Count",
        },
      },
    },
  };

  const handleChangeFilter = (e) => {
    setIsNotFound(false);
    setcurrentPage2(1); // Reset to page 1

    if (e.target.value === "all") {
      setfiltered(clientsAds);
    } else {
      const filteredArr = clientsAds.filter(
        (item) => item.status == e.target.value
      );
      if (filteredArr.length === 0) {
        setIsNotFound(true);
        toast.error("Nothing Found!!!");
      }

      switch (e.target.value) {
        case "all":
          setCurrentFilter("all");
          break;

        case "0":
          setCurrentFilter("inActive");
          break;

        case "1":
          setCurrentFilter("Active");
          break;

        case "2":
          setCurrentFilter("sold");
          break;

        case "3":
          setCurrentFilter("expire");
          break;
        default:
          console.log("No filter applied");
      }

      setfiltered(filteredArr);
    }
  };

  return (
    <div className="md:w-[75vw] lg:w-[75vw] sm:w-[75vw] w-[75vw] mt-4 overflow-x-hidden h-[100vh]">
      <select
        className="p-3  mb-3"
        name="filter"
        id=""
        onChange={handleChangeFilter}
      >
        <option value="all">ALL</option>
        <option value="1">active</option>
        <option value="0">inActive</option>
        <option value="3">sold</option>
        <option value="2">expire</option>
      </select>
      <div className="inline-flex gap-2 w-[200px] border-black text-left border-1   p-3 m-2 ml-4 ">
        <span>Active:</span>
        <span>{ActiveAds}</span>
      </div>
      <div className="inline-flex gap-2 w-[200px] border-black text-left border-1    p-3 m-2 ml-4 ">
        <span>InActive:</span>
        <span>{inActiveAds}</span>
      </div>
      <div className="inline-flex gap-2  w-[200px] border-black text-left border-1    p-3 m-2 ml-4 ">
        <span>ExpireAds:</span>
        <span>{expireAds}</span>
      </div>
      <div className="inline-flex gap-2 w-[200px] border-black text-left border-1    p-3 m-2 ml-4 ">
        <span>SoldAds:</span>
        <span>{soldAds}</span>
      </div>

      {/* =================ads header==================  */}
      <div
        className="flex items-center justify-between text-xs font-semibold text-white bg-gray-700 sm:text-sm md:text-base h-14"
        // style={{ minWidth: "3500px" }}
      >
        <span className="md:w-[6%] text-sm text-center sm:w-[6%] md:text-sm w-[6%]">
          Detail{" "}
        </span>

        <span className="md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%]">
          {" "}
          Ads Title
        </span>

        <span className="md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%]">
          Ad Brand
        </span>

        <span className="md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%]">
          Ad condition in no
        </span>
        <span className="md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%]">
          {" "}
          ad price
        </span>
        <span className="md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%]">
          {" "}
          ad Delete
        </span>
        <span className="md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%]">
          {" "}
          Edit
        </span>
      </div>

      {/* =================ads body==================  */}

      {status === "loading" ? (
        "Loading !!"
      ) : isNotFound ? (
        <div>Nothing found !!</div>
      ) : filtered.length > 0 ? (
        <>
          {filtered.map((item, index) => (
            <div
              key={item._id}
              className={`flex text-gray-800 justify-between border-[1px] border-gray-500 ${
                index % 2 === 0 ? "bg-gray-200" : "bg-white"
              }`}
            >
              <span
                onClick={(e) => cardOpen(e, item)}
                className="center sm:w-[6%] hover:text-red-500 md:w-[6%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[6%] py-1"
              >
                <IoMdEye className="ml-4" />
              </span>

              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                {item.title}
              </span>
              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                {item.brand}
              </span>
              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                <option value="">{item.conditionInNum}/10</option>
              </span>

              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                {item.price}
              </span>
              <span
                onClick={() => deleteADs(item._id)}
                className="hover:text-red-500 cursor-pointer sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1"
              >
                Delete
              </span>
              <span
                onClick={() => changeStatus(item._id, item.status)}
                className=" cursor-pointer md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%] bg-transparent pr-2 pl-2"
              >
                <select
                  className=" bg-transparent"
                  name="filter"
                  id=""
                  onChange={(e) =>
                    dispatch(changeStatus(item._id, e.target.value))
                  }
                >
                  <option value="" disabled selected>
                    EDIT
                  </option>
                  <option value="1">active</option>
                  <option value="0">inActive</option>
                  <option value="3">sold</option>
                  <option value="2">expire</option>
                </select>
              </span>
            </div>
          ))}

          <div className="mt-4 px-72">
            {currentFilter === "all" && (
              <Pagination
                current={currentPage2}
                total={clientsAds.length}
                pageSize={itemsPerPage}
                onChange={onChange2}
              />
            )}

            {currentFilter === "inActive" && (
              <Pagination
                current={currentPage2}
                total={clientsAds.filter((item) => item.status == 0).length}
                pageSize={itemsPerPage}
                onChange={onChange2}
              />
            )}

            {currentFilter === "Active" && (
              <Pagination
                current={currentPage2}
                total={clientsAds.filter((item) => item.status == 1).length}
                pageSize={itemsPerPage}
                onChange={onChange2}
              />
            )}

            {currentFilter === "sold" && (
              <Pagination
                current={currentPage2}
                total={clientsAds.filter((item) => item.status == 2).length}
                pageSize={itemsPerPage}
                onChange={onChange2}
              />
            )}

            {currentFilter === "expired" && (
              <Pagination
                current={currentPage2}
                total={clientsAds.filter((item) => item.status == 3).length}
                pageSize={itemsPerPage}
                onChange={onChange2}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {current.map((item, index) => (
            <div
              key={item._id}
              className={`flex text-gray-800 justify-between border-[1px] border-gray-500 ${
                index % 2 === 0 ? "bg-gray-200" : "bg-white"
              }`}
            >
              <span
                onClick={(e) => cardOpen(e, item)}
                className="center sm:w-[6%] hover:text-red-500 md:w-[6%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[6%] py-1"
              >
                <IoMdEye className="ml-4" />
              </span>

              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                {item.title}
              </span>
              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                {item.brand}
              </span>
              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                <option value="">{item.conditionInNum}/10</option>
              </span>

              <span className="sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1">
                {item.price}
              </span>
              <span
                onClick={() => deleteADs(item._id)}
                className="hover:text-red-500 cursor-pointer sm:w-[21%] md:w-[21%] text-sm text-center border-r border-gray-500 pr-2 pl-2 w-[21%] py-1"
              >
                Delete
              </span>
              <span
                onClick={() => changeStatus(item._id, item.status)}
                className=" cursor-pointer md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%] bg-transparent pr-2 pl-2"
              >
                <select
                  className=" bg-transparent"
                  name="filter"
                  id=""
                  onChange={(e) =>
                    dispatch(changeStatus(item._id, e.target.value))
                  }
                >
                  <option value="" disabled selected>
                    EDIT
                  </option>
                  <option value="1">active</option>
                  <option value="0">inActive</option>
                  <option value="3">sold</option>
                  <option value="2">expire</option>
                </select>
              </span>
            </div>
          ))}

          <div className="mt-4 px-72">
            <Pagination
              current={currentPage}
              total={clientsAds.length}
              pageSize={itemsPerPage}
              onChange={onChange}
            />
          </div>
        </>
      )}

      {isOpen && (
        <Card2
          views_len={currentAds.views.length}
          likes_len={currentAds.likes.length}
          published={currentAds.publishedOn}
          lat={currentAds.lat}
          long={currentAds.long}
          area={currentAds.area}
          city={currentAds.city}
          add={currentAds.fullAddress}
          des={currentAds.description}
          status={currentAds.status}
          id={currentAds._id}
          img={currentAds.images}
          title={currentAds.title}
          brand={currentAds.brand}
          condition={currentAds.conditionInNum}
          price={currentAds.price}
          cardOpen={cardOpen}
          cardClose={cardClose}
          toggle={toggle}
        />
      )}

      <div className="p-6">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default Ads;
