// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const AdminLogsReducer = createSlice({
  //STATE NAME
  name: "logs",

  //STATE INITIAL VALUE
  initialState: {
    adminLogs: [],
    userLogs: [],
    newsLogs:[],
    reviewsLogs:[],
    adsLogs:[],
    hoursBefore: [],
    logsByDate:[],
    brandsMobilesCount: {
      TEL: 0,
      SAMSUNG: 0,
      Apple: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    },
    adsPerBrandRecord:{
      TEL: 0,
      SAMSUNG: 0,
      Apple: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    },
    status: "idle",
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    adminLogsRequest(state) {
      state.status = "loading";
    },

    adminLogsSuccess(state, action) {
      state.status = "succeeded";
      state.adminLogs = action.payload.adminLogs;
    },

    adminDeleteLogsRequest(state) {
      state.status = "loading";
    },

    adminDeleteLogsSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isDeleted = true;
    },

    adminDeleteAllLogsRequest(state) {
      state.status = "loading";
    },

    adminDeleteAllLogsSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isDeleted = true;
    },

    reset(state, action) {
      state.status = "succeeded";
      state.message = "";
      state.error = true;
      state.isDeleted= false;
    },

    adminLogsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    userLogsRequest(state) {
      state.status = "loading";
    },

    userLogsSuccess(state, action) {
      state.status = "succeeded";
      state.userLogs = action.payload.userLogs;
    },

    userLogsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    newsLogsRequest(state) {
      state.status = "loading";
    },

    newsLogsSuccess(state, action) {
      state.status = "succeeded";
      state.newsLogs = action.payload.newsLogs;
    },

    newsLogsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    reviewsRequest(state) {
      state.status = "loading";
    },

    reviewsSuccess(state, action) {
      state.status = "succeeded";
      state.reviewsLogs = action.payload.reviewsLogs;
    },

    reviewsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    adsRequest(state) {
      state.status = "loading";
    },

    adsSuccess(state, action) {
      state.status = "succeeded";
      state.adsLogs = action.payload.adsLogs;
    },

    adsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    deleteUserLogRequest(state) {
      state.status = "loading";
    },

    deleteUserLogSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isDeleted = true;
    },

    deleteUserLogFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    pastHoursLogsRequest(state) {
      state.status = "loading";
    },

    pastHoursLogsSuccess(state, action) {
      state.status = "succeeded";
      state.hoursBefore = action.payload.hoursBefore;
    },

    pastHoursLogsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    customHoursLogsRequest(state) {
      state.status = "loading";
    },

    customHoursLogsSuccess(state, action) {
      state.status = "succeeded";
      state.logsByDate = action.payload.logsByDate;
    },

    customHoursLogsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    mobilesPerBrandSuccess(state,action)
    {
      state.brandsMobilesCount = action.payload.record
    },

    adsPerBrandSuccess(state,action)
    {
      state.adsPerBrandRecord = action.payload.adsPerBrandRecord
    }
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  adminLogsRequest,
  adminLogsSuccess,
  adminLogsFailure,
  adminDeleteLogsRequest,
  adminDeleteLogsSuccess,
  adminDeleteAllLogsSuccess,
  adminDeleteAllLogsRequest,
  userLogsRequest,
  userLogsSuccess,
  userLogsFailure,
  newsLogsRequest,
  newsLogsSuccess,
  newsLogsFailure,
  deleteUserLogRequest,
  deleteUserLogSuccess,
  deleteUserLogFailure,
  pastHoursLogsRequest,
  pastHoursLogsSuccess,
  pastHoursLogsFailure,
  customHoursLogsRequest,
  customHoursLogsSuccess,
  customHoursLogsFailure,
  mobilesPerBrandSuccess,
  reviewsRequest,
  reviewsSuccess,
  reviewsFailure,
  adsRequest,
  adsSuccess,
  adsFailure,
  reset,
  adsPerBrandSuccess
} = AdminLogsReducer.actions;

//EXPORT REDUCER

export default AdminLogsReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getAdminLogs = () => async (dispatch) => {
  dispatch(adminLogsRequest());

  try {
    // const response = await fetch(`https://6283.mobilemate.io/brands`);

    const response = await fetch(`https://mm.magma3c.com/logs/adminLogs`);

    const responseData = await response.json();

    dispatch(adminLogsSuccess({ adminLogs: responseData.adminLogs }));
  } catch (error) {
    dispatch(adminLogsFailure(error.message));
  }
};

export const getUserLogs = () => async (dispatch) => {
  dispatch(userLogsRequest());

  try {
    // const response = await fetch(`https://6283.mobilemate.io/brands`);

    const response = await fetch(`https://mm.magma3c.com/logs/userLogs`);

    const responseData = await response.json();

    dispatch(userLogsSuccess({ userLogs: responseData.userLogs }));
  } catch (error) {
    dispatch(userLogsFailure(error.message));
  }
};

export const getNewsLogs = () => async (dispatch) => {
  dispatch(newsLogsRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/logs/newsLogs`);

    const responseData = await response.json();

    dispatch(newsLogsSuccess({ newsLogs: responseData.newsLogs }));
  } catch (error) {
    dispatch(newsLogsFailure(error.message));
  }
};

export const getReviewsLogs = () => async (dispatch) => {
  dispatch(reviewsRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/logs/reviewsLogs`);

    const responseData = await response.json();

    dispatch(reviewsSuccess({ reviewsLogs: responseData.reviewsLogs }));
  } catch (error) {
    dispatch(reviewsFailure(error.message));
  }
};

export const getAdsLogs = () => async (dispatch) => {
  dispatch(adsRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/logs/adsLogs`);

    const responseData = await response.json();

    dispatch(adsSuccess({ adsLogs: responseData.adsLogs }));
  } catch (error) {
    dispatch(adsFailure(error.message));
  }
};

export const deleteAdminLog = (id) => async (dispatch) => {
  dispatch(adminDeleteLogsRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/logs/deleteAdminLogs/${id}`,
      {
        method: "DELETE",
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      dispatch(adminLogsFailure(responseData.message));
    }

    if (response.ok) {
      dispatch(adminDeleteLogsSuccess({ message: responseData.message }));
    }
  } catch (error) {
    dispatch(adminLogsFailure(error.message));
  }
};

export const deleteUserLog = (id) => async (dispatch) => {
  dispatch(deleteUserLogRequest());

  try {
    const response = await fetch(
      `http://localhost:47017/logs/deleteUserLog/${id}`,
      {
        method: "DELETE",
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      dispatch(deleteUserLogFailure(responseData.message));
    }

    if (response.ok) {
      dispatch(deleteUserLogSuccess({ message: responseData.message }));
    }
  } catch (error) {
    dispatch(deleteUserLogFailure(error.message));
  }
};

export const deleteAllAdminLogs = () => async (dispatch) => {
  dispatch(adminDeleteAllLogsRequest());

  try {
    // const response = await fetch(`https://6283.mobilemate.io/brands`);

    const response = await fetch(
      `https://mm.magma3c.com/admin/deletealladminlog`,
      {
        method: "DELETE",
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      dispatch(adminLogsFailure(responseData.message));
    }

    if (response.ok) {
      dispatch(adminDeleteAllLogsSuccess({ message: responseData.message }));
    }
  } catch (error) {
    dispatch(adminLogsFailure(error.message));
  }
};

export const pastHoursLogs = (hours) => async (dispatch) => {
  dispatch(pastHoursLogsRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/logs/pastHour/${hours}`
    );

    const responseData = await response.json();

    dispatch(pastHoursLogsSuccess({ hoursBefore: responseData.hoursBefore }));
  } catch (error) {
    dispatch(pastHoursLogsFailure(error.message));
  }
};

export const customHoursLogs = (date) => async (dispatch) => {

  

  dispatch(customHoursLogsRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/logs/logsByDate/${date}`
    );

    const responseData = await response.json();

    dispatch(customHoursLogsSuccess({ logsByDate: responseData.logsByDate }));

  } catch (error) {
    dispatch(customHoursLogsFailure(error.message));
  }

};

export const mobilesPerBrand = (record) => async (dispatch) => {

    dispatch(mobilesPerBrandSuccess({ record: record }));
 
};

export const adsPerBrand = (record) => async (dispatch) => {

  dispatch(adsPerBrandSuccess({ adsPerBrandRecord: record }));

};




