import React, { useState } from "react";
import Clients from "./Clients";
import Ads from "./Ads";
import AdsSearch from "./AdsSearch";
import ClientSearch from "./ClientSearch";
const BuyandSellMainContainer = () => {
    const [activeComponent, setActiveComponent] = useState("Clients");

    const handleComponentVisibility = (componentName) => {
      setActiveComponent(componentName);
    };
  return (
    <div>
    <div className="flex justify-between space-x-3">

      <button
        onClick={() => handleComponentVisibility("Clients")}
        className={`w-full text-center py-2 font-semibold shadow-md shadow-gray-400 duration-300 ${
          activeComponent === "Clients"
            ? "bg-gray-600 text-white"
            : "text-gray-700 bg-gray-200"
        }`}
      >
        Clients
      </button>
      <button
        onClick={() => handleComponentVisibility("Ads")}
        className={`w-full text-center py-2 font-semibold shadow-md shadow-gray-400 duration-300 ${
          activeComponent === "Ads"
            ? "bg-gray-600 text-white"
            : "text-gray-700 bg-gray-200"
        }`}
      >
        Ads
      </button>
      <button
        onClick={() => handleComponentVisibility("ClientSearch")}
        className={`w-full text-center py-2 font-semibold shadow-md shadow-gray-400 duration-300 ${
          activeComponent === "ClientSearch"
            ? "bg-gray-600 text-white"
            : "text-gray-700 bg-gray-200"
        }`}
      >
        Client Search
      </button>
      <button
        onClick={() => handleComponentVisibility("AdsSearch")}
        className={`w-full text-center py-2 font-semibold shadow-md shadow-gray-400 duration-300 ${
          activeComponent === "AdsSearch"
            ? "bg-gray-600 text-white"
            : "text-gray-700 bg-gray-200"
        }`}
      >
        Ad Search
    
      </button>
    </div>

    {/* Conditional rendering based on activeComponent */}
    {activeComponent === "Clients" && <Clients />}
    {activeComponent === "Ads" && <Ads />}
    {activeComponent === "ClientSearch" && <ClientSearch />}
    {activeComponent === "AdsSearch" && <AdsSearch />}
    {/* {activeComponent === "AdsDetails" && <AdsDetails />} */}
  </div>
  )
}

export default BuyandSellMainContainer