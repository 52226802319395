import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getTotalUsers,
  getTotalReviews,
  getTotalNews,
  getTotalMobiles,
} from "../../../Redux/analytics";
import { Link } from "react-router-dom";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";

const MainDashboard = () => {
  const dispatch = useDispatch();

  const { totalMobiles, totalNews, totalReviews, totalUsers } = useSelector(
    (state) => state.analytics
  );

  const { users, status } = useSelector((state) => state.users);

  useEffect(() => {
    if (totalMobiles == 0) dispatch(getTotalMobiles());

    if (totalNews == 0) dispatch(getTotalNews());

    if (totalReviews == 0) dispatch(getTotalReviews());

    if (totalUsers == 0) dispatch(getTotalUsers());
  }, []);

  return (
    <div className="container-fluid pb-3 ">
      <div className="container grid grid-cols-2 gap-3 md:w-[750px] lg:w-full">
        <Link
          to={"/mainNewsContainer"}
          className=" bg-white rounded-md shadow-md shadow-gray-500 border py-2 px-3"
        >
          <BarChart
            xAxis={[{ scaleType: "band", data: ["Jan", "Feb", "Mar", "Apr"] }]}
            series={[
              { data: [4, 3, 5, 3] },
              { data: [1, 6, 3, 8] },
              { data: [2, 5, 6, 2] },
              { data: [2, 4, 6, 0] },
            ]}
            width={340}
            height={150}
          />
          <div className=" text-sm font-semibold hover:text-gray-600  bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total News</span> <span>{totalNews}</span>
          </div>
        </Link>
        <Link
          to={"/reviews"}
          className=" bg-white rounded-md shadow-md shadow-gray-500 py-2 px-3"
        >
          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: 10, label: "series A" },
                  { id: 1, value: 15, label: "series B" },
                  { id: 2, value: 20, label: "series C" },
                ],
              },
            ]}
            width={320}
            height={150}
          />
          <div className=" text-sm font-semibold  hover:text-gray-600  bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Reviews</span> <span>{totalReviews}</span>
          </div>
        </Link>
        <div className=" bg-white rounded-md shadow-md shadow-gray-500 py-2 px-3">
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
              },
            ]}
            width={340}
            height={150}
          />
          <div className=" text-sm font-semibold  hover:text-gray-600  bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Users </span> <span>{totalUsers}</span>
          </div>
        </div>
        {/* Mobiles Brands{" "} */}
        <Link
          to={"/mobileBrandsMainSec"}
          className=" bg-white rounded-md shadow-md shadow-gray-500 py-2 px-3"
        >
          <BarChart
            xAxis={[{ scaleType: "band", data: ["Jan", "Feb", "Mar", "Apr"] }]}
            series={[
              { data: [4, 3, 5, 3] },
              { data: [1, 6, 3, 8] },
              { data: [2, 5, 6, 2] },
              { data: [2, 4, 6, 0] },
            ]}
            width={340}
            height={150}
          />
          <div className=" text-sm  hover:text-gray-600  font-semibold bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Mobiles</span> <span>{totalMobiles}</span>{" "}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MainDashboard;
