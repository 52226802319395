// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const AnalyticsReducer = createSlice({

  //STATE NAME
  name: "analytics",

  //STATE INITIAL VALUE
  initialState: {
    totalMobiles: 0,
    totalNews: 0,
    totalReviews: 0,
    totalUsers: 0,
    status:"idle",
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    totalMobilesSuccess(state,action) {
      state.totalMobiles = action.payload.totalMobiles;
    },
    totalNewsSuccess(state, action) {
     
      state.totalNews = action.payload.totalNews;
    },

    totalReviewsSuccess(state, action) {
      
      state.totalReviews = action.payload.totalReviews;
    },
    totalUsersSuccess(state,action) {

        state.totalUsers = action.payload.totalUsers;
    }
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
 totalMobilesSuccess, totalNewsSuccess, totalReviewsSuccess, totalUsersSuccess
} = AnalyticsReducer.actions;

//EXPORT REDUCER

export default AnalyticsReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getTotalMobiles = () => async (dispatch) => {
  
 
    // const response = await fetch(`http://localhost:5000/admin/totalmobiles`);

    // const responseData = await response.json();

    // dispatch(totalMobilesSuccess({ totalMobiles: responseData.totalMobiles }));
 
};

export const getTotalNews = () => async (dispatch) => {
  
 
    // const response = await fetch(`http://localhost:5000/admin/totalnews`);

    // const responseData = await response.json();

    // dispatch(totalNewsSuccess({ totalNews: responseData.totalNews }));
 
};

export const getTotalReviews = () => async (dispatch) => {
  
 
    // const response = await fetch(`http://localhost:5000/admin/totalreviews`);

    // const responseData = await response.json();

    // dispatch(totalReviewsSuccess({ totalReviews: responseData.totalReviewsController }));
 
};

export const getTotalUsers = () => async (dispatch) => {
  
 
    // const response = await fetch(`http://localhost:5000/admin/totalusers`);

    // const responseData = await response.json();

    // dispatch(totalUsersSuccess({ totalUsers: responseData.totalUsers }));
 
};


