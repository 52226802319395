// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const SingleBrandReducer = createSlice({
  //STATE NAME
  name: "singleMobileBrand",

  //STATE INITIAL VALUE
  initialState: {
    isAdded: false,
    isUpdated: false,
    mobiles: [],
    searchedItems: [],
    brand: null,
    status: "idle",
    message: "",
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    singleBrandRequest(state) {
      state.status = "loading";
    },
    singleBrandSuccess(state, action) {
      state.status = "succeeded";
      state.mobiles = action.payload.phones;
    },
    getSingleBrandRequest(state) {
      state.status = "loading";
    },
    getSingleBrandSuccess(state, action) {
      state.status = "succeeded";
      state.brand = action.payload.brand;
    },
    updateSingleBrandRequest(state) {
      state.status = "loading";
    },
    updateSingleBrandSuccess(state, action) {
      state.status = "succeeded";
      state.isUpdated = true;
      state.message = action.payload.message;
    },
    deleteSingleBrandRequest(state) {
      state.status = "loading";
    },
    deleteSingleBrandSuccess(state, action) {
      state.status = "succeeded";
      state.isUpdated = true;
      state.message = action.payload.message;
    },
    deleteSingleBrandFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    searchRequest(state) {
      state.status = "loading";
    },
    searchSuccess(state, action) {
      state.status = "succeeded";
      state.searchedItems = action.payload.searchedItems;
    },
    resetSuccess(state, action) {
      state.searchedItems = action.payload.searchedItems;
    },
    postSingleBrandSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isAdded = true;
    },
    postSingleModalSuccess(state, action) {
      state.status = "succeeded";
      state.model = action.payload.model;
    },
    singleBrandFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  singleBrandRequest,
  singleBrandSuccess,
  singleBrandFailure,
  getSingleBrandRequest,
  getSingleBrandSuccess,
  resetSuccess,
  postSingleBrandSuccess,
  postSingleModalSuccess,
  searchRequest,
  searchSuccess,
  updateSingleBrandRequest,
  updateSingleBrandSuccess,
  deleteSingleBrandRequest,
  deleteSingleBrandSuccess,
  deleteSingleBrandFailure,
} = SingleBrandReducer.actions;

//EXPORT REDUCER

export default SingleBrandReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getMobilesByBrand = (brand) => async (dispatch) => {
  dispatch(singleBrandRequest());

  try {
    const response = await fetch(
      `https://6283.mobilemate.io/admin/mobilephones/${brand}`
    );

    const responseData = await response.json();

    console.log(responseData);

    dispatch(singleBrandSuccess({ phones: responseData.phones }));
  } catch (error) {
    dispatch(singleBrandFailure(error.message));
  }
};

export const postSingleBrand = (data, currentuser) => async (dispatch) => {
  dispatch(singleBrandRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/brands/createBrand`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json",
        "authorization":JSON.stringify(currentuser)
       },
    });

    const responseData = await response.json();

    dispatch(postSingleBrandSuccess({ message: responseData.message }));
  } catch (error) {
    dispatch(singleBrandFailure(error.message));
  }
};

export const getPhones = (model) => async (dispatch) => {
  dispatch(searchRequest());

  

  try {
    const response = await fetch(
      `https://mm.magma3c.com/mobiles/searchMobile?model=${model}`
    );

    const responseData = await response.json();

    console.log(responseData);

    dispatch(searchSuccess({ searchedItems: responseData }));
  } catch (error) {
    dispatch(singleBrandFailure(error.message));
  }
};

export const resetSearch = () => async (dispatch) => {
  dispatch(resetSuccess({ searchedItems: [] }));
};

export const getSingleBrand = (id) => async (dispatch) => {
  dispatch(getSingleBrandRequest());

  try {
    const response = await fetch(
      `https://6283.mobilemate.io/admin/editbrand/${id}`
    );

    const responseData = await response.json();

    dispatch(getSingleBrandSuccess({ brand: responseData.brand }));
  } catch (error) {
    dispatch(singleBrandFailure(error.message));
  }
};

export const updateSingleBrand = (id, data,currentUser) => async (dispatch) => {
  dispatch(updateSingleBrandRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/brands/updateBrand/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "authorization": JSON.stringify(currentUser)
        },
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      dispatch(singleBrandFailure(responseData.message));
    } else {
      dispatch(updateSingleBrandSuccess({ message: responseData.message }));
    }
  } catch (error) {
    dispatch(singleBrandFailure(error.message));
  }
};

export const deleteSingleBrand = (id,currentUser) => async (dispatch) => {
  dispatch(deleteSingleBrandRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/brands/deleteBrand/${id}`,
      {
        method: "DELETE",
        headers: {
        "authorization": JSON.stringify(currentUser)
        }

      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      dispatch(deleteSingleBrandFailure(responseData.message));
    } else {
      dispatch(deleteSingleBrandSuccess({ message: responseData.message }));
    }
  } catch (error) {
    dispatch(singleBrandFailure(error.message));
  }
};
