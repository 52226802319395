import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoArrowBack } from "react-icons/io5";
import { postMobile } from "../../../Redux/SingleMobileDetails";
import { getBrands } from "../../../Redux/BrandsReducer";
import ImageUpload1 from "../../ImageUpload1";

const AddMobileForm = ({ isVisible }) => {

  const { brands } = useSelector((state) => state.brands);
  const {imageUrl1, isLoading, uploaded1} = useSelector(state => state.imageUplaod)
  const { token, currentUser } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [mobileBrands, setMobileBrands] = useState("");

  useEffect(() => {
    if (brands.length == 0) {
      dispatch(getBrands());
    }
  }, [brands]);

  useEffect(() => {
    setMobileBrands(brands);

    // fetchMobileBrands(MobileBrandsApi);
  }, [brands]);

  // STRING PROPERTIES
 
  const [model, setModel] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState("");
  const [launched, setLaunched] = useState("");
  const [resolution, setResolution] = useState("");
  const [wlan, setWlan] = useState("");
  const [usb, setUSB] = useState("");
  const [nfc, setNFC] = useState("");
  const [cpu, setCPU] = useState("");
  const [gps, setGPS] = useState("");
  const [color, setColor] = useState("");
  const [gpu, setGPU] = useState("");
  const [sim, setSIM] = useState("");
  const [bluetooth, setBluetooth] = useState("");

  const [chipset, setChipset] = useState("");
  const [technology, setTechnology] = useState("");
  const [os, setOS] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [TwoGBand, setTwoGBand] = useState("");
  const [ThreeGBand, setThreeGBand] = useState("");
  const [FourGBand, setFourGBand] = useState("");
  const [FiveGBand, setFiveGBand] = useState("");
  const [launchedDate, setLaunchedDate] = useState("");

  // NUMERIC PROPERTIES
  const [frontCam, setFrontCam] = useState("");
  const [backCam, setBackCam] = useState("");
  const [batteryMah, setBatteryMah] = useState("");
  const [rating, setRating] = useState("");
  const [size, setSize] = useState("");
  const [weight, setWeight] = useState("");
  const [pricePkr, setPricepkr] = useState("");
  const [priceUsd, setPriceUsd] = useState("");
  const [rom, setROM] = useState("");
  const [ram, setRAM] = useState("");
  const [refreshRateFps, setRefreshRateFps] = useState("");

  const OPTIONS = ["Yes", "No"];

  function submitHandler(event) {
    event.preventDefault();

    // const formData = new FormData();
    // formData.append("model", model.toUpperCase());
    // formData.append("imageurl", imageurl);
    // formData.append("brand", brand);
    // formData.append("description", description);
    // formData.append("features", features);
    // formData.append("launched", launched);
    // formData.append("resolution", resolution);
    // formData.append("wlan", wlan);
    // formData.append("usb", usb);
    // formData.append("nfc", nfc);
    // formData.append("cpu", cpu);
    // formData.append("gps", gps);
    // formData.append("colors", color);
    // formData.append("gpu", gpu);
    // formData.append("sim", sim);
    // formData.append("bluetooth", bluetooth);
    // formData.append("chipset", chipset);
    // formData.append("technology", technology);
    // formData.append("os", os);
    // formData.append("dimensions", dimensions);
    // formData.append("frontCam", frontCam);
    // formData.append("backCam", backCam);
    // formData.append("batteryMah", batteryMah);
    // formData.append("rating", rating);
    // formData.append("size", size);
    // formData.append("weight", weight);
    // formData.append("pricePkr", pricePkr);
    // formData.append("priceUsd", priceUsd);
    // formData.append("rom", rom);
    // formData.append("ram", ram);
    // formData.append("refreshRateFps", refreshRateFps);
    // formData.append("2GBand", TwoGBand);
    // formData.append("3GBand", ThreeGBand);
    // formData.append("4GBand", FourGBand);
    // formData.append("5GBand", FiveGBand);
    // formData.append("launchedDate", launchedDate);

    const Payload = {
      model: model.toUpperCase(),
        imageurl:imageUrl1,
        brand: brand,
        description: description,
        features: features,
        launched: launched,
        resolution: resolution,
        wlan: wlan,
        usb: usb,
        nfc: nfc,
        cpu: cpu,
        gps: gps,
        colors: color,
        gpu: gpu,
        sim: sim,
        bluetooth: bluetooth,
        chipset: chipset,
        technology: technology,
        os: os,
        dimensions: dimensions,
        frontCam: frontCam,
        backCam: backCam,
        battery_Mah: batteryMah,
        rating: rating,
        size: size,
        weight: weight,
        pricePkr: pricePkr,
        priceUsd: priceUsd,
        rom: rom,
        ram: ram,
        refreshrateFps: refreshRateFps,
        twoG: TwoGBand,
        threeG: ThreeGBand,
        fourG: FourGBand,
        FiveG: FiveGBand,
        createdOn: launchedDate,
        
    }

    dispatch(postMobile(Payload,currentUser));

    isVisible();
  }

  return (
    <div className="absolute bg-gray-900/80 inset-0 z-10">
      <div className="bg-white w-[1200px] mx-auto px-12 py-2 my-16  ">
        <div className="flex items-center py-4">
          <button onClick={isVisible}>
            <IoArrowBack />
          </button>
          <h1 className="text-2xl text-gray-800 font-semibold text-center w-full">
            Add New Mobile
          </h1>
        </div>
        <form
          action=""
          className=" grid md:grid-cols-6 grid-cols-4 md:gap-3 gap-2 md:text-sm text-[11px] justify-between pb-8"
          onSubmit={submitHandler}
        >
          <input
            value={model}
            type="text"
            onChange={(e) => setModel(e.target.value)}
            placeholder="Model"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />

          <select
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          >
            <option value="" disabled>
              Select a Brand
            </option>
            {mobileBrands &&
              mobileBrands.map((item) => {
                return (
                  <option key={item._id} value={item.brand}>{item.brand}</option>
                );
              })}
          </select>

          <select
            value={launched}
            onChange={(e) => setLaunched(e.target.value)}
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          >
            <option value="" disabled>
              Select Is Launched
            </option>
            {OPTIONS.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>

          <input
            value={description}
            type="text"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={features}
            type="text"
            onChange={(e) => setFeatures(e.target.value)}
            placeholder="Features"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />
          <input
            value={resolution}
            type="text"
            onChange={(e) => setResolution(e.target.value)}
            placeholder="Resolution"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />
          <input
            value={wlan}
            type="text"
            onChange={(e) => setWlan(e.target.value)}
            placeholder="Wlan"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />
          <input
            value={usb}
            type="text"
            onChange={(e) => setUSB(e.target.value)}
            placeholder="USB"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />

          <select
            value={nfc}
            required
            onChange={(e) => setNFC(e.target.value)}
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          >
            <option value="" disabled>
              Select NFC
            </option>
            {OPTIONS.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>

          <input
            value={cpu}
            type="text"
            onChange={(e) => setCPU(e.target.value)}
            placeholder="CPU"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />
          <input
            value={gps}
            type="text"
            onChange={(e) => setGPS(e.target.value)}
            placeholder="GPS"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />
          <input
            value={color}
            type="text"
            onChange={(e) => setColor(e.target.value)}
            placeholder="Color"
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            required
          />
          <input
            value={gpu}
            type="text"
            onChange={(e) => setGPU(e.target.value)}
            placeholder="GPU"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={sim}
            type="text"
            onChange={(e) => setSIM(e.target.value)}
            placeholder="SIM"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <select
            value={bluetooth}
            required
            onChange={(e) => setBluetooth(e.target.value)}
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          >
            <option value="" disabled>
              Select Bluetooth
            </option>
            {OPTIONS.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>

          <input
            value={chipset}
            type="text"
            onChange={(e) => setChipset(e.target.value)}
            placeholder="Chipset"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={technology}
            type="text"
            onChange={(e) => setTechnology(e.target.value)}
            placeholder="Technology"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={os}
            type="text"
            onChange={(e) => setOS(e.target.value)}
            placeholder="OS"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={dimensions}
            type="text"
            onChange={(e) => setDimensions(e.target.value)}
            placeholder="Dimension"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={frontCam}
            type="number"
            onChange={(e) => setFrontCam(e.target.value)}
            placeholder="FrontCam"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={backCam}
            type="number"
            onChange={(e) => setBackCam(e.target.value)}
            placeholder="BackCam"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={batteryMah}
            type="number"
            onChange={(e) => setBatteryMah(e.target.value)}
            placeholder="Batterymah"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={rating}
            type="number"
            onChange={(e) => setRating(e.target.value)}
            placeholder="Rating"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={size}
            type="number"
            onChange={(e) => setSize(e.target.value)}
            placeholder="Size"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={weight}
            type="number"
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Weight"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={pricePkr}
            type="number"
            onChange={(e) => setPricepkr(e.target.value)}
            placeholder="Pice Pkr"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={priceUsd}
            type="number"
            onChange={(e) => setPriceUsd(e.target.value)}
            placeholder="Price Usd"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={ram}
            type="number"
            onChange={(e) => setRAM(e.target.value)}
            placeholder="RAM"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={rom}
            type="number"
            onChange={(e) => setROM(e.target.value)}
            placeholder="ROM"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={refreshRateFps}
            type="number"
            onChange={(e) => setRefreshRateFps(e.target.value)}
            placeholder="RefreshRateFps"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={TwoGBand}
            type="text"
            onChange={(e) => setTwoGBand(e.target.value)}
            placeholder="Enter 2G Band"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={ThreeGBand}
            type="text"
            onChange={(e) => setThreeGBand(e.target.value)}
            placeholder="Enter 3G Band"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={FourGBand}
            type="text"
            onChange={(e) => setFourGBand(e.target.value)}
            placeholder="Enter 4G Band"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={FiveGBand}
            type="text"
            onChange={(e) => setFiveGBand(e.target.value)}
            placeholder="Enter 5G Band"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

          <input
            value={launchedDate}
            type="date"
            onChange={(e) => setLaunchedDate(e.target.value)}
            placeholder="Launched Date"
            required
            className="hover:shadow-md max-w-[160px] shadow-gray-700 h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />

            <ImageUpload1 className=" text-sm text-gray-700 outline-none  rounded-sm "/>

          <button
            className=" rounded-sm  hover:bg-gray-900  max-w-[355px]  bg-gray-800 duration-300 text-white h-12"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddMobileForm;
