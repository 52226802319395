import React, { useState, useEffect, useCallback } from "react";
import { IoMdMenu } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getPhones,resetSearch } from "../Redux/SingleBrandReducer";
import debounce from "lodash/debounce";

const routes = [
  // { path: "/about", label: "About" },
  // { path: "/services", label: "Services" },
  // { path: "/faq", label: "FAQ's" },
];

const Navbar = ({ toggleSidebar }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");

  const { searchedItems } = useSelector((state) => state.singleMobileBrand);

  // Create a debounced function
  const debouncedDispatch = useCallback(
    debounce((modelName) => {
      dispatch(getPhones(modelName));
    }, 300), // Adjust the debounce time (in milliseconds) as needed
    [dispatch]
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearch(value);

    // Call the debounced dispatch function
    debouncedDispatch(value);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      debouncedDispatch.cancel();
    };
  }, [debouncedDispatch]);

  return (
    <div class=" border-b py-2 ">
      {/* hide above  md  */}
      <div className="flex text-[13px] font-semibold lg:hidden w-full items-center justify-between">
        <div className="flex items-center">
          <button onClick={toggleSidebar} className="text-gray-600">
            <IoMdMenu size={25} />
          </button>
          <Link
            to="/"
            className="font-bold text-[18px] md:text-2xl ml-4 text-gray-700 hover:scale-100"
          >
            Mobile<span className="font-normal">Mate</span>
          </Link>
        </div>
        <div className="space-x-3 flex items-center text-gray-500">
          <input
            type="search"
            className="outline-none   hover:shadow-md hover:shadow-gray-400 p-1 border mx-2 rounded md:max-w-full max-w-[140px] "
            placeholder="Search By Model Name"
            value={search}
            onChange={handleInputChange}
          />
          <Link to={"/profile"} className=" profile">
            <IoPersonCircleOutline size={23} />
          </Link>
        </div>
      </div>
      {/* hide below md */}
        <div className=" hidden lg:flex justify-between items-center ">
          {routes.map((route) => (
            <Link
              to={route.path}
              className={`2xl:px-6  px-4 hover:bg-gray-200  text-center  hover:font-semibold hover:text-blue-900 items-center rounded-md duration-300 button ${
                location.pathname === route.path ? "active" : ""
              }`}
            >
              <span>{route.label}</span>
            </Link>
          ))}

          <button>
            <input
              type="search"
              className=" 2xl:placeholder:text-[0.5rem] border hover:shadow-md  rounded 2xl:px-5 px-3 h-10   outline-none"
              placeholder="Search By Model Name"
              onChange={handleInputChange}
            />
          </button>

          <Link to={"/profile"} className=" profile">
            <IoPersonCircleOutline size={30} />
          </Link>
        </div>


      {searchedItems.length > 0 && (
        <div className="bg-white w-[200px] h-auto absolute left-[10px] top-[50px] z-10 shadow-md shadow-gray-800 ">
          <button
            className="items-end pl-[180px]"
            onClick={()=> dispatch(resetSearch())}
          >
            x
          </button>
          <div className="space-y-1">
            {searchedItems.map((item) => (
              <p
                className="hover:bg-gray-100 px-2"
                onClick={() => {
                  navigate(`MobileDetails/${item._id}`);

                  window.location.reload("/");
                }}
              >
                {item.model}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
