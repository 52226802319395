import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MdDashboard, MdOutlineLogout, MdAdsClick } from "react-icons/md";
import { FaMobileScreenButton, FaUsers } from "react-icons/fa6";
import { IoMdPricetag } from "react-icons/io";
import { GiNewspaper } from "react-icons/gi";
import { HiLibrary } from "react-icons/hi";
import { useSelector } from "react-redux";
import { TbLogs } from "react-icons/tb";
import { GiSellCard } from "react-icons/gi";

const Sidebar = ({ handleLogoutPageVisibilty }) => {
  const { currentUser } = useSelector((state) => state.user);

  const routes = [
    { path: "/", label: "Dashboard", icon: MdDashboard },
    { path: "/allmobiles", label: "Mobiles", icon: FaMobileScreenButton },
    {
      path: "/mobileBrandsMainSec",
      label: "Mobile Brands",
      icon: IoMdPricetag,
    },
    { path: "/mainNewsContainer", label: "News", icon: GiNewspaper },
    { path: "/users", label: "User's", icon: FaUsers },
    { path: "/ads", label: "Ads", icon: MdAdsClick },
    { path: "/glossary", label: "Glossary", icon: HiLibrary },
    { path: "/logs", label: "Logs", icon: TbLogs },
    { path: "/buyandsellcontainer", label: "Buy and Sell", icon: GiSellCard },
  ];

  // Filter out routes based on user role

  const filteredRoutes = routes.filter((route) => {
    // Hide specific routes if the role is 'publisher'
    if (currentUser.role === "publisher") {

      return (
        route.path !== "/users" &&
        route.path !== "/ads" &&
        route.path !== "/glossary" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/logs" 

      );

    } else if (currentUser.role === "mobileseditor") {

      return (
        route.path !== "/users" &&
        route.path !== "/ads" &&
        route.path !== "/glossary" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/logs"
      );
    } else if (currentUser.role === "glossaryeditor") {
      return (
        route.path !== "/users" &&
        route.path !== "/ads" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/logs"
      );
    } else if (currentUser.role === "manager") {
      return route.path !== "/users";
    } else if (currentUser.role === "adseditor") {
      return (
        route.path !== "/users" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/glossary" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/buyandsellcontainer" &&
        route.path !== "/logs"
      );
    } else {
      return route;
    }
  });

  const location = useLocation();
  return (
    <div className={`fixed xl:w-[20%]  bg-white h-[100%] hidden lg:block `}>
      <div className="logoName">
        <Link to="/" >
          Mobile<span className="font-normal">Mate</span>
        </Link>
      </div>
      <div className="btnsContainer">
        {filteredRoutes.map((route) => (
          <Link
            key={route.path} // Adding key for unique identification
            to={route.path}
            className={` sideBarbutton button ${
              location.pathname === route.path ? "active" : ""
            }`}
          >
            {React.createElement(route.icon)}
            <span>{route.label}</span>
            {/* {React.createElement(route.icon, { size: iconSize })}
            <span>{route.label}</span> */}
          </Link>
        ))}

        {/* Logout Button */}
        <button
          className="sideBarbutton button "
          onClick={handleLogoutPageVisibilty}
        >
          <MdOutlineLogout />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
