// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const GlossaryReducer = createSlice({
  //STATE NAME
  name: "glossary",

  //STATE INITIAL VALUE
  initialState: {
    message: "",
    glossaryTerms: [],
    glossaryList: [],
    isUpdated : false,
    status: "idle",
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    glossaryRequest(state) {
      state.status = "loading";
    },
    glossarySuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.msg;
      state.isUpdated = true;
    },
    updateGlossaryRequest(state) {
      state.status = "loading";
    },
    updateGlossarySuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isUpdated = true;
    },
    deleteGlossaryRequest(state) {
      state.status = "loading";
    },
    deleteGlossarySuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isUpdated = true;
    },
    glossaryTermsSuccess(state, action) {
      state.status = "succeeded";
      state.glossaryTerms = action.payload.glossaryTerms;
    },
    glossaryListRequest(state) {
      state.status = "loading";
    },
    glossaryListSuccess(state, action) {
      state.status = "succeeded";
      state.glossaryList = action.payload.glossaryList;
    },

    singleGlossaryRequest(state) {
      state.status = "loading";
    },

    singleGlossarySuccess(state, action) {
      state.status = "succeeded";
      state.singleGlossary = action.payload;
    },

    glossaryFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  glossaryRequest,
  glossarySuccess,
  glossaryFailure,
  glossaryTermsSuccess,
  glossaryListRequest,
  glossaryListSuccess,
  updateGlossaryRequest,
  updateGlossarySuccess,
  deleteGlossaryRequest,
  deleteGlossarySuccess,
  singleGlossaryRequest,
  singleGlossarySuccess
} = GlossaryReducer.actions;

//EXPORT REDUCER

export default GlossaryReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const postGlossary = (data, token) => async (dispatch) => {
  dispatch(glossaryRequest());

  console.log(data)

  try {
    const response = await fetch(
      `https://mm.magma3c.com/glossary/createGlossary`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `bareer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    dispatch(glossarySuccess({ msg: responseData }));

  } catch (error) {
    dispatch(glossaryFailure(error.message));
  }
};

export const getGlossary = () => async (dispatch) => {
  dispatch(glossaryRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/glossary/glossaryTerms`);

    const responseData = await response.json();

    dispatch(glossaryTermsSuccess({ glossaryTerms: responseData.glossary }));
  } catch (error) {
    dispatch(glossaryFailure(error.message));
  }
};

export const getGlossaryList = () => async (dispatch) => {
  dispatch(glossaryListRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/glossary/glossaryList`);

    const responseData = await response.json();

    console.log(responseData.glossary)

    dispatch(glossaryListSuccess({ glossaryList: responseData.glossary }));
  } catch (error) {
    dispatch(glossaryFailure(error.message));
  }
};

export const getSingleGlossary = (term) => async (dispatch) => {

  dispatch(singleGlossaryRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/glossary/glossaryDefinations/${term}`);

    const responseData = await response.json();

    dispatch(singleGlossarySuccess({ glossaryList: responseData }));

  } catch (error) {

    dispatch(glossaryFailure(error.message));

  }
};

export const updateGlossary = (data,id,token) => async (dispatch) => {

  dispatch(updateGlossaryRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/glossary/editGlossary/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json', 
        authorization: `bareer ${token}`,
      },
    });

    const responseData = await response.json();

    dispatch(updateGlossarySuccess({ message: responseData.message }));
  } catch (error) {
    dispatch(glossaryFailure(error.message));
  }
};

export const deleteGlossary = (term) => async (dispatch) => {

  dispatch(deleteGlossaryRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/glossary/deleteGlossary/${term}`, {
      method: "DELETE"
      });

    const responseData = await response.json();

    dispatch(deleteGlossarySuccess({ message: responseData.message }));

  } catch (error) {
    dispatch(glossaryFailure(error.message));
  }

  
};
