import { useDispatch } from "react-redux";
import { useRef } from "react";
import { login, logout } from "../Redux/UserReducer";

const useAuth = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null); // Reference to store the timer ID

  const handleAuth = (user) => {
    
    dispatch(login({ uid: user.uid }));

    // Clear any existing timer before setting a new one, 
    //if we dont use this if statement then user might be 
    //logout unexpectedly, for example if user login timer 
    //starts but what if user logout manually then still 
    //this timer will be executed but this if statement checks 
    //if user has previous timer then clean that first before 
    //starting a new timer

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set up a timer to log out the user after 24 hours
    timerRef.current = setTimeout(() => {
      dispatch(logout(user.uid));
    }, 86400000); // 24 hours in milliseconds
  };

  return handleAuth;
};

export default useAuth;
