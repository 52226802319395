import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { getSingleNews } from "../../../Redux/NewsReducer";
import EditForPublishNews from "./EditForPublishNews";
import { CiEdit } from "react-icons/ci";


const NewsDetails = () => {

  const [iseditPageVisible, setEditPageVisible] = useState(false);
  const handleEditPageVisibility = () => {
    setEditPageVisible(!iseditPageVisible);
  };

  const {id, title} = useParams();
  console.log("unique name ", id)
  
  console.log("unique name ", title)

  const { singleNews } = useSelector((state) => state.news);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleNews(title));
  }, [title]);

  return (
    <div className=" text-gray-700 my-3 py-3 px-3 mx-4 ">
      {iseditPageVisible && (
        <EditForPublishNews
          handleEditPageVisibility={handleEditPageVisibility}
          id={id}
          data={singleNews}
        />
      )}
      <div className=" text-end">
     
        <button  className="px-2 py-1 mb-2 rounded-sm bg-gray-700 text-white " onClick={handleEditPageVisibility} title="edit"><CiEdit size={20} /></button>
      </div>

      <p className="text-3xl font-semibold text-gray-700 text-center">
        {singleNews.title}{" "}
      </p>

      <div className="flex mx-auto">
        <img
          src={singleNews.image1}
          alt={"image"}
          className="w-[370px] h-[250px] m-6 border shadow-md shadow-gray-700"
        />
        <img
          src={singleNews.image2}
          alt={"image"}
          className="w-[370px] h-[250px] m-6 border shadow-md shadow-gray-700"
        />
      </div>

      <h1 className="text-sm text-start mx-6">
        Brand:{" "}
        <span className="font-bold  tracking-wider">{singleNews.brand}</span> :
        published by
        <span className="font-bold text-gray-700 tracking-wider">
          {" "}
          {singleNews.name}{" "}
        </span>
        <span className="tracking-wider"> {singleNews.timestamp}</span>{" "}
      </h1>
      {/* <p className="text-sm "></p> */}

      <div
        dangerouslySetInnerHTML={{ __html: singleNews.description }}
        className="news-content space-y-4 mt-2 text tracking-wider text-justify mx-7 pb-4"
      />
    </div>
    // <div>News</div>
  );
};

export default NewsDetails;
