import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for uploading images to Cloudinary
export const uploadImage = createAsyncThunk(
  'imageUpload/uploadImage',
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'my_unsigned_upload'); // Replace with your upload preset
      formData.append('folder', 'mobilemate'); // Replace with your desired folder

      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/di9vpom9l/image/upload', // Replace with your Cloudinary cloud name
        formData
      );

      return response.data.secure_url; // Return the secure URL of the uploaded image
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const imageUploadSlice = createSlice({
  name: 'imageUpload',
  initialState: {
    imageUrl: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetImageState: (state) => {
      state.imageUrl = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.loading = false;
        state.imageUrl = action.payload;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetImageState } = imageUploadSlice.actions;

export default imageUploadSlice.reducer;