import React from 'react'

const Reviews = () => {
  return (
    <div>
     main reviews page
    </div>
  )
}

export default Reviews
