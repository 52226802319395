import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
import { useSelector, useDispatch } from "react-redux";
import { getPublishedNews } from "../../../Redux/NewsReducer";

const PublishNews = () => {
  const dispatch = useDispatch();

  const { publishedNews, status, message } = useSelector((state) => state.news);

  const [pnews, setNews] = useState([]);

  useEffect(() => {
    dispatch(getPublishedNews());

    setNews(publishedNews);
  }, [message]);

  const settings = {
    infinite: false,
    speed: 700,
    slidesToShow: Math.min(3),
    slidesToScroll: 2,
    autoplay: false,
  };

  return (
    // <div className=" px-3 mt-4 rounded-md space-y-3 2xl:h-[450px] h-[250px] py-3 border  border-gray-700 my-3 bg-white shadow-md shadow-gray-500">
    <div className=" px-3 mt-4 rounded-md space-y-3 py-3 border h-[250px]   border-gray-700  bg-white shadow-md shadow-gray-500">
      <div className="flex justify-between">
        <h1 className="2xl:text-[2rem] font-semibold text-gray-600 mb-2">
          Published News
        </h1>
        <Link
          className="2xl:text-[2rem] text-sm underline text-blue-500 font-semibold px-4 2xl:py-2"
          to="/news"
        >
          View all
        </Link>
      </div>
      {status == "loading" ? (
        <div className="h-full py-11">
          <Loader type="bubble-scale" bgColor={"#AEB9B8"} size={50} />
        </div>
      ) : (
        <Slider {...settings}>
          {publishedNews &&
            pnews.map(
              (news) =>
                news &&
                news._id && ( // Check if news and news.id are defined
                  <Link to={`/newsdetails/${news.title}/${news._id}`} key={news._id}>
                    <div className=" space-x-3 hover:shadow-md hover:shadow-gray-600 duration-300  rounded-lg 2xl:h-[340px] md:h-[170px] 2xl:w-[540px] lg:w-[300px] md:w-[200px] h-36  relative">
                      <img
                        src={news.image1}
                        alt="news images"
                        className="relative rounded-lg 2xl:w-[540px] lg:w-[300px] md:w-[200px] inset-0 2xl:h-[320px] md:h-[170px] object-cover bg-no-repeat shadow-md"
                      />
                      <div className="2xl:w-[480px] lg:w-[320px] md:w-[200px] 2xl:text-[1.8rem] line-clamp-2 text-[0.9rem]  md:pt-[6rem] 2xl:pt-[12rem] pt-[4.5rem] absolute inset-0 z-50  text-white overflow-x-hidden px-2">
                        <h1> {news.title}</h1>
                      </div>
                    </div>
                  </Link>
                )
            )}
        </Slider>
      )}
    </div>
  );
};

export default PublishNews;
