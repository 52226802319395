// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const ImageUpload = createSlice({
  //STATE NAME
  name: "allMobiles",

  //STATE INITIAL VALUE
  initialState: {
    imageUrl1: "",
    imageUrl2: "",
    isLoading: "",
    uploaded1:false,
    uploaded2:false,
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    uploadFirstImageRequest(state) {
      state.isLoading = "loading";
    },
    uploadFirstImageSuccess(state, action) {
      state.isLoading = "succeeded";
      state.imageUrl1 = action.payload.imageUrl;
      state.uploaded1= true;
    },

    uploadSecondImageRequest(state) {
      state.isLoading = "loading";
    },

    uploadSecondImageSuccess(state, action) {
      state.isLoading = "succeeded";
      state.imageUrl2 = action.payload.imageUrl;
      state.uploaded2= true;
    },

    uploadSingleImageFail(state, action) {
      state.isLoading = "failed";
      state.error = action.payload;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  uploadFirstImageRequest,
  uploadFirstImageSuccess,
  uploadSecondImageRequest,
  uploadSecondImageSuccess,
  uploadSingleImageFail,
} = ImageUpload.actions;

//EXPORT REDUCER

export default ImageUpload.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const uploadFirstimage = (formData) => async (dispatch) => {

  dispatch(uploadFirstImageRequest());

  try {
    const url = `https://api.cloudinary.com/v1_1/di9vpom9l/image/upload`;

    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      dispatch(uploadSingleImageFail("Request timed out"));
    }

    const responseData = await response.json();

    dispatch(uploadFirstImageSuccess({ imageUrl: responseData.secure_url }));

  } catch (error) {
    if (error.name === "AbortError") {
      dispatch(uploadSingleImageFail("Request timed out"));
    } else {
      dispatch(uploadSingleImageFail(error.message));
    }
  }
};

export const uploadSecondImage = (formData) => async (dispatch) => {

  dispatch(uploadSecondImageRequest());

  try {
    const url = `https://api.cloudinary.com/v1_1/di9vpom9l/image/upload`;

    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      dispatch(uploadSingleImageFail("Request timed out"));
    }

    const responseData = await response.json();

    dispatch(uploadSecondImageSuccess({ imageUrl: responseData.secure_url }));

  } catch (error) {
    if (error.name === "AbortError") {
      dispatch(uploadSingleImageFail("Request timed out"));
    } else {
      dispatch(uploadSingleImageFail(error.message));
    }
  }
};
