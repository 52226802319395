import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  searchAds,
  getClientById,
  getTotalAds,
} from "../../../Redux/BuyandSell";
import MyAlert from "../../../Compnents/MyAlerts";
import { IoSearchSharp } from "react-icons/io5";

const AdsSearch = () => {
  const dispatch = useDispatch();
  const { searchedAds, status, postedby, totalAds } = useSelector(
    (state) => state.buyAndSell
  );

  const [city, setCity] = useState("");
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [trimmedAds, setTrimmedAds] = useState([]);
  const [alert, setAlert] = useState(false);
  const [popup, setPopup] = useState(false);

  const alertHandler = () => {
    setAlert(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!title && !brand && !city) {
      setAlert(true);
    } else {
      dispatch(searchAds(title, city, brand));
    }
  };

  useEffect(() => {
    if (totalAds.length > 5) {
      const trimmed = totalAds.slice(0, 5);
      setTrimmedAds(trimmed);
    } else {
      setTrimmedAds(totalAds);
    }
  }, [totalAds, trimmedAds]);

  return (
    <div>
      <form onSubmit={submitHandler} className="flex mt-4 space-x-2">
        <div className="text-[15px] bg-white border-2 border-blue-100 rounded-md hover:border-blue-200 duration-300">
          <input
            type="text"
            placeholder="Search By City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="outline-none items-center text-[16px] px-2 border-r"
          />
          <button
            type="submit"
            className="pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150"
          >
            <IoSearchSharp size={15} />
          </button>
        </div>
        <div className="text-[15px] bg-white border-2 border-blue-100 rounded-md hover:border-blue-200 duration-300">
          <input
            type="text"
            placeholder="Search By Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="outline-none items-center text-[16px] px-2 border-r"
          />
          <button
            type="submit"
            className="pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150"
          >
            <IoSearchSharp size={15} />
          </button>
        </div>
        <div className="text-[15px] bg-white border-2 border-blue-100 rounded-md hover:border-blue-200 duration-300">
          <input
            type="text"
            placeholder="Search By Brand"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            className="outline-none items-center text-[16px] px-2 border-r"
          />
          <button
            type="submit"
            className="pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150"
          >
            <IoSearchSharp size={17} />
          </button>
        </div>
      </form>

      {alert && (
        <MyAlert
          onClose={alertHandler}
          message={"Please fill at least one field!"}
        />
      )}

      <div className="mt-3">
        {/* Ads Header */}
        <div className="flex w-full text-white bg-gray-700 font-semibold">
          <span className="w-full text-sm border-2 py-2 text-center">
            Posted By
          </span>
          <span className="w-full text-sm border-2 py-2 text-center">
            Ads Title
          </span>
          <span className="w-full text-sm border-2 py-2 text-center">
            Ads Brand
          </span>
          <span className="w-full text-sm border-2 py-2 text-center">
            Ad Condition
          </span>
          <span className="w-full text-sm border-2 py-2 text-center">
            Condition Detail
          </span>
          <span className="w-full text-sm border-2 py-2 text-center">
            Price
          </span>
          <span className="w-full text-sm border-2 py-2 text-center">City</span>
        </div>

        {/* Ads Body */}
        {status === "loading" ? (
          <p>Loading...</p>
        ) : (
          searchedAds.map((item) => (
            <div key={item._id} className="flex w-full text-gray-800">
              <span
                className="w-full text-sm border-2 py-2 text-center line-clamp-1 cursor-pointer"
                onClick={() => {
                  dispatch(getClientById(item.uid));
                  dispatch(getTotalAds(item.uid));
                  setPopup(true);
                }}
              >
                CLICK
              </span>
              <span className="w-full text-sm border-2 py-2 text-center">
                {item.title}
              </span>
              <span className="w-full text-sm border-2 py-2 text-center">
                {item.brand}
              </span>
              <span className="w-full text-sm border-2 py-2 text-center">
                {item.conditionInNum}/10
              </span>
              <span className="w-full text-sm border-2 py-2 text-center">
                {item.condition}
              </span>
              <span className="w-full text-sm border-2 py-2 text-center">
                {item.price}
              </span>
              <span className="w-full text-sm border-2 py-2 text-center">
                {item.city}
              </span>
            </div>
          ))
        )}
      </div>

      {popup && (
        <div
          className="bg-black/90 absolute inset-0 z-50 flex justify-center items-center"
          onClick={() => setPopup(false)}
        >
          {status === "loading" ? (
            "Loading !!!"
          ) : (
            <div className="bg-white w-[50%] p-10 grid md:grid-cols-2 grid-cols-1">
              <div>
                <div className="flex justify-center items-center">
                <img src={postedby.imageUrl} alt="client" className="rounded-full w-24 h-24" />
                </div>
                
                <div className="flex">
                  <b>
                    <p className="text-gray-500">Name:</p>
                  </b>

                  <h1>{postedby.name}</h1>
                </div>

                <div className="flex">
                  <b>
                    <p className="text-gray-500">Email:</p>
                  </b>

                  <h1>{postedby.email}</h1>
                </div>

                <div className="flex">
                  <b>
                    <p className="text-gray-500">Favourites:</p>
                  </b>

                  <h1>{postedby.favourites.length}</h1>
                </div>

                <div className="flex">
                  <b>
                    <p className="text-gray-500">Followers:</p>
                  </b>

                  <h1>{postedby.following.length}</h1>
                </div>

                <div className="flex">
                  <b>
                    <p className="text-gray-500">Total Ads:</p>
                  </b>

                  <h1>{postedby.totalAds}</h1>
                </div>

               
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {totalAds.length > 0 &&
                  trimmedAds.map((ad) => (
                    <div className="border h-auto p-2 rounded cursor-pointer">
                      <img
                        src={ad.images[0]}
                        alt="ad"
                        className="w-full h-52"
                      />
                      <p>
                        <span className="text-red-500">Title: </span>
                        {ad.title}
                      </p>
                      <p>
                        <span className="text-green-800">Brand: </span>
                        {ad.brand}
                      </p>
                      <p>
                        <span className="text-blue-700">Condition: </span>
                        {ad.condition}
                      </p>
                    </div>
                  ))}

                {totalAds.length > 6 && (
                  <Link
                    className="flex justify-items-start items-center mr-[50px]"
                    to={`/totalAds/${postedby.uid}`}
                  >
                    <button>
                      <b>see more...</b>
                    </button>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdsSearch;
