import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
// import parse from "html-react-parser";
import { useSelector } from "react-redux";


const AllPublishNews = () => {
  const { publishedNews, status } = useSelector((state) => state.news);
  console.log("what comming in publish news", publishedNews);

  return (
    <div>
      <button>Edit</button>
      <h1 className="text-2xl text-gray-700 font-semibold text-center">
        All Publish News
      </h1>
      <div className="grid grid-cols-3 justify-between gap-4 my-3 mx-2  pb-5 text-gray-500 ">
        {publishedNews &&
          publishedNews.map((news) => (
            <div className="hover:scale-105 duration-150 h-[240px]  space-y-3 pb-4 bg-white shadow-md shadow-gray-900 ">
              <Link to={`/newsdetails/${news.title}/${news._id}`} key={news._id}>
                <div className="hover:text-gray-400 ">
                  <img
                    src={news.image1}
                    alt="news images"
                    className="  object-center bg-no-repeat h-[180px] w-[400px] "
                  />
                  <div className="text-sm px-2 space-y-1 mx-3 mt-2">
                    <h1 className="font-bold">BRAND : {news.brand}</h1>
                    <p className="font-semibold  line-clamp-1">
                      {news.title}
                    </p>
                    {/* <p className="line-clamp-2 text-justify">
                    {parse(news.data.description)}
                  </p> */}
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AllPublishNews;
