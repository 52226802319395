import React, { useEffect, useState } from "react";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../Redux/UsersReducer.js";
import { deleteUser } from "../../../Redux/UserReducer.js";
import UserEditForm from "./UserEditForm";

const UsersDetails = ({ todo, handleDelete, handleEditPageVisibility }) => {

  const [isUserEdited, setUserEdit] = useState("");

  const [userID, setUserID] = useState("");

  // Define common responsive class for table cells (Name, Email, etc.)

  const cellClass = `
    2xl:w-[240px] xl:w-[120px] lg:w-[100px] md:w-[100px] w-[40px] 
    2xl:py-4 xl:px-2 
    text-[11px] md:text-xs 2xl:text-[1.6rem] 
    line-clamp-1 2xl:line-clamp-none
  `;

  const actionIconClass = "p-[3px] md:p-0";
  const iconSizes = { small: 20, large: 25, larger: 40 };

  // Table header and action icons
  const headerClass = `
    flex justify-between items-center 
    2xl:py-[13px] xl:py-3 
    bg-gray-600 text-white font-bold
    md:px-1 lg:py-3 md:py-2
  `;

  const { users, status } = useSelector((state) => state.users);

  const { isEdited } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (isEdited) window.location.reload("/");
  }, [isEdited]);

  function showEditForm(id="") {
    setUserID(id);

    setUserEdit(!isEdited);
  }

  return (
    <div>
      {isUserEdited && (
        <UserEditForm handleEditPageVisibility={showEditForm} userID={userID} />
      )}

      <div className="w-full mt-2 bg-white shadow-md overflow-x-hidden ">
        {/* Header */}
        <div className={headerClass}>
          <p className={cellClass}>Name</p>
          <p className={cellClass}>Email</p>
          <p className={cellClass}>Role</p>
          <p className={cellClass}>Login time</p>
          <p className={cellClass}>Logout time</p>
          <p className={cellClass}>User Activity</p>
          {/* Action Icons */}
          <MdEdit
            size={iconSizes.small}
            className={`${actionIconClass} xl:hidden 2xl:hidden flex`}
          />
          <MdEdit
            size={iconSizes.large}
            className={`${actionIconClass} 2xl:hidden xl:flex hidden`}
          />
          <MdEdit
            size={iconSizes.larger}
            className={`${actionIconClass} 2xl:flex xl:hidden hidden`}
          />
          <MdDeleteForever
            size={iconSizes.small}
            className={`${actionIconClass} xl:hidden 2xl:hidden flex`}
          />
          <MdDeleteForever
            size={iconSizes.large}
            className={`${actionIconClass} 2xl:hidden xl:flex hidden`}
          />
          <MdDeleteForever
            size={iconSizes.larger}
            className={`${actionIconClass} 2xl:flex xl:hidden hidden`}
          />
        </div>

        {/* User Data */}
        <div className="xl:h-[25rem] lg:h-[14rem] md:h-[13rem] overflow-y-auto px-1 border">
          {status == "loading"
            ? "Loading !!!"
            : users.map((user, index) => (
                <div
                  key={index}
                  className={`flex justify-between items-center 
      2xl:h-[60px] py-2 border-b font-semibold
      space-x-1 md:space-x-0
    `}
                >
                  <p className={cellClass}>{user.name}</p>
                  <p className={cellClass}>{user.email}</p>
                  <p className={cellClass}>{user.role}</p>
                  <p className={cellClass}>03:49:94</p>
                  <p className={cellClass}>47:48:00</p>
                  <Link to="/useractivity" className={cellClass}>
                    User Activity
                  </Link>
                  <button
                    onClick={() => {
                      showEditForm(user.id);
                    }}
                  >
                    <MdEdit
                      size={iconSizes.small}
                      className={`${actionIconClass} xl:hidden 2xl:hidden flex`}
                    />
                    <MdEdit
                      size={iconSizes.large}
                      className={`${actionIconClass} 2xl:hidden xl:flex hidden`}
                    />
                    <MdEdit
                      size={iconSizes.larger}
                      className={`${actionIconClass} 2xl:flex xl:hidden hidden`}
                    />
                  </button>
                  <button
                    onClick={() => {
                      dispatch(deleteUser(user.uid, user.id));
                    }}
                  >
                    <MdDeleteForever
                      size={iconSizes.small}
                      className={`${actionIconClass} xl:hidden 2xl:hidden flex`}
                    />
                    <MdDeleteForever
                      size={iconSizes.large}
                      className={`${actionIconClass} 2xl:hidden xl:flex hidden`}
                    />
                    <MdDeleteForever
                      size={iconSizes.larger}
                      className={`${actionIconClass} 2xl:flex xl:hidden hidden`}
                    />
                  </button>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default UsersDetails;

// import React from "react";
// import { MdEdit } from "react-icons/md";
// import { MdDeleteForever } from "react-icons/md";
// import { Link } from "react-router-dom";

// const UsersDetails = ({ todo, handleEditButton, handleDelete }) => {
//   return (
//     <div className="md:w-full mt-2 bg-white w-[467px] overflow-x-hidden shadow-md shadow-gray-900">
//       {/* details header  */}
//       <div className=" md:px-1 flex justify-between items-center 2xl:space-x-0 xl:px-2 space-x-1 md:space-x-0 2xl:h-[60px] xl:py-3  md:font-semibold font-bold 2xl:text-[1.6rem]  md:text-xs text-[11px] text-start text-white bg-gray-600">
//         <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//           Name
//         </p>
//         <p className="2xl:w-[270px] xl:w-[150px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 xl:px-2">
//           Email
//         </p>
//         <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//           Role
//         </p>
//         <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//           Login time
//         </p>
//         <p className="2xl:w-[200px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 xl:px-2">
//           Logout time
//         </p>
//         <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//           User Activity
//         </p>
//         <p>
//           <MdEdit size={20} className="p-[3px] md:p-0 xl:hidden flex " />
//         </p>
//         <p>
//           <MdEdit size={25} className="p-[3px] md:p-0 xl:flex hidden" />
//         </p>
//         <p>
//           <MdDeleteForever
//             size={20}
//             className="p-[3px] md:p-0 xl:hidden flex"
//           />
//         </p>
//         <p>
//           <MdDeleteForever
//             size={25}
//             className="p-[3px] md:p-0 xl:flex hidden"
//           />
//         </p>
//       </div>
//       <div className=" md:h-[19rem] h-[18rem] overflow-y-auto px-1 border">
//         {todo.map((info, index) => (
//           <div
//             key={index}
//             className="py-2 border-b flex justify-between items-center 2xl:space-x-0 space-x-1 md:space-x-0 2xl:h-[60px] xl:h-[40  md:font-semibold font-bold 2xl:text-[1.6rem]  md:text-xs text-[11px] "
//           >
//             <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//               {info.name}
//             </p>
//             <p className="2xl:w-[270px] xl:w-[150px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 xl:px-2">
//               {info.email}
//             </p>
//             <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//               {info.role}
//             </p>
//             <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//               03:49:94
//             </p>
//             <p className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2">
//               47:48:00
//             </p>
//             <Link
//               to={"/useractivity"}
//               className="2xl:w-[220px] xl:w-[120px] md:w-[110px]  2xl:line-clamp-none line-clamp-1 w-[40px] 2xl:py-4 2xl:px-2 "
//             >
//               User Activity
//             </Link>
//             <button onClick={() => handleEditButton(index)}>
//               <p>
//                 <MdEdit size={20} className="p-[3px] md:p-0 xl:hidden flex  " />
//               </p>
//               <p>
//                 <MdEdit size={25} className="p-[3px] md:p-0 xl:flex hidden xl:ml-6" />
//               </p>
//             </button>
//             <button onClick={() => handleDelete(index)}>
//               <p>
//                 <MdDeleteForever
//                   size={20}
//                   className="p-[3px] md:p-0 xl:hidden flex"
//                 />
//               </p>
//               <p>
//                 <MdDeleteForever
//                   size={25}
//                   className="p-[3px] md:p-0 xl:flex hidden xl:ml-8"
//                 />
//               </p>
//             </button>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default UsersDetails;
