//HEADER FILES

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = 
{
  apiKey: "AIzaSyDjguNTinw6Nh-fi9b9D0Ijqt14ytrr6-c",
  authDomain: "mobile-mate-a76dd.firebaseapp.com",
  projectId: "mobile-mate-a76dd",
  storageBucket: "mobile-mate-a76dd.appspot.com",
  messagingSenderId: "481199753272",
  appId: "1:481199753272:web:a714019275328c1e1d3ecd",
};

//INITIALIZE FIREBASE
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

//EXPORT

export { auth };
