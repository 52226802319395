// import React, { useState } from "react";
// import { BsThreeDotsVertical } from "react-icons/bs";

// const Ads = () => {
//   const [menuButtons, setMenuButtons] = useState(false);

//   const handleShowButtons = () => {
//     setMenuButtons(!menuButtons);
//   };
//   return (
//     <div className="space-y-6">
//       <h1 className="text-2xl text-gray-600 font-semibold  text-center mt-6">
//         Ads Section
//       </h1>

//       {/* =================== Top section adds ==================== */}

//       {menuButtons && (
//         <div className="absolute  bg-white z-10 top-10  right-20  border w-40 shadow-md ">
//           <button className="block py-2 text-sm border-b w-full hover:bg-gray-200 duration-300">
//             Delete
//           </button>
//           <button className="block py-2 text-sm border-b w-full hover:bg-gray-200 duration-300">
//             Edit
//           </button>
//           <button
//             onClick={handleShowButtons}
//             className="block py-2 text-sm w-full hover:bg-gray-200 duration-300"
//           >
//             Cancel
//           </button>
//         </div>
//       )}
//       <div className="hover:bg-gray-300 duration-300 flex font-semibold justify-between text-sm h-16 p-2 bg-gray-200  border-[1px] rounded-sm items-center border-gray-400 shadow-md shad">
//         <h1 className="  w-40 font-semibold  outline-none p-2 rounded-sm  text-gray-600">
//           Top section image
//         </h1>
//         <select className=" w-36 outline-none p-2 rounded-sm bg-gray-600  text-white">
//           <option value="" className="text-gray-500 font-semibold ">
//             Pages
//           </option>
//         </select>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Submitted by
//         </p>

//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Issue date
//         </p>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Expiry date
//         </p>
//         <div>
//           <input
//             type="file"
//             name=""
//             id=""
//             className=" w-48 text-center outline-none p-2 rounded-sm  text-gray-600"
//           />
//         </div>
//         <button onClick={handleShowButtons}>
//           <BsThreeDotsVertical size={20} />
//         </button>
//       </div>

//       {/* =================== left section adds ==================== */}

//       <div className="hover:bg-gray-300 duration-300 flex font-semibold justify-between text-sm h-16 p-2 bg-gray-200  border-[1px] rounded-sm items-center border-gray-400 shadow-md shad">
//         <h1 className="  w-40 font-semibold  outline-none p-2 rounded-sm  text-gray-600">
//           Left section image
//         </h1>
//         <select className=" w-36 outline-none p-2 rounded-sm bg-gray-600  text-white">
//           <option value="" className="text-gray-500 font-semibold ">
//             Pages
//           </option>
//         </select>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Submitted by
//         </p>

//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Issue date
//         </p>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Expiry date
//         </p>
//         <div>
//           <input
//             type="file"
//             name=""
//             id=""
//             className=" w-48 text-center outline-none p-2 rounded-sm  text-gray-600"
//           />
//         </div>
//         <button onClick={handleShowButtons}>
//           <BsThreeDotsVertical size={20} />
//         </button>
//       </div>

//       {/* =================== Right section adds ==================== */}
//       <div className="hover:bg-gray-300 duration-300 flex font-semibold justify-between text-sm h-16 p-2 bg-gray-200  border-[1px] rounded-sm items-center border-gray-400 shadow-md shad">
//         <h1 className="  w-40 font-semibold  outline-none p-2 rounded-sm  text-gray-600">
//           Right section image
//         </h1>
//         <select className=" w-36 outline-none p-2 rounded-sm bg-gray-600  text-white">
//           <option value="" className="text-gray-500 font-semibold ">
//             Pages
//           </option>
//         </select>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Submitted by
//         </p>

//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Issue date
//         </p>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Expiry date
//         </p>
//         <div>
//           <input
//             type="file"
//             name=""
//             id=""
//             className=" w-48 text-center outline-none p-2 rounded-sm  text-gray-600"
//           />
//         </div>
//         <button onClick={handleShowButtons}>
//           <BsThreeDotsVertical size={20} />
//         </button>
//       </div>

//       {/* =================== Bottom section adds ==================== */}

//       <div className="hover:bg-gray-300 duration-300 flex font-semibold justify-between text-sm h-16 p-2 bg-gray-200  border-[1px] rounded-sm items-center border-gray-400 shadow-md shad">
//         <h1 className="  w-40 font-semibold  outline-none p-2 rounded-sm  text-gray-600">
//           Bottom section image
//         </h1>
//         <select className=" w-36 outline-none p-2 rounded-sm bg-gray-600  text-white">
//           <option value="" className="text-gray-500 font-semibold ">
//             Pages
//           </option>
//         </select>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Submitted by
//         </p>

//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Issue date
//         </p>
//         <p className=" w-28 text-center outline-none p-2 rounded-sm  text-gray-600">
//           Expiry date
//         </p>
//         <div>
//           <input
//             type="file"
//             name=""
//             id=""
//             className=" w-48 text-center outline-none p-2 rounded-sm  text-gray-600"
//           />
//         </div>
//       </div>
//       <button onClick={handleShowButtons}>
//         <BsThreeDotsVertical size={20} />
//       </button>
//     </div>
//   );
// };

// export default Ads;

import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

const AdsSection = ({ sectionTitle, isActive, onToggleMenu }) => {
  return (
    <div className="relative hover:bg-gray-300 duration-300 flex font-semibold justify-between text-sm h-16 p-2 bg-gray-200 border-[1px] rounded-sm items-center border-gray-400 shadow-md">
      <h1 className="w-40 font-semibold p-2 rounded-sm text-gray-600">
        {sectionTitle}
      </h1>
      <select className="w-36 outline-none p-2 rounded-sm bg-gray-600 text-white">
        <option value="" className="text-gray-500 font-semibold">
          Pages
        </option>
      </select>
      <p className="w-28 text-center p-2 rounded-sm text-gray-600">
        Submitted by
      </p>
      <p className="w-28 text-center p-2 rounded-sm text-gray-600">
        Issue date
      </p>
      <p className="w-28 text-center p-2 rounded-sm text-gray-600">
        Expiry date
      </p>
      <p className="w-28 text-center p-2 rounded-sm text-gray-600">
        Size of Image
      </p>
      <div>
        <input
          type="file"
          className="w-40 text-center p-2 rounded-sm text-gray-600"
        />
      </div>
      <button onClick={onToggleMenu}>
        <BsThreeDotsVertical size={20} />
      </button>

      {isActive && (
        <div className="absolute bg-white z-10 top-10 right-0 border w-40 shadow-md">
          <button className="block py-2 text-sm border-b w-full hover:bg-gray-200 duration-300">
            Delete
          </button>
          <button className="block py-2 text-sm border-b w-full hover:bg-gray-200 duration-300">
            Edit
          </button>
          <button
            onClick={onToggleMenu}
            className="block py-2 text-sm w-full hover:bg-gray-200 duration-300"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

const AdsDetails = () => {
  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    "Top section image",
    "Left section image",
    "Right section image",
    "Bottom section image",
  ];

  const handleToggleMenu = (index) => {
    setActiveSection((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="space-y-6">
      
      <h1 className="text-2xl text-gray-600 font-semibold text-center mt-6">
        Ads Section
      </h1>

      {sections.map((section, index) => (
        <AdsSection
          key={section}
          sectionTitle={section}
          isActive={activeSection === index}
          onToggleMenu={() => handleToggleMenu(index)}
        />
      ))}
    </div>
  );
};

export default AdsDetails;
