// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getUserLogs, mobilesPerBrand } from "../../Redux/logsReducer";
// import { Pie } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// // Registering necessary chart elements with Chart.js
// ChartJS.register(ArcElement, Tooltip, Legend);

// export default function Record() {
//   const dispatch = useDispatch();

//   const { userLogs, brandsMobilesCount } = useSelector((state) => state.logs);

//   const countBrands = () => {
//     // Create a copy of the counts object to avoid direct mutation
//     let updatedCounts = {
//       TEL: 0,
//       SAMSUNG: 0,
//       INFINIX: 0,
//       ONEPLUS: 0,
//       APPLE: 0,
//       MOTOROLA: 0,
//       HTC: 0,
//       OPPO: 0,
//       XIAOMI: 0,
//       TECHNO: 0,
//       HUAWEI: 0,
//       VIVO: 0,
//       LENOVO: 0,
//       GOOGLE: 0,
//       NOKIA: 0,
//       SPARX: 0,
//       REALME: 0,
//       POCO: 0,
//       QMOBILE: 0,
//       SONY: 0,
//       LG: 0,
//       ZTE: 0,
//       GIONEE: 0,
//       TOSHIBA: 0,
//       PANASONIC: 0,
//       ALCATEL: 0,
//       BLACKBERRY: 0,
//     };

//     // ITATE USER LOG AND ITRATE EACH PROPERTY OF OBJECT

//     userLogs.forEach((item) => {
//       Object.keys(updatedCounts).forEach((brand) => {
//         //CHECK IF EACH PROPERTY OF UPDATEDCOUNTS IS PRESEENT IN USERLOGIN URL

//         if (item.url.includes(brand)) {
//           updatedCounts[brand] += 1;
//         }
//       });
//     });

//     // Dispatch the updated counts to the Redux store
//     dispatch(mobilesPerBrand(updatedCounts));
//   };

//   useEffect(() => {
//     if (userLogs.length > 0) countBrands();
//   }, [userLogs]);

//   useEffect(() => {
//     dispatch(getUserLogs());
//   }, [dispatch]);

//   // Preparing the data to be used in the Pie chart
//   const chartData = {
//     labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
//     datasets: [
//       {
//         data: Object.values(brandsMobilesCount), // Counts of each brand
//         backgroundColor: [
//           "#FF6384",
//           "#36A2EB",
//           "#FFCE56",
//           "#4BC0C0",
//           "#FF9F40",
//           "#9966FF",
//           "#FF3366",
//           "#FF33CC",
//           "#33FF57",
//           "#FF5733",
//           "#33B5E5",
//           "#FFB74D",
//           "#009688",
//           "#E91E63",
//           "#8BC34A",
//           "#CDDC39",
//           "#FFEB3B",
//           "#00BCD4",
//           "#FF5722",
//           "#2196F3",
//           "#673AB7",
//           "#9C27B0",
//           "#FF9800",
//           "#FFEB3B",
//           "#607D8B",
//         ], // You can add more colors here or randomize them
//         hoverBackgroundColor: [
//           "#FF6384",
//           "#36A2EB",
//           "#FFCE56",
//           "#4BC0C0",
//           "#FF9F40",
//           "#9966FF",
//           "#FF3366",
//           "#FF33CC",
//           "#33FF57",
//           "#FF5733",
//           "#33B5E5",
//           "#FFB74D",
//           "#009688",
//           "#E91E63",
//           "#8BC34A",
//           "#CDDC39",
//           "#FFEB3B",
//           "#00BCD4",
//           "#FF5722",
//           "#2196F3",
//           "#673AB7",
//           "#9C27B0",
//           "#FF9800",
//           "#FFEB3B",
//           "#607D8B",
//         ], // Hover color
//         borderWidth: 1, // Border width for each segment
//       },
//     ],
//   };

//   return (
//     <div>
//         <div className="w-[500px] h-[500px] m-auto">
//         <Pie data={chartData}  />
//         </div>
      

//       {/* Table displaying brand counts */}

//       <table className="table-auto w-full border-collapse border border-gray-300">
//         <thead>
//           <tr>
//             <th className="border-b p-2 text-left">Brand</th>
//             <th className="border-b p-2 text-left">Clicks</th>
//           </tr>
//         </thead>
//         <tbody>
//           {/* ITRATE OBJECT */}

//           {Object.entries(brandsMobilesCount).map(([brand, count]) => (
//             <tr key={brand}>
//               <td className="border-b p-2">{brand}</td>
//               <td className="border-b p-2">{count}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getUserLogs, mobilesPerBrand } from "../../Redux/logsReducer";
// import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// // Registering necessary chart elements with Chart.js for bar chart
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// export default function Record() {
//   const dispatch = useDispatch();

//   const { userLogs, brandsMobilesCount } = useSelector((state) => state.logs);

//   const countBrands = () => {
//     // Create a copy of the counts object to avoid direct mutation
//     let updatedCounts = {
//       TEL: 0,
//       SAMSUNG: 0,
//       INFINIX: 0,
//       ONEPLUS: 0,
//       APPLE: 0,
//       MOTOROLA: 0,
//       HTC: 0,
//       OPPO: 0,
//       XIAOMI: 0,
//       TECHNO: 0,
//       HUAWEI: 0,
//       VIVO: 0,
//       LENOVO: 0,
//       GOOGLE: 0,
//       NOKIA: 0,
//       SPARX: 0,
//       REALME: 0,
//       POCO: 0,
//       QMOBILE: 0,
//       SONY: 0,
//       LG: 0,
//       ZTE: 0,
//       GIONEE: 0,
//       TOSHIBA: 0,
//       PANASONIC: 0,
//       ALCATEL: 0,
//       BLACKBERRY: 0,
//     };

//     // Iterate over user logs and count brand occurrences
//     userLogs.forEach((item) => {
//       Object.keys(updatedCounts).forEach((brand) => {
//         if (item.url.includes(brand)) {
//           updatedCounts[brand] += 1;
//         }
//       });
//     });

//     // Dispatch the updated counts to the Redux store
//     dispatch(mobilesPerBrand(updatedCounts));
//   };

//   useEffect(() => {
//     if (userLogs.length > 0) countBrands();
//   }, [userLogs]);

//   useEffect(() => {
//     dispatch(getUserLogs());
//   }, [dispatch]);

//   // Preparing the data for the Bar chart
//   const chartData = {
//     labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
//     datasets: [
//       {
//         label: 'Mobile Clicks per Brand',
//         data: Object.values(brandsMobilesCount), // Counts of each brand
//         backgroundColor: '#4BC0C0', // You can customize the color
//         borderColor: '#36A2EB', // Border color of the bars
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Chart options for better presentation
//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       title: {
//         display: true,
//         text: 'Mobile Clicks per Brand',
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => {
//             return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Brands',
//         },
//       },
//       y: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Click Count',
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <div >
//         <Bar data={chartData} options={chartOptions} />
//       </div>

//       {/* Table displaying brand counts */}
//       <table className="table-auto w-full border-collapse border border-gray-300">
//         <thead>
//           <tr>
//             <th className="border-b p-2 text-left">Brand</th>
//             <th className="border-b p-2 text-left">Clicks</th>
//           </tr>
//         </thead>
//         <tbody>
//           {Object.entries(brandsMobilesCount).map(([brand, count]) => (
//             <tr key={brand}>
//               <td className="border-b p-2">{brand}</td>
//               <td className="border-b p-2">{count}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserLogs, mobilesPerBrand } from "../../Redux/logsReducer";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// Registering necessary chart elements with Chart.js for bar chart
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Record() {
  const dispatch = useDispatch();

  const { userLogs, brandsMobilesCount } = useSelector((state) => state.logs);

  const countBrands = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    userLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(mobilesPerBrand(updatedCounts));
  };

  useEffect(() => {
    if (userLogs.length > 0) countBrands();
  }, [userLogs]);

  useEffect(() => {
    dispatch(getUserLogs());
  }, [dispatch]);

  // Define an array of colors for each bar
  const barColors = [
    "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#FF9F40", "#9966FF",
    "#FF3366", "#FF33CC", "#33FF57", "#FF5733", "#33B5E5", "#FFB74D",
    "#009688", "#E91E63", "#8BC34A", "#CDDC39", "#FFEB3B", "#00BCD4",
    "#FF5722", "#2196F3", "#673AB7", "#9C27B0", "#FF9800", "#FFEB3B",
    "#607D8B", "#FF4081"
  ];

  // Preparing the data for the Bar chart
  const chartData = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: 'Mobile Clicks per Brand',
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(0, Object.keys(brandsMobilesCount).length), // Apply unique color to each bar
        borderColor: '#36A2EB', // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options for better presentation
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Mobile Clicks per Brand',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Brands',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Click Count',
        },
      },
    },
  };

  return (
    <div>
        <h1 className="text-blue-950 text-4xl text-center mb-4">TOTAL CLICKS TILL NOW</h1>
      <div className="p-6">
        <Bar data={chartData} options={chartOptions} />
      </div>

      {/* Table displaying brand counts */}
      <table className="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border-b p-2 text-left">Brand</th>
            <th className="border-b p-2 text-left">Clicks</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(brandsMobilesCount).map(([brand, count]) => (
            <tr key={brand}>
              <td className="border-b p-2">{brand}</td>
              <td className="border-b p-2">{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}


