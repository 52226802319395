import React from "react";

const ClientSearch = () => {
  return (
    <div className="mt-4">
      {/* =================ads header==================  */}
      <div className=" flex w-full text-white bg-gray-700 font-semibold">
        <span className="w-full text-sm border-2 py-2 text-center ">
          Client S.No{" "}
        </span>
        <span className="w-full text-sm border-2 py-2 text-center ">
          Client Name
        </span>
        <span className="w-full text-sm border-2 py-2 text-center ">
          Client Email
        </span>
        <span className="w-full text-sm border-2 py-2 text-center ">
          Client contact Number
        </span>
      </div>

      {/* =================ads body==================  */}

      <div className=" flex w-full  text-gray-800 ">
        <span className="w-full text-sm border-2 py-2 text-center ">
          Client S.No{" "}
        </span>
        <span className="w-full text-sm border-2 py-2 text-center ">
          Client Name
        </span>
        <span className="w-full text-sm border-2 py-2 text-center ">
          mariarashid$5237@gamil.com
        </span>
        <span className="w-full text-sm border-2 py-2 text-center ">
          03749273738
        </span>
      </div>
    </div>
  );
};

export default ClientSearch;
