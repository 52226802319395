import React, { useState } from "react";

import { postSingleBrand } from "../../../Redux/SingleBrandReducer";

import { useDispatch, useSelector } from "react-redux";

import ReactQuill from "react-quill";

import { IoArrowBack } from "react-icons/io5";

import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";

import ImageUpload1 from "../../ImageUpload1";
import ImageUpload2 from "../../ImageUpload2";

const AddMobileBrand = ({ handleAddMobileFormVisible }) => {
  const dispatch = useDispatch();

  const { token, currentUser } = useSelector((state) => state.user);

  const { imageUrl1, imageUrl2, isLoading, uploaded1, uploaded2 } = useSelector(
    (state) => state.imageUplaod
  );
 

  function submitHandler(e) {
    e.preventDefault();

    const data  = {
      brand:  name,
      description,
      rating: Number(rating),
      imageUrl: imageUrl1,
      bannerUrl: imageUrl2,
    };

    dispatch(postSingleBrand(data,currentUser));

    handleAddMobileFormVisible();
  }

  function handleName(e) {
    setName(e.target.value);
  }

  function handleDescription(content) {
    setDescription(content);
  }

  function handleRating(e) {
    setRating(e.target.value);
  }

  // function handleAmbassador(e) {
  //   setAmbassador(e.target.value);
  // }

  // const handleContact = (e) => {
  //   setContact(e.target.value);
  // };

  // const handleWebsite = (e) => {
  //   setWebsite(e.target.value);
  // };

  // const handleFileChange = (e) => {
  //   setImage(e.target.files[0]);
  // };

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  // const [ambassador, setAmbassador] = useState("");
  const [rating, setRating] = useState(0);
  // const [image, setImage] = useState("");
  // const [contact, setContact] = useState("");
  // const [website, setWebsite] = useState("");

  return (
    <div className="absolute  inset-0 border-gray-900 h-[930px] overflow-y-auto bg-black/90  z-10">
      <div className="bg-white w-[700px]   mx-auto  mt-3 rounded-sm border px-9 py-2">
        <button
          onClick={handleAddMobileFormVisible}
          className=" font-bold  text-gray-800 text-sm rounded-sm "
        >
          <IoArrowBack />
        </button>
        <h1 className="text-center font-bold text-[24px] text-gray-600 mb-4">
          Add Brand Details
        </h1>

        <form action="" onSubmit={submitHandler} className=" text-sm space-y-4">
          <div className="">
            <label htmlFor="" className="font-semibold mb-2 text-gray-700">
              Brand Name
            </label>
            <input
              required
              type="text"
              name="brands"
              id=""
              placeholder="please enter mobile brand name"
              onChange={handleName}
              value={name}
              className="w-full h-12  capitalize text px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm "
            />
          </div>
          <div>
            <label htmlFor="" className="font-semibold mb-2 text-gray-700">
              Ratings
            </label>
            <input
              required
              type="number"
              name="rating"
              placeholder="No of mobiles "
              value={rating}
              onChange={handleRating}
              className="w-full h-12  capitalize text px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm "
            />
          </div>


          <div>
            <label htmlFor="" className="font-semibold mb-2 text-gray-700">
              Description
            </label>
            <div className="h-[190px]">
              <ReactQuill
                placeholder="Description"
                required
                value={description}
                onChange={handleDescription}
                className="h-[160px]"
                theme="snow" // Optional, you can use other themes like 'bubble'
              />
            </div>
          </div>

          <div className="flex mt-4">
            <div>
              <label htmlFor="" className="font-semibold mb-2 text-gray-700">
                Choose Logo
              </label>
              <ImageUpload1 className=" text-sm text-gray-700 outline-none  rounded-sm " />
            </div>
            <div>
              <label htmlFor="" className="font-semibold mb-2 text-gray-700">
                Choose Banner
              </label>
              <ImageUpload2 className=" text-sm text-gray-700 outline-none  rounded-sm " />
            </div>
          </div>

          {isLoading === "loading" ? (
            <button>Wait!!</button>
          ) : (
            <button
              type="submit"
              className="w-full h-12 mb-4 mt-8 text-sm border-gray-400 rounded-sm bg-gray-800 text-white hover:bg-gray-900 duration-300"
            >
              Submit
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddMobileBrand;
