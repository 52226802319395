import React, { useEffect, useState } from "react";
import MyAlert from "../../MyAlerts";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUser, updateSingleUser } from "../../../Redux/UserReducer";

const UserEditForm = ({ handleEditPageVisibility, userID }) => {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [role, setRole] = useState("");

  const { singleUser, status, currentUser, message, isEdited } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (singleUser) {
      setName(singleUser.name);
      setEmail(singleUser.email);
      setPassword(singleUser.password);
      setRole(singleUser.role);
    }
  }, [singleUser]);

  useEffect(() => {
    dispatch(getSingleUser(userID));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const data = { name, email, password, role };

    dispatch(updateSingleUser(userID, data));
  }

  return (
    <div className=" bg-gray-100 absolute z-10 inset-0 h-[700px]">
      {isEdited == true && <MyAlert />}

      {status == "loading" ? (
        "Loading"
      ) : (
        <form
          action=""
          className="space-y-6 py-7 px-4 w-[400px] bg-white mx-auto border my-2"
          onSubmit={handleSubmit}
        >
          <h1 className="2xl:h-[60px] 2xl:text-[3rem] md:text-xl  text-[1rem]  font-semibold text-center">
            Enter details
          </h1>

          <div>
            <label htmlFor="" className="mb-2 font-semibold">
              Name
            </label>
            <input
              required
              type="name"
              name="name"
              value={name}
              className=" px-2  w-full outline-none border-2  h-12 rounded-md"
              placeholder="Enter Name"
            />
          </div>
          <div>
            <label htmlFor="" className="mb-2 font-semibold">
              Email
            </label>
            <input
              required
              type="text"
              name="email"
              value={email}
              className=" px-2   w-full outline-none border-2   h-12 rounded-md"
              placeholder="Enter email"
            />
          </div>
          <div>
          <label htmlFor="" className="mb-2 font-semibold">
             Password
            </label>
            <input
              required
              type="password"
              value={password}
              className=" px-2   w-full outline-none border-2   h-12 rounded-md"
              placeholder="Password"
              minlength="6"
            />
          </div>

          <div className="  rounded  ">
          <label htmlFor="" className="mb-2 font-semibold">
             Password
            </label>
            {currentUser.role == "admin" && (
              <select
              className=" px-2   w-full outline-none border-2   h-12 rounded-md"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                name="role"
                required
              >
                <option
                  value=""
                  disabled
                  className="w-[30px] 2xl:text-sm text-white bg-black"
                >
                  Select a Role
                </option>

                <option
                  value="manager"
                  className="w-[30px] 2xl:text-sm text-white bg-black"
                >
                  Manager
                </option>

                <option
                  value="publisher"
                  className="w-[30px] 2xl:text-sm text-white bg-black"
                >
                  Publisher
                </option>

                <option
                  value="mobileseditor"
                  className="w-[30px] 2xl:text-sm text-white bg-black"
                >
                  Mobiles and Brands Editor
                </option>

                <option
                  value="glossaryeditor"
                  className="w-[30px] 2xl:text-sm text-white bg-black"
                >
                  Glossary Editor
                </option>

                <option
                  value="adseditor"
                  className="w-[30px] 2xl:text-sm text-white bg-black"
                >
                  Ads Editor
                </option>
              </select>
            )}
          </div>
          <button
            type="submit"
            className="hover:bg-gray-700 mt-5 text-sm bg-gray-500 rounded-md  md:w-full w-[230px] 2xl:h-[100px]  md:h-[40px] h-[30px] text-white font-semibold  md:pt-0 duration-150 px-1 outline-none 2xl:text-[1.9rem]"
          >
            Submit!!
          </button>
        </form>
      )}
    </div>
  );
};

export default UserEditForm;
