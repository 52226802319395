import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./blog.css"; // Import CSS file
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaParagraph, FaHeading, FaList, FaImage, FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, resetImageState } from '../../../Redux/imageUploadSlice';

const categories = ["Diagnose", "Explore", "Ads", "Shortcuts", "Compare", "BlogPost"];

const BlogDetail = () => {
    const { featureBlogCategory, featureBlogSubCategory, featureBlogTitle } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await fetch(
                    `https://mm.magma3c.com/featureBlogs/SingleBlogsFeature/${featureBlogTitle}`
                );

                if (!response.ok) throw new Error("Failed to fetch blog");

                const data = await response.json();
                setBlog(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [featureBlogCategory, featureBlogSubCategory, featureBlogTitle]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async (updatedBlog) => {
        try {
            const response = await fetch(`https://mm.magma3c.com/featureBlogs/EditblogsFeature/${featureBlogTitle}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedBlog),
            });

            if (!response.ok) throw new Error("Failed to update blog");

            setBlog(updatedBlog);
            setIsEditing(false);
        } catch (error) {
            setError(error.message);
        }
    };

    if (loading) return <div className="text-center p-5">Loading...</div>;
    if (error) return <div className="text-center text-red-500 p-5">Error: {error}</div>;
    if (!blog) return <div className="text-center p-5">Blog not found.</div>;

    if (isEditing) {
        return (
            <WriteBlog
                blog={blog}
                onSave={handleSave}
                onCancel={() => setIsEditing(false)}
            />
        );
    }

    return (
        <div className="blog-detail">
            <div className="container">
                <div className="button-container">
                    <button onClick={handleEdit} className="edit-button">
                        Edit Blog <FaEdit />
                    </button>
                </div>

                <h1>{blog.featureBlogTitle}</h1>
                <p className="text-muted">
                    Category: {blog.featureBlogCategory} → {blog.featureBlogSubCategory}
                </p>

                {(blog.brand) && (
                    <p className="text-muted">
                        Brand: {blog.brand}
                    </p>
                )}

                <div className="row">
                    <div className="col-md-6">
                        <strong>Author:</strong>  {blog.author}
                    </div>
                    <div className="col-md-6 text-center">
                        {/* <strong> Created On:</strong>   {blog.createdOn} */}
                        <strong>Published on: </strong> {new Date(blog.createdOn).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}

                    </div>
                </div>

                {/* Blog Thumbnail Image */}
                {blog.featureBlogImage && (
                    <div className="ImageWrapper">
                        <img
                            src={blog.featureBlogImage}
                            alt={blog.featureBlogTitle}
                            className="ImagePart"
                        />
                    </div>
                )}

                {/* Blog Content */}
                {blog.featureBlogContent.map((content, index) => {
                    if (content.type === "text") {
                        return (
                            <div
                                key={index}
                                className="TextPart"
                                dangerouslySetInnerHTML={{ __html: content.value }}
                            ></div>
                        );
                    } else if (content.type === "image") {
                        return (
                            <div key={index} className="ImageWrapper">
                                <img src={content.value} alt={`Blog Image ${index}`} className="ImagePart" />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

const WriteBlog = ({ blog, onSave, onCancel }) => {
    const [title, setTitle] = useState(blog.featureBlogTitle);
    const [category, setCategory] = useState(blog.featureBlogCategory);
    const [subCategory, setSubCategory] = useState(blog.featureBlogSubCategory);
    const [image, setImage] = useState(blog.featureBlogImage);
    const [about, setAbout] = useState(blog.featureBlogAbout);
    const [author, setAuthor] = useState(blog.Author);
    const [brand, setBrand] = useState(blog.Brand);
    const [content, setContent] = useState(blog.featureBlogContent);
    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const { imageUrl, loading, error } = useSelector((state) => state.imageUpload);
    const [tempImage, setTempImage] = useState(null); // Temporary image for preview
    const [subCategories, setSubCategories] = useState([]);


    useEffect(() => {
        const fetchSubCategories = async () => {
            if (!category) {
                setSubCategories([]);
                return;
            }
            try {
                const response = await fetch(`https://mm.magma3c.com/feature/features/${category}`);
                if (response.ok) {
                    const data = await response.json();
                    setSubCategories(data.data || []); // Ensure we extract the `data` array
                } else {
                    console.error("Failed to fetch subcategories");
                }
            } catch (error) {
                console.error("Error fetching subcategories:", error);
            }
        };
    
        fetchSubCategories();
    }, [category]);


    const handleDeleteBlock = (index) => {
        const updatedContent = content.filter((_, i) => i !== index);
        setContent(updatedContent);
    };

    const handleAddBlock = (index, type) => {
        let newBlock = { type, value: "" };
        if (type === "list") {
            newBlock.value = [];
        }
        setContent([...content.slice(0, index + 1), newBlock, ...content.slice(index + 1)]);
        setDropdownIndex(null);
    };

    const handleChangeContent = (index, value) => {
        const updatedContent = [...content];
        updatedContent[index].value = value;
        setContent(updatedContent);
    };

    const handleListChange = (index, listIndex, value) => {
        const updatedContent = [...content];
        updatedContent[index].value[listIndex] = value;

        // Convert array into a valid HTML string
        const listType = updatedContent[index].listType || "ul";
        updatedContent[index].formattedList = `<${listType}>` +
            updatedContent[index].value.map((item) => `<li>${item}</li>`).join("") +
            `</${listType}>`;

        setContent(updatedContent);
    };

    const addListItem = (index) => {
        const updatedContent = [...content];
        updatedContent[index].value.push("");

        // Convert array into HTML on every addition
        const listType = updatedContent[index].listType || "ul";
        updatedContent[index].formattedList = `<${listType}>` +
            updatedContent[index].value.map((item) => `<li>${item}</li>`).join("") +
            `</${listType}>`;

        setContent(updatedContent);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };


    const confirmSave = () => {
        setIsModalOpen(false);
        const updatedBlog = {
            featureBlogTitle: title,
            featureBlogCategory: category,
            featureBlogSubCategory: subCategory,
            featureBlogImage: image,
            featureBlogAbout: about,
            featureBlogContent: content,
            author: author,
            brand: brand,
        };
        onSave(updatedBlog);
        dispatch(resetImageState()); // Reset the image state after saving
    };

    const handleImageUpload = (file, index = null) => {
        dispatch(uploadImage(file)).unwrap()
            .then((uploadedUrl) => {
                if (index !== null) {
                    // ✅ Update only the specific content block
                    setContent((prevContent) => {
                        return prevContent.map((item, i) =>
                            i === index ? { ...item, value: uploadedUrl } : item
                        );
                    });
                } else {
                    // ✅ Update only the featureBlogImage
                    setImage(uploadedUrl);
                }
            })
            .catch((err) => {
                console.error("Image upload failed:", err);
            });
    };

    useEffect(() => {
        console.log("Updated Content:", content);
    }, [content]);


    // useEffect(() => {
    //     if (imageUrl) {
    //         if (dropdownIndex !== null) {
    //             // Update the specific content block with the uploaded image URL
    //             setContent((prevContent) => {
    //                 const updatedContent = [...prevContent];
    //                 updatedContent[dropdownIndex].value = imageUrl;
    //                 return updatedContent;
    //             });
    //         } else {
    //             // Update the featureBlogImage
    //             setImage(imageUrl);
    //         }
    //     }
    // }, [imageUrl]); // Runs when `imageUrl` updates


    const handleFileChange = (event, index = null) => {
        const file = event.target.files[0];
        if (file) {
            const localUrl = URL.createObjectURL(file); // Create a local preview URL

            if (index !== null) {
                // ✅ Store the temporary preview only for this specific image block
                setContent((prevContent) => {
                    return prevContent.map((item, i) =>
                        i === index ? { ...item, tempValue: localUrl } : item
                    );
                });

                // Upload image to Cloudinary
                handleImageUpload(file, index);
            } else {

                setImage(localUrl);

                // Upload image to Cloudinary
                handleImageUpload(file);
            }
        }
    };


    return (
        <div className="flex gap-6 p-6 bg-gray-100 min-h-screen">
            <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
                <h1
                    contentEditable
                    suppressContentEditableWarning
                    className="text-3xl font-bold mb-4 border-b"
                    onBlur={(e) => setTitle(e.target.innerText)}
                >
                    {title}
                </h1>

                <div className="space-y-4">
                    {content.map((block, index) => (
                        <div key={index} className="p-3 rounded relative group">
                            {/* Delete Button (Cross Button) */}
                            <button
                                type="button"
                                onClick={() => handleDeleteBlock(index)}
                                className="absolute -right-1.5 -top-0.5 bg-red-500 text-white rounded w-6 h-6 flex items-center justify-center pb-1"
                            >
                                &times;
                            </button>

                            {/* Render Content Block */}
                            {block.type === "text" && (
                                <ReactQuill
                                    value={block.value}
                                    onChange={(value) => handleChangeContent(index, value)}
                                    placeholder="Write paragraph here..."
                                    className="w-full p-2 border rounded"
                                />
                            )}
                            {block.type === "image" && (
                                <div>
                                    <input
                                        type="text"
                                        className="w-full p-2 border rounded mb-2"
                                        placeholder="Image URL"
                                        value={block.value}
                                        onChange={(e) => handleChangeContent(index, e.target.value)}
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(e, index)}
                                        className="w-full p-2 border rounded mb-2"
                                    />
                                    {/* Image Preview */}
                                    {(block.tempValue || block.value) && (
                                        <img
                                            src={block.tempValue || block.value}
                                            alt={`Preview ${index}`}
                                            className="mt-2 rounded-lg shadow-sm max-w-[400px] max-h-[300px] w-auto h-auto"
                                        />
                                    )}
                                </div>
                            )}
                            {block.type === "heading" && (
                                <div>
                                    <select
                                        className="w-full p-2 border rounded mb-2"
                                        onChange={(e) => {
                                            const updatedContent = [...content];
                                            updatedContent[index].headingLevel = e.target.value;
                                            setContent(updatedContent);
                                        }}
                                    >
                                        <option value="h1">H1</option>
                                        <option value="h2">H2</option>
                                        <option value="h3">H3</option>
                                        <option value="h4">H4</option>
                                        <option value="h5">H5</option>
                                        <option value="h6">H6</option>
                                    </select>
                                    <input
                                        type="text"
                                        className="w-full p-2 border rounded"
                                        placeholder="Enter heading text..."
                                        value={block.value}
                                        onChange={(e) => handleChangeContent(index, e.target.value)}
                                    />
                                </div>
                            )}
                            {block.type === "list" && (
                                <div>
                                    <select
                                        className="w-full p-2 border rounded mb-2"
                                        onChange={(e) => {
                                            const updatedContent = [...content];
                                            updatedContent[index].listType = e.target.value;
                                            setContent(updatedContent);
                                        }}
                                    >
                                        <option value="ul">Unordered List</option>
                                        <option value="ol">Ordered List</option>
                                    </select>
                                    {block.value.map((item, listIndex) => (
                                        <input
                                            key={listIndex}
                                            type="text"
                                            className="w-full p-2 border rounded mb-2"
                                            placeholder={`List item ${listIndex + 1}`}
                                            value={item}
                                            onChange={(e) => handleListChange(index, listIndex, e.target.value)}
                                        />
                                    ))}
                                    <button
                                        onClick={() => addListItem(index)}
                                        className="mt-2 bg-green-500 text-white px-3 py-1 rounded"
                                    >
                                        + Add List Item
                                    </button>
                                </div>
                            )}

                            {/* Add Block Button */}
                            <button
                                type="button"
                                onClick={() => setDropdownIndex(dropdownIndex === index ? null : index)}
                                className="bg-black text-white opacity-50 hover:opacity-100 transition-opacity px-2 m-2"
                            >
                                +
                            </button>

                            {/* Dropdown for Adding New Blocks */}
                            {dropdownIndex === index && (
                                <div className="absolute bg-white shadow-md p-2 rounded-lg mt-2 grid grid-cols-2 gap-2 w-48">
                                    <button
                                        onClick={() => handleAddBlock(index, "text")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaParagraph className="text-xl" />
                                        <span className="text-sm">Text</span>
                                    </button>
                                    <button
                                        onClick={() => handleAddBlock(index, "heading")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaHeading className="text-xl" />
                                        <span className="text-sm">Heading</span>
                                    </button>
                                    <button
                                        onClick={() => handleAddBlock(index, "image")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaImage className="text-xl" />
                                        <span className="text-sm">Image</span>
                                    </button>
                                    <button
                                        onClick={() => handleAddBlock(index, "list")}
                                        className="flex items-center space-x-2 hover:bg-gray-200 w-full p-2 rounded"
                                    >
                                        <FaList className="text-xl" />
                                        <span className="text-sm">List</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Blog Settings Sidebar */}
            <div className="w-1/3 bg-white p-6 rounded-lg shadow-xl">
                <h2 className="text-xl font-semibold mb-4">Blog Settings</h2>
                <p className="italic text-sm">Category:</p>
                <select
                    value={category}
                    onChange={(e) => {
                        setCategory(e.target.value);
                        setSubCategory(""); // Reset subcategory when category changes
                    }}
                    className="w-full border rounded-md mb-4 h-auto max-h-[40px]"
                    required
                >
                    <option value="" className="">Select Category</option>
                    {categories.map((cat) => (
                        <option key={cat} value={cat}>{cat}</option>
                    ))}
                </select>
                <hr />
                <p className="italic text-sm mt-4">Subcategory:</p>
                <select
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4"
                    required
                    disabled={!category} // Disable if no category is selected
                >
                    <option value="">Select Subcategory</option>
                    {subCategories.map((sub) => (
                        <option key={sub} value={sub.featureName}>{sub.featureName}</option>
                    ))}
                </select>
                <hr />
                <p className="italic text-sm mt-4">Feature Blog Image:</p>
                <input
                    type="text"
                    placeholder="Feature Blog Image URL"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                    className="w-full p-3 border rounded-md mb-1 h-auto max-h-[40px]"
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e)}
                    className="w-full p-1 border rounded-md mb-1 h-auto max-h-[40px]"
                    required
                />
                {(image || blog.featureBlogImage) && (
                    <img
                        src={image || blog.featureBlogImage}
                        alt={"Feature Blog Image"}
                        className="feature-image mt-1 rounded-lg shadow-sm mb-8"
                    />
                )}

                <hr />
                <p className="italic text-sm mt-4">Blog About:</p>
                <textarea
                    placeholder="Short Blog Description"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4 text-sm"
                    required
                />

                <hr />

                <p className="italic text-sm mt-4">Author:</p>
                <input
                    type="text"
                    placeholder="Author Name"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4 h-auto max-h-[40px]"
                    required
                />
                <hr />

                {/* Brand */}
                <p className="italic text-sm mt-4">Brand (optional):</p>
                <input
                    type="text"
                    placeholder="Brand Name (if applicable)"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    className="w-full p-3 border rounded-md mb-4 h-auto max-h-[40px]"
                />
                <hr />

                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-full bg-[#50d71e] text-white rounded-md h-auto max-h-[200px]"
                >
                    Save Changes
                </button>
                <button
                    type="button"
                    onClick={onCancel}
                    className="w-full bg-red-500 text-white rounded-md h-auto max-h-[200px] mt-2"
                >
                    Cancel
                </button>
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={confirmSave}
            />

        </div>
    );
};

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Confirm Changes</h2>
                <p>Are you sure you want to save the changes?</p>
                <div className="modal-buttons">
                    <button onClick={onConfirm} className="confirm-button">Yes, Save</button>
                    <button onClick={onClose} className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
};


export default BlogDetail;