import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchAds } from "../../../Redux/BuyandSell";
import MyAlert from "../../../Compnents/MyAlerts";
import { IoSearchSharp } from "react-icons/io5";

const AdsSearch = () => {
  const dispatch = useDispatch();
  const { searchedAds, status } = useSelector((state) => state.buyAndSell);

  const [city, setCity] = useState("");
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [alert, setAlert] = useState(false);

  const alertHandler = () => {
    setAlert(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!title && !brand && !city) {
      setAlert(true);
    } else {
      dispatch(searchAds(title, city, brand));
    }
  };

  return (
    <div>
      <form onSubmit={submitHandler} className="flex mt-4 space-x-2">
        <div className="text-[15px] bg-white border-2 border-blue-100 rounded-md hover:border-blue-200 duration-300">
          <input
            type="text"
            placeholder="Search By City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="outline-none items-center text-[16px] px-2 border-r"
          />
          <button type="submit" className="pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150">
            <IoSearchSharp size={15} />
          </button>
        </div>
        <div className="text-[15px] bg-white border-2 border-blue-100 rounded-md hover:border-blue-200 duration-300">
          <input
            type="text"
            placeholder="Search By Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="outline-none items-center text-[16px] px-2 border-r"
          />
          <button type="submit" className="pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150">
            <IoSearchSharp size={15} />
          </button>
        </div>
        <div className="text-[15px] bg-white border-2 border-blue-100 rounded-md hover:border-blue-200 duration-300">
          <input
            type="text"
            placeholder="Search By Brand"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            className="outline-none items-center text-[16px] px-2 border-r"
          />
          <button type="submit" className="pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150">
            <IoSearchSharp size={17} />
          </button>
        </div>
      </form>

      {alert && (
        <MyAlert onClose={alertHandler} message={"Please fill at least one field!"} />
      )}

      <div className="mt-3">
        {/* Ads Header */}
        <div className="flex w-full text-white bg-gray-700 font-semibold">
          <span className="w-full text-sm border-2 py-2 text-center">Ads S.No</span>
          <span className="w-full text-sm border-2 py-2 text-center">Ads Title</span>
          <span className="w-full text-sm border-2 py-2 text-center">Ads Brand</span>
          <span className="w-full text-sm border-2 py-2 text-center">Ad Condition</span>
          <span className="w-full text-sm border-2 py-2 text-center">Condition Detail</span>
          <span className="w-full text-sm border-2 py-2 text-center">Price</span>
          <span className="w-full text-sm border-2 py-2 text-center">City</span>
        </div>

        {/* Ads Body */}
        {status === "loading" ? (
          <p>Loading...</p>
        ) : (
          searchedAds.map((item) => (
            <div key={item._id} className="flex w-full text-gray-800">
              <span className="w-full text-sm border-2 py-2 text-center line-clamp-1">{item._id}</span>
              <span className="w-full text-sm border-2 py-2 text-center">{item.title}</span>
              <span className="w-full text-sm border-2 py-2 text-center">{item.brand}</span>
              <span className="w-full text-sm border-2 py-2 text-center">{item.conditionInNum}/10</span>
              <span className="w-full text-sm border-2 py-2 text-center">{item.condition}</span>
              <span className="w-full text-sm border-2 py-2 text-center">{item.price}</span>
              <span className="w-full text-sm border-2 py-2 text-center">{item.city}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AdsSearch;