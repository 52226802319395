import React from "react";
import Logs from "./Logs";

const LogsContainer = () => {
  return (
    <div>
      <Logs />
    </div>
  );
};

export default LogsContainer;
