import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddFeature = () => {
    const navigate = useNavigate();
    const [featureName, setFeatureName] = useState("");
    const [featureCategory, setFeatureCategory] = useState("");
    const [featureInfo, setFeatureInfo] = useState("");
    const [featureQuestions, setFeatureQuestions] = useState([""]);
    const [featureAnswers, setFeatureAnswers] = useState([""]);
    const [loading, setLoading] = useState(false);

    const handleQuestionChange = (index, value) => {
        const newQuestions = [...featureQuestions];
        newQuestions[index] = value;
        setFeatureQuestions(newQuestions);
    };

    const handleAnswerChange = (index, value) => {
        const newAnswers = [...featureAnswers];
        newAnswers[index] = value;
        setFeatureAnswers(newAnswers);
    };

    const addQuestionAndAnswer = () => {
        setFeatureQuestions([...featureQuestions, ""]);
        setFeatureAnswers([...featureAnswers, ""]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const featureData = {
            featureName,
            featureCategory,
            featureInfo,
            featureQuestions: featureQuestions.filter(q => q.trim() !== ""),
            featureAnswers: featureAnswers.filter(a => a.trim() !== ""),
        };

        try {
            const response = await fetch("https://mm.magma3c.com/feature/features", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(featureData),
            });

            if (response.ok) {
                alert("Feature added successfully!");
                navigate("/"); // Redirect to home or another page
            } else {
                alert("Failed to add feature.");
            }
        } catch (error) {
            console.error("Error adding feature:", error);
            alert("An error occurred while adding the feature.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-semibold mb-4">Add a New Feature</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Feature Name</label>
                    <input
                        type="text"
                        value={featureName}
                        onChange={(e) => setFeatureName(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Feature Category</label>
                    <select
                        value={featureCategory}
                        onChange={(e) => setFeatureCategory(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        required
                    >
                        <option value="">Select a category</option>
                        <option value="Diagnose">Diagnose</option>
                        <option value="Explore">Explore</option>
                        <option value="Ads">Ads</option>
                        <option value="Shortcuts">Shortcuts</option>
                        <option value="Compare">Compare</option>
                        <option value="BlogPost">BlogPost</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Feature Info</label>
                    <textarea
                        value={featureInfo}
                        onChange={(e) => setFeatureInfo(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        rows="4"
                        required
                    />
                </div>

                {featureQuestions.map((question, index) => (
                    <div key={index}>
                        <label className="block text-sm font-medium text-gray-700">Question {index + 1}</label>
                        <input
                            type="text"
                            value={question}
                            onChange={(e) => handleQuestionChange(index, e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        />
                        <label className="block text-sm font-medium text-gray-700 mt-2">Answer {index + 1}</label>
                        <input
                            type="text"
                            value={featureAnswers[index]}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                ))}

                <button
                    type="button"
                    onClick={addQuestionAndAnswer}
                    className="mt-2 p-2 bg-blue-500 text-white rounded-md"
                >
                    Add Another Question & Answer
                </button>

                <button
                    type="submit"
                    disabled={loading}
                    className="mt-4 p-2 bg-green-500 text-white rounded-md"
                >
                    {loading ? "Adding..." : "Add Feature"}
                </button>
            </form>
        </div>
    );
};

export default AddFeature;