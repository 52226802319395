import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";

const categories = ["All", "Diagnose", "Explore", "Ads", "Shortcuts", "Compare"];

const AllBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [subcategories, setSubcategories] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState("All");

    useEffect(() => {
        const fetchAllBlogs = async () => {
            try {
                const response = await fetch("https://mm.magma3c.com/featureBlogs/blogsFeature");
                const data = await response.json();
                setBlogs(data);
                setFilteredBlogs(data); // Initially set to all blogs
            } catch (error) {
                console.error("Error fetching blogs:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchAllBlogs();
    }, []);

    // Handle category selection
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory("All");

        if (category === "All") {
            setFilteredBlogs(blogs);
            setSubcategories([]);
        } else {
            const filtered = blogs.filter((blog) => blog.featureBlogCategory === category);
            setFilteredBlogs(filtered);

            // Extract unique subcategories from filtered blogs
            const uniqueSubcategories = [...new Set(filtered.map((blog) => blog.featureBlogSubCategory))];
            setSubcategories(uniqueSubcategories);
        }
    };

    // Handle subcategory selection
    const handleSubcategoryChange = (subcategory) => {
        setSelectedSubcategory(subcategory);
        if (subcategory === "All") {
            setFilteredBlogs(blogs.filter((blog) => blog.featureBlogCategory === selectedCategory));
        } else {
            setFilteredBlogs(
                blogs.filter(
                    (blog) => blog.featureBlogCategory === selectedCategory && blog.featureBlogSubCategory === subcategory
                )
            );
        }
    };

    return (
        <div className="px-3 mt-4 rounded-md space-y-3 py-3 border border-gray-700 bg-white shadow-md shadow-gray-500">
            <h1 className="text-xl font-semibold text-gray-600 mb-4">All Feature Blogs</h1>

            {/* Category Filter */}
            <div className="flex space-x-3 mb-4">
                {categories.map((category) => (
                    <button
                        key={category}
                        className={`px-4 py-2 rounded-md text-sm font-medium transition ${
                            selectedCategory === category
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleCategoryChange(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            {/* Subcategory Filter (Only Show If a Category is Selected) */}
            {selectedCategory !== "All" && subcategories.length > 0 && (
                <div className="mb-4">
                    <label className="block text-gray-600 font-medium mb-1">Filter by Subcategory:</label>
                    <select
                        className="w-full border border-gray-300 px-4 py-2 rounded-md"
                        value={selectedSubcategory}
                        onChange={(e) => handleSubcategoryChange(e.target.value)}
                    >
                        <option value="All">All</option>
                        {subcategories.map((sub) => (
                            <option key={sub} value={sub}>
                                {sub}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {loading ? (
                <div className="h-full py-11 flex justify-center">
                    <Loader type="bubble-scale" bgColor={"#AEB9B8"} size={50} />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredBlogs.length > 0 ? (
                        filteredBlogs.map((blog) => (
                            <Link to={`/feature-blogs/${blog.featureBlogCategory}/${blog.featureBlogSubCategory}/${blog.featureBlogTitle}`} key={blog._id}>
                                <div className="hover:shadow-md hover:shadow-gray-600 duration-300 rounded-lg h-48 relative">
                                    <img
                                        src={blog.featureBlogImage || "/placeholder.jpg"}
                                        alt={blog.featureBlogTitle}
                                        className="relative rounded-lg w-full h-full object-cover shadow-md"
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-40 flex items-end p-2 text-white">
                                        <h1 className="line-clamp-2 text-sm font-medium">{blog.featureBlogTitle}</h1>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p className="text-gray-500 text-center col-span-full">No blogs available in this category.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default AllBlogs;
