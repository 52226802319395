import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getUserLogs,
  mobilesPerBrand,
  getAdsLogs,
  adsPerBrand,
} from "../../../Redux/logsReducer";

//Chart modules
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Registering necessary chart elements with Chart.js for both bar and line charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const MainDashboard = () => {
  const dispatch = useDispatch();

  const { userLogs, brandsMobilesCount, adsLogs, adsPerBrandRecord } =
    useSelector((state) => state.logs);

  // Calculate the count of mobile clicks per brand

  const countBrands = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    userLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(mobilesPerBrand(updatedCounts));
  };

  // Bar Chart

  useEffect(() => {
    if (userLogs.length > 0) countBrands();
  }, [userLogs]);

  useEffect(() => {
    dispatch(getUserLogs());
  }, [dispatch]);

  // Calculate the count of mobile clicks per brand
  const countBrandsForAds = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    adsLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(adsPerBrand(updatedCounts));
  };

  // Define an array of colors for each bar
  const barColors = [
    "#FF6F61", // Orange
    "#7B61FF", // Purple
    "#58D3F7", // Cyan
    "#FFD700", // Yellow
    "#FF4DA6", // Pink
  ];

  // Preparing the data for the Bar chart
  const chartData = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Clicks per Brand",
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(
          0,
          Object.keys(brandsMobilesCount).length
        ), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options for better presentation
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        // text: "Mobile Clicks per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
          },
        },
      },
    },
    scales: {
      x: {
        display: false, // This will hide the x-axis labels (brand names)
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  // Pie Chart

  useEffect(() => {
    if (adsLogs.length > 0) countBrandsForAds();
  }, [adsLogs]);

  useEffect(() => {
    dispatch(getAdsLogs());
  }, [dispatch]);

  // Prepare the data for the Pie Chart

  const pieChartData = {
    labels: Object.keys(adsPerBrandRecord), // Brand names
    datasets: [
      {
        data: Object.values(adsPerBrandRecord), // Click counts
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#F7464A",
          "#8E44AD",
          "#2ECC71",
          "#E74C3C",
          "#3498DB",
          "#9B59B6",
          "#F39C12",
          "#D35400",
          "#1ABC9C",
          "#34495E",
          "#2C3E50",
          "#16A085",
          "#D35400",
          "#F1C40F",
          "#7F8C8D",
          "#BDC3C7",
        ],
      },
    ],
  };

  // Options for disabling the legend
  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // This hides the legend
      },
    },
  };

  // Line Graph

  const [counts, setCounts] = useState([]);
  const [dates, setDates] = useState([]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://mm.magma3c.com/logs/lineGraph"); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCounts(data.counts);
        setDates(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForNews = {
    labels: dates, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Clicks On News", // Label for the dataset
        data: counts, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  const optionsForNews = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: false, // Hides the x-axis labels (dates)
      },
      y: {
        title: {
          display: true,
          text: "Clicks",
        },
      },
    },
  };

  // Line Chart #2

  const [countsForReviews, setCountsForReviews] = useState([]);
  const [datesForReviews, setDatesForReviews] = useState([]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://mm.magma3c.com/logs/lineGraphForReviews"
        ); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCountsForReviews(data.counts);
        setDatesForReviews(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForReviews = {
    labels: datesForReviews, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Clicks On Reviews", // Label for the dataset
        data: countsForReviews, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  const optionsForReviews = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: false, // Hides the x-axis labels (dates)
      },
      y: {
        title: {
          display: true,
          text: "Clicks",
        },
      },
    },
  };

  return (
    <div className="container-fluid pb-3 ">
      <div className="container grid grid-cols-2 gap-3 md:w-[1000px] m-auto ">
        <Link
          to={"/allmobiles"}
          className=" bg-white rounded-md shadow-md shadow-gray-500 border py-2 px-3"
        >
          <Bar data={chartData} options={chartOptions} />
          <div className=" text-sm font-semibold hover:text-gray-600  bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Clicks On Mobiles</span> <span>{userLogs.length}</span>
          </div>
        </Link>
        <Link
          to={"/buyandsellcontainer"}
          className=" flex flex-col justify-center items-center bg-white rounded-md shadow-md shadow-gray-500 py-2 px-3"
        >
          <div className="w-[38vw] h-[38vh]  flex justify-center items-center">
            {/* <Pie data={pieChartData} /> */}
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>

          <div className=" text-sm font-semibold  hover:text-gray-600  bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Clicks On Ads</span> <span>{adsLogs.length}</span>
          </div>
        </Link>

        <Link
          to={"/mainNewsContainer"}
          className=" bg-white rounded-md shadow-md shadow-gray-500 py-2 px-3"
        >
          <Line data={dataForNews} options={optionsForNews} />
          <div className=" text-sm font-semibold  hover:text-gray-600  bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Click On News </span>{" "}
            <span>
              {counts.reduce((sum, currentValue) => sum + currentValue, 0)}
            </span>
          </div>
        </Link>

        <Link
          to={"/mainNewsContainer"}
          className=" bg-white rounded-md shadow-md shadow-gray-500 py-2 px-3"
        >
          <Line data={dataForReviews} options={optionsForReviews} />
          <div className=" text-sm  hover:text-gray-600  font-semibold bg-zinc-100 border border-zinc-200 w-full py-2 rounded-md px-7 flex justify-between">
            <span>Total Clicks On Reviews</span>
            <span>
              {countsForReviews.reduce(
                (sum, currentValue) => sum + currentValue,
                0
              )}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MainDashboard;
