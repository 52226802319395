import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../../../Redux/BuyandSell";
import { IoSearchSharp } from "react-icons/io5";

//PAGINATION
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

//  =======================  styles ==========================
const apisdatastyle =
  " text-sm text-center w-[300px] border-r border-gray-500   pr-2 pl-2 py-1";

const addresstyles =
  " text-sm text-center w-[300px] border-r border-gray-500  pr-2 pl-2 py-1 overflow-y-auto ";

const headerNameStyle = "text-sm   text-center w-[300px] px-2";
//  =======================  styles ==========================

const Clients = () => {
  const dispatch = useDispatch();

  const { clients, status } = useSelector((state) => state.buyAndSell);

  useEffect(() => {
    if (clients.length === 0) {
      dispatch(getClients());
    }
  }, [clients, dispatch]);

  // Array of client fields
  const clientFields = [
    "Client image url",
    "Client S.No",
    "Client Name",
    "Client Email",
    "Client Following",
    "Client Favourites",
    "Client contact Number",
    "Client type",
    "Client registration time",
    "Client total ads",
    "Client total points",
    "Client package plan",
    "Client weekly ads",
    "Client address",
    "Client city",
    "Client area",
    "Client lat",
    "Client long",
  ];

  // PAGINATION LOGIC

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const onChange = (page) => {
    //on clicking next page set it to local storage

    setCurrentPage(page);
  };

  //UPDATE ALL MOBILES PAGINATION

  var currentItems = clients.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className=" overflow-x-auto h-auto">
      {/* ================clients header==================  */}
      <form className="flex mt-3 mb-3 space-x-2">
        <div className=" text-[15px] flex  bg-white h-8  duration-300 border-2  border-blue-100 rounded-md  hover:border-blue-200">
          <input
            type="text"
            placeholder="search by s.no"
            className="outline-none  items-center  text-[16px] px-2 border-r  w-[150px]"
          />
          <button
            type="submit"
            className=" pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150 "
          >
            <IoSearchSharp size={17} />
          </button>
        </div>
        <div className=" text-[15px] flex  bg-white h-8  duration-300 border-2  border-blue-100 rounded-md  hover:border-blue-200">
          <input
            type="text"
            placeholder="search by email"
            className="outline-none  items-center  text-[16px] px-2 border-r  w-[150px]"
          />
          <button
            type="submit"
            className=" pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150 "
          >
            <IoSearchSharp className="active:scale-90 " size={15} />
          </button>
        </div>
        <div className=" text-[15px] flex  bg-white h-8  duration-300 border-2  border-blue-100 rounded-md  hover:border-blue-200">
          <input
            type="text"
            placeholder="search by contact number"
            className="outline-none  items-center  text-[16px] px-2 border-r  w-[150px]"
          />
          <button
            type="submit"
            className=" pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150 "
          >
            <IoSearchSharp className="active:scale-90 " size={15} />
          </button>
        </div>

        <div className=" text-[15px] flex  bg-white h-8  duration-300 border-2  border-blue-100 rounded-md  hover:border-blue-200">
          <input
            type="text"
            placeholder="search by ad number"
            className="outline-none  items-center  text-[16px] px-2 border-r  w-[150px]"
          />
          <button
            type="submit"
            className=" pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150 "
          >
            <IoSearchSharp className="active:scale-90 " size={15} />
          </button>
        </div>

        <div className=" text-[15px] flex  bg-white h-8  duration-300 border-2  border-blue-100 rounded-md  hover:border-blue-200">
          <input
            type="text"
            placeholder="search by ad model"
            className="outline-none  items-center  text-[16px] px-2 border-r  w-[150px]"
          />
          <button
            type="submit"
            className=" pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150 "
          >
            <IoSearchSharp className="active:scale-90 " size={15} />
          </button>
        </div>

        <div className=" text-[15px] flex  bg-white h-8  duration-300 border-2  border-blue-100 rounded-md  hover:border-blue-200">
          <input
            type="text"
            placeholder="search by city"
            className="outline-none  items-center  text-[16px] px-2 border-r  w-[150px]"
          />
          <button
            type="submit"
            className=" pl-2 pt-[6px] pb-[9px] px-2 active:scale-90 text-blue-500 duration-150 "
          >
            <IoSearchSharp className="active:scale-90 " size={15} />
          </button>
        </div>
      </form>

      <div
        className="flex justify-between text-white bg-gray-700  h-10 font-semibold  items-center"
        style={{ minWidth: "3000px" }}
      >
        {/* <span className="text-sm text-center w-[90px]  ">image url</span> */}
        <span className="text-sm    text-center w-[300px] px-2">
          Client S.No
        </span>
        <span className="text-sm   text-center   w-[300px] px-2">
          Client Name
        </span>
        <span className="text-sm text-center  w-[300px] px-2">
          Client Email
        </span>
        <span className="text-sm text-center  w-[300px] px-2">
          Client favourites
        </span>
        <span className="text-sm text-center  w-[300px] px-2">
          Client following
        </span>

        <span className={headerNameStyle}> Client contact Number</span>
        <span className={headerNameStyle}> Client type</span>
        <span className={headerNameStyle}> Client reg time</span>
        <span className={headerNameStyle}> total ads</span>
        <span className={headerNameStyle}> total points</span>
        <span className={headerNameStyle}> weekly ads count</span>

        {/* <span className={headerNameStyle}> "Client total ads</span> */}

        <span className={headerNameStyle}> Client address</span>

        <span className={headerNameStyle}>Client city</span>

        <span className={headerNameStyle}>Client area</span>

        <span className="text-sm  text-center  w-[300px]  px-2">
          Client lat
        </span>

        <span className="text-sm   text-center  w-[300px]  px-2">
          Client long
        </span>
      </div>

      {/* =================clients body==================  */}

      {status === "loading"
        ? "Loading"
        : currentItems.map((item, index) => (
            <div
              key={item._id}
              className={`flex w-full text-gray-800   border-t border-b border-gray-500 ${
                index % 2 === 0 ? "bg-gray-200" : "bg-white"
              }`}
              style={{ minWidth: "3000px" }}
            >
              {/* <span className="text-sm text-center w-[70px] h-[60px] border-r border-gray-500">
                {item.imageUrl ? (
                  <img
                    src={item.imageUrl}
                    className="  object-contain h-[60px]"
                  />
                ) : (
                  <span className="text-gray-500">No Image Found</span>
                )}
              </span> */}
              <span
                className={` min-h-[50px] break-all ${apisdatastyle} line-clamp-1`}
              >
                {item._id}
              </span>
              <span className="  border-black text-sm break-all   py-2 text-center w-[300px]">
                {item.name}
              </span>
              <span className="  border-black text-sm break-all   py-2 text-center w-[300px]">
                {item.email}
              </span>
              <span className="  border-black text-sm break-all   py-2 text-center w-[300px]">
                {item.following.length}
              </span>
              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.favourites.length}
              </span>
              <span className="  border-black text-sm   break-all py-2 text-center w-[300px]">
                {item.number}
              </span>
              <span className="  border-black text-sm break-all   py-2 text-center w-[300px]">
                {item.type}
              </span>
              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.registerationTime}
              </span>

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.totalAds}
              </span>

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.totalPoints}
              </span>

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.totalPoints}
              </span>

              {/* <span className="text-sm border-2 py-2 text-center w-[300px]">
                No Data
              </span> */}

              {/* <span className="text-sm border-2 py-2 text-center w-[300px]">
                {item.weeklyAdsCount}
              </span> */}

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.fullAddress}
              </span>

              <span className="  border-black text-sm   break-all py-2 text-center w-[300px]">
                {item.city}
              </span>

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.area}
              </span>

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.lat}
              </span>

              <span className="  border-black text-sm  break-all  py-2 text-center w-[300px]">
                {item.long}
              </span>
            </div>
          ))}

      <div className=" mt-4 mb-3 mx-[25rem]">
        <Pagination
          current={currentPage}
          total={clients.length}
          pageSize={itemsPerPage}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Clients;
