import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientsAds } from "../../../Redux/BuyandSell";
//PAGINATION
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const Ads = () => {
  const dispatch = useDispatch();

  const { clientsAds, status } = useSelector((state) => state.buyAndSell);

  useEffect(() => {
    if (clientsAds.length === 0) {
      dispatch(getClientsAds());
    }
  }, [clientsAds, dispatch]);

  // PAGINATION LOGIC

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const onChange = (page) => {
    //on clicking next page set it to local storage

    setCurrentPage(page);
  };

  //UPDATE ALL MOBILES PAGINATION

  var currentItems = clientsAds.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="mt-4 overflow-x-auto h-[28rem]">
      {/* =================ads header==================  */}
      <div
        className="flex text-white bg-gray-700  h-10 font-semibold justify-between  items-center"
        style={{ minWidth: "3500px" }}
      >
        <span className="text-sm text-center  w-[100px]">image url</span>
        <span className=" text-sm text-center w-[250px]"> Ads S.No </span>
        <span className=" text-sm text-center w-[250px]"> Ads Title</span>

        <span className=" text-sm text-center w-[250px]">Ad Brand</span>

        <span className=" text-sm text-center w-[250px]">
          Ad condition in no
        </span>
        <span className=" text-sm text-center w-[250px]"> ad Condition</span>
        <span className=" text-sm text-center w-[250px]"> ad price</span>
        <span className=" text-sm text-center w-[250px]"> ad status</span>
        <span className=" text-sm text-center w-[250px]"> ad description</span>
        <span className=" text-sm text-center w-[250px]"> ad address</span>
        <span className=" text-sm text-center w-[250px]"> ad city</span>
        <span className=" text-sm text-center w-[250px]"> ad area</span>
        <span className=" text-sm text-center w-[250px]"> ad lat</span>
        <span className=" text-sm text-center w-[250px]"> ad long</span>

        <span className=" text-sm text-center w-[250px]"> ad publish date</span>
        <span className=" text-sm text-center w-[250px]"> ad like</span>
        <span className=" text-sm text-center w-[250px]"> ad views</span>
      </div>

      {/* =================ads body==================  */}

      {status === "loading"
        ? "Loading !!"
        : currentItems.map((item, index) => (
            <div
              key={item._id}
              className={`flex text-gray-800  justify-between  border-[1px] border-gray-500 ${
                index % 2 === 0 ? "bg-gray-200" : "bg-white"
              }`}
              style={{ minWidth: "3500px" }}
            >
              <span className="w-[100px] text-center border-r border-gray-500">
                {item.images && item.images[0] ? (
                  <img
                    src={item.images[0]}
                    alt="Ad Image"
                    className="h-[90px] w-[100px] object-contain"
                  />
                ) : (
                  <span className="text-sm text-gray-500">No Image Found</span>
                )}
              </span>

              <span className=" text-sm text-center  border-r border-gray-500 pr-2 pl-2 w-[250px]  py-1 line-clamp-1 px-2 ">
                {item._id}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.title}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.brand}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                <option value="">{item.conditionInNum}/10</option>
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.condition}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.price}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.status}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.description}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.fullAddress}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.city}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.area}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.lat}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.long}
              </span>

              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.publishedOn}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.likes.length}
              </span>
              <span className=" text-sm text-center  border-r border-gray-500   pr-2 pl-2 w-[250px] py-1">
                {" "}
                {item.views.length}
              </span>
            </div>
          ))}
           <div className=" px-72 mt-4">
            <Pagination
              current={currentPage}
              total={clientsAds}
              pageSize={itemsPerPage}
              onChange={onChange}
            />
          </div>
    </div>
  );
};

export default Ads;
