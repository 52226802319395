import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase-config";
import { FaLinkedin } from "react-icons/fa6";
import { SiInstagram } from "react-icons/si";
import { TbWorld } from "react-icons/tb";
import { ImFacebook2 } from "react-icons/im";
import { login } from "../../../Redux/UserReducer";
import { useDispatch } from "react-redux";

import useAuth from "../../../CustomHooks/useAuth";

const LogIn = ({ handleLogInPageVisibilty, handleAuthentication }) => {
  const handleAuth = useAuth();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  async function handleLogin(e) {
    e.preventDefault();

    try {
      //SIGNIN USER

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      console.log(userCredential);

      //GET USER

      const user = userCredential.user;

      // Obtain the ID token

      const token = await user.getIdToken();

      localStorage.setItem("user", JSON.stringify({ user: user }));

      localStorage.setItem("token", JSON.stringify({ token: token }));

      dispatch(login({ uid: user.uid }));

      // handleAuth({ uid: user.uid });

      handleLogInPageVisibilty();

      handleAuthentication();
    } catch (error) {
      console.error("Error signing in:", error.message);
      alert(error.message);
    }
  }

  return (
    <div className="w-[100%] flex mx-auto rounded-sm  ">
      <div className="w-full  mx-auto  bg-white md:my-0  my-7 ">
        <h1 className="font-bold 2xl:text-6xl md:text-2xl text-xl 2xl:py-10 p-7 2xl:px-5 text-blue-950 2xl:mx-28 ">
          MobileMate
        </h1>

        <h1 className="text-center 2xl:text-6xl md:text-4xl tracking-wider text-gray-800 font-bold 2xl:mt-10 md:mt-5 ">
          Login to Your Account
        </h1>
        <form
          action=""
          className="mt-8 2xl:w-[800px] md:w-[370px] w-[320px]  mx-auto 2xl:space-y-9 2xl:mt-24  space-y-5 border-b border-t border-gray-300  py-4 "
        >
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="2xl:h-28 h-10 bg-gray-100  px-9 2xl:text-[2rem] text-sm 2xl:rounded-full rounded-2xl w-full outline-none placeholder:text-gray-400 font-semibold 2xl:mt-10"
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            className=" 2xl:h-28 h-10 bg-gray-100 px-9   2xl:text-[2rem] text-sm 2xl:rounded-full rounded-2xl w-full outline-none placeholder:text-gray-400 font-semibold"
          />
          {/* <button
              onClick={handleLogin}
              className="h-10 bg-gray-600 text-white hover:bg-gray-800 duration-300 px-3 text-sm rounded-2xl w-full font-semibold"
            >
              login
            </button> */}
          <button
            onClick={handleLogin}
            className="2xl:h-28 h-10 bg-gray-600 text-white hover:bg-gray-800 duration-300 px-3 2xl:text-[2.4rem] text-sm 2xl:rounded-full rounded-2xl w-full font-semibold "
          >
            Login
          </button>
        </form>

        <div className="py-4 mt-20">
          <p className="text-center 2xl:text-4xl text-sm text-gray-600 font-semibold  mb-4">
            social networks
          </p>

          <ul className=" flex 2xl:hidden text-gray-600 space-x-2 mx-auto w-[150px] justify-between ">
            <li>
              <FaLinkedin size={30} />
            </li>
            <li>
              <SiInstagram size={27} />
            </li>
            <li>
              <TbWorld size={30} />
            </li>
            <li>
              <ImFacebook2 size={27} />
            </li>
          </ul>
          {/* above 2xl */}
          <ul className=" 2xl:flex hidden text-gray-600 space-x-2 mt-7 mx-auto w-[300px] justify-between ">
            <li>
              <FaLinkedin size={50} />
            </li>
            <li>
              <SiInstagram size={50} />
            </li>
            <li>
              <TbWorld size={50} />
            </li>
            <li>
              <ImFacebook2 size={50} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
