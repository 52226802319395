import React, { useState, useEffect } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getGlossary, updateGlossary } from "../../../Redux/GlossaryReducer";
import ReactQuill from "react-quill";
import ImageUpload from "../../ImageUpload1";

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  },
};

// Custom CSS for toolbar container
<style jsx>{`
  .ql-toolbar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`}</style>;

const GlossaryEditForm = ({
  handleEditWindow,
  term,
  description,
  relatedTerms,
  id,
}) => {
  const [termsHook, setTerm] = useState(term);

  const [descriptionHook, setDescription] = useState(description);

  const [relatedTermsHook, setRelatedTerms] = useState(relatedTerms);

  const [allRelatedTermsHook, setAllRelatedTerms] = useState([]);

  const [selectedTerm, setSelectedTerms] = useState([]);

  const dispatch = useDispatch();

  const { glossaryTerms } = useSelector((state) => {
    return state.glossary;
  });

  const { imageUrl1 } = useSelector((state) => state.imageUplaod);

  function removeRelatedTerm(term) {
    const removeOneTerm = relatedTermsHook.filter((item) => item !== term);

    setRelatedTerms(removeOneTerm);
  }

  useEffect(() => {
    if (glossaryTerms.length === 0) {
      dispatch(getGlossary());
    }
  }, [dispatch, glossaryTerms]);

  useEffect(() => {
    if (glossaryTerms.length !== 0) {
      setAllRelatedTerms(glossaryTerms);
    }
  }, [glossaryTerms]);

  function handleDescription(newValue) {
    setDescription(newValue);
  }

  function handleRelatedTerm(event) {
    const valueArray = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedTerms([...selectedTerm, ...valueArray]);
  }

  function submitHandler(e) {
    e.preventDefault();

    const joined = relatedTermsHook.concat(selectedTerm);

    const data = {
      term: termsHook.toUpperCase(),
      description: descriptionHook,
      relatedTerms: joined,
      imgUrl: imageUrl1,
    };

    dispatch(updateGlossary(data, id));

    handleEditWindow();

  }

  return (
    <div className="bg-black/90 absolute inset-0 z-10 w-screen overflow-y-auto ">
      <button className="text-white mx-3">
        <IoArrowBack onClick={() => handleEditWindow()} />
      </button>

      <div className="bg-white w-[1110px]  py-2  pb-4 mx-auto rounded-sm px-5   mb-3">
        <h1 className="font-semibold text-xl text-center mb-2 my-2">
          Edit new term
        </h1>
        <form className=" text-sm space-y-6" onSubmit={submitHandler}>
      <div className="sec1 w-[570px] float-left mr-8 ">
          <div>
            <label htmlFor="" className="mb-2 font-semibold block ">
              Glossary Term*
            </label>
            <input
              required
              value={termsHook}
              onChange={(e) => setTerm(e.target.value)}
              type="text"
              placeholder="Please enter term"
              className="w-[570px] px-3 h-10  text-gray-700 outline-none border-[2px]  border-slate-300 rounded-sm bg-white"
            />
          </div>

          <div>
            <label htmlFor="" className="mb-2 font-semibold">
              Description
            </label>
            <div className="w-[570px] h-[17.3rem]  border-[2px] border-slate-300">
              <ReactQuill
                placeholder="description"
                theme="snow"
                value={descriptionHook}
                onChange={handleDescription}
                modules={modules}
                required
                className="text-sm h-[13rem] "
              />
            </div>
          </div>
          </div>
        

<div className="sec2 ml-[40px]">

          <div>
            <label htmlFor="" className=" block  mb-2 font-semibold ">
              Delete Related Terms
            </label>
            <div className=" w-[400px] h-[80px] border-[2px]   border-slate-300  overflow-y-auto">
              {relatedTermsHook.length > 0 &&
                relatedTermsHook.map((item, index) => (
                  <div
                    key={index}
                    className="bg-gray-200 py-1 font-normal px-1 text-[11px] inline-block rounded-md mr-2 mb-2"
                  >
                    {item}
                    <button
                      className=" ml-2 text-red-500 text-[16px]"
                      onClick={() => removeRelatedTerm(item)}
                      type="button"
                    >
                      x
                    </button>
                  </div>
                ))}
            </div>
          </div>

          <div>
            <label htmlFor="" className="block mb-2 font-semibold">
              Add New Related Terms
            </label>
            <select
              className="w-[400px] text-sm h-10 outline-none border-[2px] border-slate-300 rounded-sm bg-white"
              onChange={handleRelatedTerm}
              value={selectedTerm}
            >
              <option className="text-center text-[11px]  py-2 bg-gray-50   duration-300">
                options
              </option>
              {allRelatedTermsHook.map((item) => (
                <option>{item.term}</option>
              ))}
            </select>
          </div>
          </div>

          <ImageUpload />

          <button
            type="submit"
            className="w-full h-10 text-sm border-gray-400  rounded-sm bg-gray-800 text-white hover:bg-gray-900 duration-300 mb-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default GlossaryEditForm;
