// import React, { useState } from "react";
// import profile from "./images/profile.jpeg"

// const Carddummy = (props) => {
// console.log(props.img);


//   return (
//     <div className="text-sm fixed top-[250px] right-[30px] bg-gray-100 rounded z-10 w-[350px] h-[400px] border border-gray-400 flex flex-col items-center space-y-4 p-4">




//     {props.img === ""? (
//          <h1>NO IMG</h1>
      
//     ) : (
     
//          <img
//         className="w-[80px] h-[80px] rounded-full"
//         src={props.img}
//         alt=""
//       />
      
//     )}


//     <p className="font-bold">{props.name}</p>
//     <p className="text-gray-600">Email: {props.email}</p>
//     <p className="text-gray-600">Client favourites: {props.fav}</p>
//   </div>
//   );
// };

// export default Carddummy;


import React from "react";
import profile from "./images/image.png"; // Placeholder profile image

const Carddummy = (props) => {
  return (
    <div className="fixed inset-0 z-50 bg-black/70" onClick={()=>props.toggle()}>
    <div className="overflow-y-auto text-sm fixed top-[10px] right-[30px] bg-gray-100 rounded z-10 w-[350px] h-[100vh] border border-gray-400 flex flex-col space-y-4 p-4">


      
      {props.cardOpen && ( <button onClick={props.cardClose} className="p-2 m-1 mb-2 font-bold text-white bg-gray-700 rounded border-1 hover:text-red-500"  >Close</button>)
      }
      
    
      {props.img === " " ? (
        <img
          className="m-auto w-[80px] h-[80px] rounded-full"
          src={profile}
        />
      ) : (
        <img
          className="m-auto w-[80px] h-[80px] rounded-full"
          src={props.img} // Use actual image
          alt={`${props.name}'s Profile`}
        />
      )}

      <p className="m-auto font-bold">{props.name}</p>
      <p className="text-gray-600"> <b>Email:</b> {props.email}</p>
      <p className="text-gray-600"> <b>Client favourites:</b> {props.fav}</p>
      <p className="text-gray-600"> <b>Client following:</b> {props.followings}</p>
      <p className="text-gray-600"> <b>Client contact Number:</b> {props.num}</p>
      <p className="text-gray-600"> <b>Client type:</b> {props.type}</p>
      <p className="text-gray-600"> <b>Registration Time:</b> {props.time}</p>
      <p className="text-gray-600"> <b>Total Ads:</b> {props.Ads}</p>
      <p className="text-gray-600"> <b>Total Points:</b> {props.points}</p>
      <p className="text-gray-600"> <b>Weekly ads count:</b> {props.points}</p>
      <p className="text-gray-600"> <b>Full address:</b> {props.fullAddress}</p>
      <p className="text-gray-600"> <b>City:</b> {props.city}</p>
      <p className="text-gray-600"> <b>Area:</b> {props.area}</p>
      <p className="text-gray-600"> <b>Client lat:</b> {props.lat}</p>
      <p className="text-gray-600"> <b>Client long:</b> {props.long}</p>
    </div>
    </div>
  );
};

export default Carddummy;

