// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from '@reduxjs/toolkit';

const BrandReducer = createSlice({

  //STATE NAME  
  name: 'brands',

  //STATE INITIAL VALUE
  initialState: {
    brands: [],
    status: 'idle',
    error: null
  },

  //REDUCER FUNCTIONS
  reducers: {
    brandRequest(state) {
      state.status = 'loading';
    },
    brandSuccess(state, action) {
      state.status = 'succeeded';
      state.brands = action.payload;
    },

    brandFailure(state, action) {
      state.status = 'failed';
      state.error = action.payload;
    }
  }
});

//EXPORT REDUCER ACTION FUNCTIONS

export const { brandRequest, brandSuccess, brandFailure } = BrandReducer.actions;

//EXPORT REDUCER

export default BrandReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getBrands = () => async (dispatch) => {

  dispatch(brandRequest());
  
  try {

    const response = await fetch(`https://mm.magma3c.com/brands/allBrands`);

    const responseData = await response.json();

    dispatch(brandSuccess(responseData));

  } catch (error) {
    dispatch(brandFailure(error.message));
  }
};





