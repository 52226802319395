import React, { useEffect, useState } from "react";
import { IoCloudUploadSharp } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSingleBrand,
  deleteSingleBrand,
} from "../../../Redux/SingleBrandReducer";
import { getBrands } from "../../../Redux/BrandsReducer";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { getAllMobiles } from "../../../Redux/AllMobiles";
import ReactQuill from "react-quill";
import { IoArrowBack } from "react-icons/io5";
import ImageUpload1 from "../../ImageUpload1";
import ImageUpload2 from "../../ImageUpload2";

const TotalMobileBrands = ({ handleAddMobileFormVisible }) => {

  const { brands } = useSelector((state) => state.brands);

  const { status, brand, isAdded, isUpdated } = useSelector(
    (state) => state.singleMobileBrand
  );

  const { token, currentUser } = useSelector((state) => state.user);

  const { imageUrl1, imageUrl2, isLoading } = useSelector(
    (state) => state.imageUplaod
  );

  const { mobiles } = useSelector(
    (state) => state.mobiles
  );

  const [isEditWindowVisible, setEditWindowVisible] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [ambassador, setAmbassador] = useState("");
  const [rating, setRating] = useState(0);
  const [contact, setContact] = useState("");
  const [website, setWebsite] = useState("");
  const [id, setId] = useState("");

  const handleEditWindow = (id) => {
    if (id !== false) {
      setId(id);
      const singleBrand = brands.filter((item) => item._id == id);

      setName(singleBrand[0].brand);
      setDescription(singleBrand[0].description);
      setRating(singleBrand[0].rating);
      setEditWindowVisible(!isEditWindowVisible);
    }

    setEditWindowVisible(!isEditWindowVisible);
  };

  const dispatch = useDispatch();

  const [mobileBrands, setMobileBrands] = useState([]);


  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands());

      setMobileBrands(brands);
    }
  }, [dispatch]);

  useEffect(() => {
    if (brands.length > 0) {
      setMobileBrands(brands);
    }
  }, [brands]);

  useEffect(() => {
    if (isAdded) {
      window.location.reload("/");
    }
  }, [isAdded]);

  useEffect(() => {
    if (isUpdated) window.location.reload("/");
  }, [isUpdated]);

  useEffect(() => {
    if (!mobiles.length) dispatch(getAllMobiles());
  }, [mobiles, dispatch]);

  if (!Array.isArray(mobileBrands) || mobileBrands.length === 0) {
    return <div>No mobile brands available</div>;
  }

  function submitHandler() {
    const data = {
      brand:name,
      description,
      rating,
      imageUrl: imageUrl1,
      bannerUrl:imageUrl2
    };

    dispatch(updateSingleBrand(id, data,currentUser));
    setAmbassador("");
    setContact("");
    setDescription("");
    setName("");
    setWebsite("");
    setRating("");
    setEditWindowVisible(false);
  }

  function getTotalMobiles(brand)
  {
    const totalMobilesOfSpecificBrand = mobiles.filter(mobile => mobile.brand === brand );

    return totalMobilesOfSpecificBrand.length;
  }

  const handleChange = (html) => {
    setDescription(html);
  };

  function deleteBrand(id) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this brand?"
    );
    if (confirmed) {
      dispatch(deleteSingleBrand(id,currentUser));
    }
  }

  return (
    <div className=" w-full px-3 pb-9">
      <div className="flex justify-between">
        <h1 className=" text-2xl font-bold text-gray-700 py-3">
          Brands We Deal With
        </h1>
        <button
          onClick={handleAddMobileFormVisible}
          className="  px-2 pt-1 rounded-lg bg-white border w-[50px] h-[40px] mt-2 "
        >
          <IoCloudUploadSharp
            size={30}
            className="hover:text-gray-500 text-gray-700 duration-200"
          />
        </button>
      </div>

      <div className="flex justify-between space-x-10  font-semibold px-3 bg-gray-600 py-2 text-white">
        <p className="w-[150px] ">Brand</p>
        <p className="w-[150px] ">Total Mobiles</p>
        <p className="w-[200px] ">Brand Embassador </p>
        <p className="w-[150px] ">Contact</p>
        <p className="w-[200px] ">Website</p>

        <p className="w-[50px] text-center">
          <FaRegEdit size={17} />
        </p>
        <p className="w-[50px]">
          <MdDeleteOutline size={20} />
        </p>
      </div>
      <div className="bg-white  shadow-md shadow-gray-700">
        {brands &&
          mobileBrands.map((mobileBrand, index) => (
            <div
              key={mobileBrand._id}
              className="flex justify-between items-center space-x-10 py-1 font-semibold px-3 border-b  text-gray-800 "
            >
              <p className="w-[150px] ">{mobileBrand.brand}</p>
              <p className="w-[150px] text-center ">{getTotalMobiles(mobileBrand.brand)}</p>
              <p className="w-[200px] ">Brand Embassador </p>
              <p className="w-[150px] ">Contact</p>
              <p className="w-[200px] ">Website</p>
              <button
                onClick={() => handleEditWindow(mobileBrand._id)}
                className="w-[50px] text-center"
              >
                <FaRegEdit size={17} />
              </button>
              <button
                className="w-[50px]"
                onClick={() => deleteBrand(mobileBrand._id)}
              >
                <MdDeleteOutline size={20} />
              </button>
            </div>
          ))}
      </div>
      {isEditWindowVisible && (
        <div className="absolute inset-0  bg-black/90 h-[950px]  z-10 flex items-center justify-center">
          <div className="bg-white rounded-sm w-[700px] px-6 py-3">
            <button
              onClick={() => handleEditWindow(false)}
              className="text-gray-700 text-sm"
            >
              <IoArrowBack />
            </button>
            <h1 className="mb-2 text-2xl font-semibold text-gray-700 text-center">
              Change Brand Details
            </h1>
            {status === "loading" ? (
              "Loading"
            ) : (
              <form
                className="space-y-6  text-gray-900 "
                onSubmit={submitHandler}
              >
                <div>
                  <label htmlFor="" className="font-semibold mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    name="brand"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className="w-full h-12  focus-within:shadow-md placeholder:text-sm capitalize text px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
                    placeholder="Brand"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="" className="font-semibold mb-2">
                    Ratings
                  </label>
                  <input
                    type="number"
                    name="ratings"
                    onChange={(e) => setRating(e.target.value)}
                    value={rating}
                    className="w-full h-12  focus-within:shadow-md placeholder:text-sm capitalize text px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
                    placeholder="Quantity"
                    required
                  />
                </div>
 

                <div>
                  <label htmlFor="" className="font-semibold mb-2">
                    Description
                  </label>
                  <div className="h-[280px]">
                    <ReactQuill
                      required
                      theme="snow"
                      className="h-[235px]"
                      value={description}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="flex mt-4">
            <div>
              <label htmlFor="" className="font-semibold mb-2 text-gray-700">
                Choose Logo
              </label>
              <ImageUpload1 className=" text-sm text-gray-700 outline-none  rounded-sm " />
            </div>
            <div>
              <label htmlFor="" className="font-semibold mb-2 text-gray-700">
                Choose Banner
              </label>
              <ImageUpload2 className=" text-sm text-gray-700 outline-none  rounded-sm " />
            </div>
          </div>

                <button
                  type="submit"
                  className="bg-gray-700 w-full mb-4 text-white py-2 px-4 rounded hover:bg-gray-900 duration-300"
                >
                  Save Changes
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalMobileBrands;
