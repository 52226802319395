// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const BuyAndSellReducer = createSlice({
  //STATE NAME
  name: "buyAndSell",

  //STATE INITIAL VALUE
  initialState: {
    message: "",
    clientsAds: [],
    searchedAds: [],
    totalAds: [],
    clients: [],
    user: {},
    postedby:{name:"", email:"",followers:[],favourites:[]},
    status: "idle",
    error: null,
  },

  //REDUCER FUNCTIONS

  reducers: {
    clientsRequest(state) {
      state.status = "loading";
    },

    clientsSuccess(state, action) {
      state.status = "succeeded";
      state.clients = action.payload.clients;
    },

    clientsAdsRequest(state) {
      state.status = "loading";
    },

    clientsAdsSuccess(state, action) {
      state.status = "succeeded";
      state.clientsAds = action.payload.clientsAds;
    },

    searchAdsRequest(state) {
      state.status = "loading";
    },

    searchAdsSuccess(state, action) {
      state.status = "succeeded";
      state.searchedAds = action.payload.searchedAds;
    },

    buySellFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    searchClientByEmailRequest(state, action) {
      state.status = "loading";
    },
    searchClientByEmailFailure(state, action) {
      state.error = { error: "404 NO USER FOUD !!!" };
      state.status = "failure";
    },
    searchClientByEmailSuccess(state, action) {
      state.user = action.payload.user;
      state.status = "succeed";
    },
    totalAdsRequest(state, action) {
      state.status = "loading";
    },
    totalAdsFailure(state, action) {
      state.error = { error: "404 NO USER FOUD !!!" };
      state.status = "failure";
    },
    totalAdsSuccess(state, action) {
      state.totalAds = action.payload.totalAds;
      state.status = "succeed";
    },
    delAdsRequest(state, action) {
      state.status = "loading";
    },
    delAdsFailure(state, action) {
      state.error = { error: "SERVER ERROR !!!" };
      state.status = "failure";
    },
    delAdsSuccess(state, action) {
      state.message = action.payload.message;
      state.status = "succeed";
    },
    getClientByIdRequest(state, action) {
      state.status = "loading";
    },
    getClientByIdFailure(state, action) {
      state.error = { error: "SERVER ERROR !!!" };
      state.status = "failure";
    },
    getClientByIdSuccess(state, action) {
      state.postedby = action.payload.postedby;
      state.status = "succeed";
    },
    delClientRequest(state, action) {
      state.status = "loading";
    },
    delClientFailure(state, action) {
      state.error = { error: "SERVER ERROR !!!" };
      state.status = "failure";
    },
    changeStatusSuccess(state, action) {
      state.message = action.payload.message;
      state.status = "succeed";
    },
    changeStatusRequest(state, action) {
      state.status = "loading";
    },
    changeStatusFailure(state, action) {
      state.error = { error: "SERVER ERROR !!!" };
      state.status = "failure";
    },
    delClientSuccess(state, action) {
      state.message = action.payload.message;
      state.status = "succeed";
    },
    searchClientByEmailResetError(state) {
      state.error = null;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  clientsRequest,
  clientsSuccess,
  clientsAdsRequest,
  clientsAdsSuccess,
  searchAdsRequest,
  searchAdsSuccess,
  buySellFailure,
  searchClientByEmailRequest,
  searchClientByEmailSuccess,
  searchClientByEmailFailure,
  totalAdsRequest,
  totalAdsSuccess,
  totalAdsFailure,
  delAdsRequest,
  delAdsSuccess,
  delAdsFailure,
  getClientByIdRequest,
  getClientByIdSuccess,
  getClientByIdFailure,
  delClientSuccess,
  delClientFailure,
  delClientRequest,
  changeStatusRequest,
  searchClientByEmailResetError,
  changeStatusFailure,
  changeStatusSuccess,
} = BuyAndSellReducer.actions;

//EXPORT REDUCER

export default BuyAndSellReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getClients = () => async (dispatch) => {
  dispatch(clientsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/users/fetchAllUsers`);

    const responseData = await response.json();

    dispatch(clientsSuccess({ clients: responseData }));
  } catch (error) {
    dispatch(buySellFailure(error.message));
  }
};

export const getClientsAds = () => async (dispatch) => {
  dispatch(clientsAdsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/ads/fetchAllAds`);

    const responseData = await response.json();

    console.log(responseData);

    dispatch(clientsAdsSuccess({ clientsAds: responseData }));
  } catch (error) {
    dispatch(buySellFailure(error.message));
  }
};

export const searchAds = (title, city, brand) => async (dispatch) => {
  dispatch(searchAdsRequest());

  title = title.toUpperCase();

  brand = brand.toUpperCase();

  city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();

  let URL = "";

  try {
    if (title.length === 0 && brand.length === 0 && city.length !== 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?city=${city}`;
    } else if (title.length === 0 && brand.length !== 0 && city.length === 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}`;
    } else if (title.length !== 0 && brand.length === 0 && city.length === 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?title=${title}`;
    } else if (title.length !== 0 && brand.length !== 0 && city.length === 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}&title=${title}`;
    } else if (title.length === 0 && brand.length !== 0 && city.length !== 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}&city=${city}`;
    } else if (title.length !== 0 && brand.length === 0 && city.length !== 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?title=${title}&city=${city}`;
    } else {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}&title=${title}&city=${city}`;
    }

    const response = await fetch(URL);

    const responseData = await response.json();

    dispatch(searchAdsSuccess({ searchedAds: responseData }));
  } catch (error) {
    dispatch(buySellFailure(error.message));
  }
};

export const searchClientByEmail = (email) => async (dispatch) => {
  dispatch(searchClientByEmailRequest());

  const URL = "https://mm.magma3c.com/users/fetchUserDetailsByEmail";

  const data = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ email: email }),
    headers: { "Content-Type": "application/json" },
  });
  const response = await data.json();

  if (!data.ok) {
    dispatch(searchClientByEmailFailure());
  } else {
    dispatch(searchClientByEmailSuccess({ user: response }));
  }
};

export const getTotalAds = (uid) => async (dispatch) => {
  dispatch(totalAdsRequest());

  const URL = "https://mm.magma3c.com/ads/myAds";

  const data = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ uid: uid }),
    headers: { "Content-Type": "application/json" },
  });
  const response = await data.json();

  if (!data.ok) {
    dispatch(totalAdsFailure());
  } else {
    dispatch(totalAdsSuccess({ totalAds: response }));
  }
};
export const delAds = (id) => async (dispatch) => {
  dispatch(delAdsRequest());

  const URL = `https://mm.magma3c.com/ads/deleteAd/${id}`;

  const data = await fetch(URL, {
    method: "DELETE",
  });
  const response = await data.json();

  if (!data.ok) {
    dispatch(delAdsFailure());
  } else {
    dispatch(delAdsSuccess({ message: response.message }));
  }
};
export const delClient = (uid) => async (dispatch) => {
  dispatch(delClientRequest());

  const URL = `https://mm.magma3c.com/users/deleteUser`;

  const data = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ uid }),
    headers: { "Content-Type": "application/json" },
  });
  const response = await data.json();

  console.log(response);

  if (!data.ok) {
    dispatch(delClientFailure());
  } else {
    dispatch(delClientSuccess({ message: response.message }));
  }
};
export const changeStatus = (uid, status) => async (dispatch) => {
  dispatch(changeStatusRequest());

  const URL = `https://mm.magma3c.com/ads/changeAdStatus/${uid}`;

  const data = await fetch(URL, {
    method: "PUT",
    body: JSON.stringify({ status }),
    headers: { "Content-Type": "application/json" },
  });
  const response = await data.json();

  console.log(response);

  if (!data.ok) {
    dispatch(changeStatusFailure());
  } else {
    dispatch(changeStatusSuccess({ message: response.message }));
  }
};

export const getClientById = (uid, status) => async (dispatch) => {
  
  dispatch(getClientByIdRequest());

  const URL = `https://mm.magma3c.com/users/fetchUserDetails`;

  const data = await fetch(URL, {
    method: "POST",
    body: JSON.stringify({ uid }),
    headers: { "Content-Type": "application/json" },
  });
  const response = await data.json();

  if (!data.ok) {
    dispatch(getClientByIdFailure());
  } else {
    dispatch(getClientByIdSuccess({ postedby: response }));
  }
};

