import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postGlossary, getGlossary } from "../../../Redux/GlossaryReducer";
import { IoArrowBack } from "react-icons/io5";
import ImageUpload from "../../ImageUpload1";

//CONFIGURATIONS FOR REACT QUILL

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  },
};

// Custom CSS for toolbar container
<style jsx>{`
  .ql-toolbar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`}</style>;

const GlossaryForm = ({ handleFormVisible }) => {
  const dispatch = useDispatch();

  const [term, setTerm] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [relatedTerms, setRelatedTerms] = useState([]);
  const [selectedTerm, setSelectedTerms] = useState([]);

  const { glossaryTerms } = useSelector((state) => {
    return state.glossary;
  });

  const {imageUrl1} = useSelector(state => state.imageUplaod);

  useEffect(() => {
    if (glossaryTerms.length === 0) {
      dispatch(getGlossary());
    }
  }, [glossaryTerms]);

  useEffect(() => {
    if (glossaryTerms.length !== 0) {
      setRelatedTerms(glossaryTerms);
    }
  }, [glossaryTerms]);

  const { token } = useSelector((state) => state.user);

  function handleTerm(e) {
    let newValue = e.target.value;
    let upperCase = newValue.toUpperCase();
    setTerm(upperCase);
  }

  function handleDefination(newValue) {
    setDescription(newValue);
  }


  function handleRelatedTerms(event) {
    const valueArray = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedTerms([...selectedTerm, ...valueArray]);
  }

  function handleRemoveTerm(index) {
    setSelectedTerms((prev) => prev.filter((_, i) => i !== index));
  }

  const submitHandler = (e) => {
    e.preventDefault();

    const data= {
      term: term.toUpperCase(),
      description,
      relatedTerms: selectedTerm,
      imgUrl:imageUrl1
    }

    dispatch(postGlossary(data));

    setTerm("");
    setDescription("");
    setImg("");
    setSelectedTerms([]);
    handleFormVisible();
  };

  return (
    <div className="bg-black/90 absolute inset-0 z-10 w-screen overflow-y-auto ">
      <button onClick={handleFormVisible} className="text-white mx-3">
        <IoArrowBack />
      </button>
      <div className="bg-white md:w-[600px]  py-2  pb-4 mx-auto rounded-sm px-5   mb-3">
        <h1 className="font-semibold text-xl text-center mb-2">Add New Term</h1>
        <form onSubmit={submitHandler} className="text-sm space-y-6">
          <div>
            <label htmlFor="" className="mb-2 font-semibold">
              Glossary Term*
            </label>
            <input
              value={term}
              required
              onChange={handleTerm}
              type="text"
              placeholder="Please enter term"
              className="w-full px-3 h-10  text-gray-700 outline-none border-[2px]  border-slate-300 rounded-sm bg-white"
            />
          </div>

          <div>
            <label htmlFor="" className="mb-2 font-semibold">
              Description
            </label>
            <div className="h-[17.3rem]  border-[2px] border-slate-300">
              <ReactQuill
                placeholder="description"
                theme="snow"
                value={description}
                onChange={handleDefination}
                modules={modules}
                required
                className="text-sm h-[13rem] "
              />
            </div>
          </div>

          <div>
            <label htmlFor="" className="mb-2 font-semibold">
              Select Related Terms
            </label>
            <select
              value={selectedTerm}
              onChange={handleRelatedTerms}
              className="text-sm w-full h-10 outline-none border-[2px] border-slate-300 rounded-sm bg-white"
            >
              <option>Related Terms</option>
              {glossaryTerms.map((item) => (
                <option
                  key={item.term}
                  value={item.term}
                  className="text-center text-[11px]  py-2 bg-gray-50   duration-300"
                >
                  {item.term}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="" className="mb-2 font-semibold ">
              Selected Related Terms
            </label>
            <div className="h-[80px] border-[2px]   border-slate-300  overflow-y-auto">
              {selectedTerm.map((term, index) => (
                <div
                  key={index}
                  className="bg-gray-200 py-1 font-normal px-1 text-[11px] inline-block rounded-md mr-2 mb-2"
                >
                  {term}
                  <button
                    onClick={() => handleRemoveTerm(index)}
                    className="ml-2 text-red-500 text-[16px]"
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* <input
            onChange={handleImg}
            type="file"
            className="w-full text-gray-700 rounded-sm bg-white"
          /> */}

          <ImageUpload />

          <button
            type="submit"
            className="w-full h-10 text-sm border-gray-400  rounded-sm bg-gray-800 text-white hover:bg-gray-900 duration-300 mb-2"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default GlossaryForm;
