// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from '@reduxjs/toolkit';

const SingleMobileDetailsReducer = createSlice({

  //STATE NAME  
  name: 'singleMobileDetails',

  //STATE INITIAL VALUE
  initialState: {
    mobileDetails: null,
    status: 'idle',
    error: null
  },

  //REDUCER FUNCTIONS
  reducers: {
    singleMobileRequest(state) {
      state.status = 'loading';
    },
    singleMobileSuccess(state, action) {
      state.status = 'succeeded';
      state.mobileDetails = action.payload.phone;
    },
    singleMobileUpdateSuccess(state, action) {
      state.status = 'succeeded';
      state.message = action.payload.message;
    },
    singleMobileAddSuccess(state, action) {
      state.status = 'succeeded';
      state.message = action.payload.message;
    },
    singleMobileFailure(state, action) {
      state.status = 'failed';
      state.error = action.payload;
    }
  }
});

//EXPORT REDUCER ACTION FUNCTIONS

export const { singleMobileRequest, singleMobileSuccess, singleMobileUpdateSuccess,singleMobileAddSuccess, singleMobileFailure } = SingleMobileDetailsReducer.actions;

//EXPORT REDUCER

export default SingleMobileDetailsReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getMobileDetailsByModel = (id) => async (dispatch) => {

  dispatch(singleMobileRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/mobiles/singleMobile/${id}`);

    const responseData = await response.json();

    dispatch(singleMobileSuccess({ phone: responseData.mobile }));

  } catch (error) {
    dispatch(singleMobileFailure(error.message));
  }
};

export const updateMobileDetailsByModel = (id, data, currentUser) => async (dispatch) => {

  dispatch(singleMobileRequest());

  try {

    const response = await fetch(`https://mm.magma3c.com/mobiles/updateMobile/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" ,
        "authorization":JSON.stringify(currentUser)
      }

    });

    const responseData = await response.json();

    console.log(responseData)

    dispatch(singleMobileUpdateSuccess({ message: responseData.message }));

  } catch (error) {
    dispatch(singleMobileFailure(error.message));
  }
};

export const uploadImage = (model, data) => async (dispatch) => {

  dispatch(singleMobileRequest());

  try {

    const response = await fetch(`https://6283.mobilemate.io/admin/updateimage/${model}`, {
      method: "PATCH",
      body: data,
      // headers:{"Content-Type" : "application/json"}
    });

    const responseData = await response.json();

    console.log("single mobile details !!!");

    console.log(responseData)

    dispatch(singleMobileUpdateSuccess({ message: responseData.message }));

  } catch (error) {
    dispatch(singleMobileFailure(error.message));
  }
};


export const postMobile = (data,currentUser) => async (dispatch) => {

  dispatch(singleMobileRequest());
  

  try {

    const response = await fetch(`https://mm.magma3c.com/mobiles/createMobile`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json',authorization: JSON.stringify(currentUser), },
    });

    const responseData = await response.json();

    console.log("single mobile details !!!");

    console.log(responseData)

    dispatch(singleMobileAddSuccess({ message: responseData.message }));

  } catch (error) {
    dispatch(singleMobileFailure(error.message));
  }
};


