import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {logout} from "../../../Redux/UserReducer";

const Logout = ({ handleLogoutPageVisibilty }) => {


  const dispatch = useDispatch();

  const {currentUser} = useSelector((state)=> state.user)

  
  return (
    <div className=" absolute inset-0 z-50 h-[100%] bg-black/90 overflow-x-hidden text-white py-32">
      <div className="bg-white text-gray-900 md:w-[300px] w-[250px] md:h-[350px] h-[300px] mx-auto rounded-lg border-b">
        <h1 className="md:text-xl text-[0.98rem] font-bold text-center md:w-[250px] mx-8 py-11  border-b border-gray-300">
          Are you sure you want to logout?
        </h1>

        <button className="md:text-[1rem]  py-3 border-b border-gray-300 block md:w-[250px] w-[185px] text-red-500 hover:shadow-md duration-300 font-semibold tracking-wider mx-8" onClick={()=>dispatch(logout(currentUser.uid))}>
          Logout
        </button>

        <button
          onClick={handleLogoutPageVisibilty}
          className="md:text-[1rem]  py-3 border-b border-gray-300 block md:w-[250px] w-[185px] text-red-500 hover:shadow-md duration-300 font-semibold tracking-wider mx-8"        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Logout;
