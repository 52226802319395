
import React, { useEffect, useState } from "react";
import PendingNews from "./PendingNews";
import { useDispatch, useSelector } from "react-redux";
import { setNews, setLoading } from "../news/NewsSlice";
import RejectedNews from "./RejectedNews";
import AddNewsForm from "./AddNewsForm";
import PublishNews from "./PublishNews";

const MainNewsContainer = () => {
  

  const [isVisibleNewsForm, setVisibleNewsForm] = useState(false);

  const prevNews = useSelector((state) => state.news);

  const {isUpdated} = useSelector((state)=>state.news)

  const dispatch = useDispatch();

  const API = "https://mm.magma3c.com/allNews";

  const [formData, setFormData] = useState({
    brand: "",
    category:"",
    name: "",
    title: "",
    description: "",
    image1: "",
    image2: "",
  });

  const fetchDatafromApi = async (url) => {
    try {
      const res = await fetch(url);
      const data = await res.json();
      dispatch(setNews(data));
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Data not found", error);
    }
  };

  useEffect(() => {
    fetchDatafromApi(API);
  }, [dispatch]);

  useEffect(()=>{
    if(isUpdated)
    {
      window.location.reload("/");
    }
  },[isUpdated])

  const handleSubmitNews = (e) => {
    e.preventDefault();

    setVisibleNewsForm(!isVisibleNewsForm);
    const newNews = {
      ...formData,
      image1: formData.image1,
      image2: formData.image2,
    };

    console.log(newNews);

    dispatch(setNews([newNews, ...prevNews.News]));

    setFormData({
      brand: "",
      name: "",
      title: "",
      description: "",
      image1: "",
      image2: "",
    });
  };

  const handleNewsFormVisible = () => {
    setVisibleNewsForm(!isVisibleNewsForm);
  };

  return (
    <div className="container-fluid gx-0">
   
      <button
      title="Add news"
        onClick={handleNewsFormVisible}
      >

        <span className="md:flex hidden">Add news</span>
      </button>
      {isVisibleNewsForm && (
        <div className="absolute inset-0 z-10 py-5 w-full h-[850px] bg-black/90">
          <AddNewsForm
            handleNewsFormVisible={handleNewsFormVisible}
            handleSubmitNews={handleSubmitNews}
            formData={formData} 
            setFormData={setFormData}
          />
        </div>
      )}

      <div className=" max-w-[1000px] pb-2">
        <PendingNews />
        <PublishNews />
        <RejectedNews />
      </div>
    </div>
  );
};

export default MainNewsContainer;

