import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getGlossaryList,deleteGlossary } from "../../../Redux/GlossaryReducer";

import GlossaryEditForm from "./GlossaryEditForm";

const Glossary = ({ handleFormVisible }) => {
  const [isEditWindowVisible, setEditWindowVisible] = useState(false);

  const [term, setTerm] = useState("");

  const [description, setDescription] = useState("");

  const [relatedTerms, setRelatedTerms] = useState([]);

  const [id, setId] = useState("");

  const [activeIndex, setActiveIndex] = useState(null);

  const dispatch = useDispatch();

  const { glossaryList, status } = useSelector((state) => state.glossary);

  const handleEditWindow = (id) => {

    setEditWindowVisible(!isEditWindowVisible);

    setId(id);

    const singleGlossary = glossaryList.find((item) => item._id === id);

    if (singleGlossary) {
      setTerm(singleGlossary.term);
      setDescription(singleGlossary.description);
      setRelatedTerms(singleGlossary.relatedTerms);
    }
  };

  useEffect(() => {
    dispatch(getGlossaryList());
  }, [dispatch]);

  const handleDescription = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sortedData = [...glossaryList].sort((a, b) =>
    a.term.toLowerCase().localeCompare(b.term.toLowerCase())
  );

  return (
    <div className=" pb-5 px-2 ">
      <div className="flex justify-between items-center text-gray-900 mb-4 pt-3">
        <h1 className="text-2xl sm:text-3xl text-gray-700 font-semibold">
          Glossary
        </h1>
        <button
          className="w-8 h-8 rounded-full bg-gray-800 hover:bg-gray-700 flex items-center justify-center text-white duration-300"
          onClick={handleFormVisible}
          title="add new term"
        >
          <IoMdAdd size={20} />
        </button>
      </div>
      {status === "loading" ? (
        <p>Loading...</p>
      ) : (
        sortedData.map((item, index) => (
          <div key={index}>
            <div
              onClick={() => handleDescription(index)}
              className="flex flex-col md:flex-row md:items-center border-t py-2 border-gray-300 cursor-pointer duration-200"
            >
              <span className="font-semibold px-2 w-full md:w-1/3">
                {item.term}
              </span>
              <span className="px-2 w-full md:w-2/3">{item.definition}</span>
              {activeIndex === index ? (
                <FaAngleDown className="ml-auto md:ml-0" size={20} />
              ) : (
                <FaAngleUp className="ml-auto md:ml-0" size={20} />
              )}
            </div>
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-linear rounded-sm shadow-md shadow-gray-800 ${
                activeIndex === index ? "max-h-screen" : "max-h-0"
              }`}
            >
              <div className="flex flex-col md:flex-row justify-between space-x-0 md:space-x-1">
                <div
                  className="p-4 bg-gray-200 w-full md:w-2/3"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
                <div className="bg-gray-200 w-full md:w-1/3 p-2">
                  <div className="flex justify-end space-x-2 mb-2">
                    <button
                      onClick={() => handleEditWindow(item._id)}
                      className="hover:text-gray-950 duration-300"
                    >
                      <FiEdit size={15} />
                    </button>
                    <button
                      className="hover:text-gray-950 duration-300"
                      onClick={() => dispatch(deleteGlossary(item.term))}
                    >
                      <MdDeleteOutline size={20} />
                    </button>
                  </div>
                  <div
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: item.relatedTerms }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}

      {isEditWindowVisible && (
        <GlossaryEditForm
          handleEditWindow={handleEditWindow}
          term={term}
          description={description}
          relatedTerms={relatedTerms}
          id={id}
        />
      )}
    </div>
  );
};

export default Glossary;
