import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import AddMobileForm from "../Mobiles/AddMobileForm";
import { getAllMobiles, filterAllMobiles, deleteSingleMobile} from "../../../Redux/AllMobiles";
import { getBrands } from "../../../Redux/BrandsReducer";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

const AllMobiles = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, currentUser } = useSelector((state) => state.user);

  const [mobileBrands, setMobileBrands] = useState([]);
  const [model, setModels] = useState("");
  const [brand, setBrand] = useState("");
  const [date, setDate] = useState("");
  const [addMobile, setAddMobileForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { brands } = useSelector((state) => state.brands);
  const { mobiles, filteredPhones, status, isUpdated } = useSelector(
    (state) => state.mobiles
  );

  const itemsPerPage = 10;

  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    setMobileBrands(brands);
  }, [brands, dispatch]);

  useEffect(() => {
    if (mobiles && !mobiles.length) dispatch(getAllMobiles());
  }, [mobiles, dispatch]);

  useEffect(() => {
    if (isUpdated) {
      dispatch(getAllMobiles()); 
    }
  }, [isUpdated, dispatch]);

  useEffect(() => {
    const savedPage = localStorage.getItem("currentPage");
    if (savedPage) {
      setCurrentPage(Number(savedPage));
    }
  }, []);

  const onChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page);
  };

  const toggleAddMobile = () => setAddMobileForm((prev) => !prev);

  const deleteMobile = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this mobile?");
    if (confirmed) dispatch(deleteSingleMobile(id,currentUser));
    alert("deleted !!");
    navigate("/allmobiles");
  };

  const handleFilter = () => {
    let filteredMobiles = [...mobiles];
    
    if (brand) {
      filteredMobiles = filteredMobiles.filter((item) => item.brand === brand);
    }

    if (model === "asc" || model === "desc") {
      filteredMobiles.sort((a, b) =>
        model === "asc"
          ? a.model.toLowerCase().localeCompare(b.model.toLowerCase())
          : b.model.toLowerCase().localeCompare(a.model.toLowerCase())
      );
    }

    if (date === "latest" || date === "oldest") {
      filteredMobiles.sort((a, b) =>
        date === "latest"
          ? new Date(b.createdOn) - new Date(a.createdOn)
          : new Date(a.createdOn) - new Date(b.createdOn)
      );
    }

    dispatch(filterAllMobiles(filteredMobiles));
  };

  useEffect(() => {
    handleFilter();
  }, [brand, model, date, mobiles]);

  


    const displayItems = filteredPhones.length ? filteredPhones : mobiles;
     const currentItems = displayItems.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );





  return (
    <Fragment>
      {addMobile ? (
        <AddMobileForm isVisible={toggleAddMobile} />
      ) : (
        <div className="ml-5 pb-9">
          <div className="text-end space-x-2">
            <button
              className="w-8 h-8 bg-gray-700 text-white rounded-full text-xl text-center pb-1 m-1"
              onClick={() => setAddMobileForm(true)}
            >
              +
            </button>
          </div>

          <div className="flex justify-between bg-gray-800 text-white px-3 font-semibold">
            <select
              value={brand}
              className="bg-gray-800 border w-[180px] outline-none hover:bg-gray-700 duration-300 py-2 rounded-md my-1 px-3"
              onChange={(e) => setBrand(e.target.value)}
            >
              <option value="">Select Brand</option>
              {mobileBrands.map((item) => (
                <option key={item.brand} value={item.brand}>
                  {item.brand}
                </option>
              ))}
            </select>

            <select
              className="w-[180px] px-2 border my-1 bg-transparent outline-none"
              value={model}
              onChange={(e) => setModels(e.target.value)}
            >
              <option value="" disabled>
                Model
              </option>
              <option value="asc">Model Ascending</option>
              <option value="desc">Model Descending</option>
            </select>

            <select
              className="w-[250px] px-2 border my-1 bg-transparent outline-none"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            >
              <option value="" disabled>
                Release Date
              </option>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>

          {status === "loading" ? (
            <div className="flex justify-center py-4 text-black">Loading...</div>
          ) : (
            currentItems.map((item) => (
              <div
                key={item._id}
                className="flex justify-between px-3 py-2 text-gray-800 text-sm border-b border-gray-500"
              >
                <p className="w-[130px] px-3">{item.brand}</p>
                <p className="w-[180px] px-3">{item.model}</p>
                <p className="w-[250px] px-2">{item.createdOn.split('T')[0]}</p>
                <button
                  className="w-[50px]"
                  onClick={() => {
                    
                    navigate(`/MobileDetails/${item._id}`)}}
                >
                  <FaRegEdit size={17} />
                </button>
                <button className="w-[50px]" onClick={() => deleteMobile(item._id)}>
                  <MdDeleteOutline size={20} />
                </button>
              </div>
            ))
          )}

          <div className="px-72 mt-4">
            <Pagination
              current={currentPage}
              total={displayItems.length}
              pageSize={itemsPerPage}
              onChange={onChange}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AllMobiles;
