// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const BuyAndSellReducer = createSlice({
  //STATE NAME
  name: "buyAndSell",

  //STATE INITIAL VALUE
  initialState: {
    message: "",
    clientsAds: [],
    searchedAds: [],
    clients: [],
    status: "idle",
    error: null,
  },

  //REDUCER FUNCTIONS

  reducers: {
    clientsRequest(state) {
      state.status = "loading";
    },

    clientsSuccess(state, action) {
      state.status = "succeeded";
      state.clients = action.payload.clients;
    },

    clientsAdsRequest(state) {
      state.status = "loading";
    },

    clientsAdsSuccess(state, action) {
      state.status = "succeeded";
      state.clientsAds = action.payload.clientsAds;
    },

    searchAdsRequest(state) {
      state.status = "loading";
    },

    searchAdsSuccess(state, action) {
      state.status = "succeeded";
      state.searchedAds = action.payload.searchedAds;
    },

    buySellFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  clientsRequest,
  clientsSuccess,
  clientsAdsRequest,
  clientsAdsSuccess,
  searchAdsRequest,
  searchAdsSuccess,
  buySellFailure,
} = BuyAndSellReducer.actions;

//EXPORT REDUCER

export default BuyAndSellReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getClients = () => async (dispatch) => {
  dispatch(clientsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/users/fetchAllUsers`);

    const responseData = await response.json();

    dispatch(clientsSuccess({ clients: responseData }));
  } catch (error) {
    dispatch(buySellFailure(error.message));
  }
};

export const getClientsAds = () => async (dispatch) => {
  dispatch(clientsAdsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/ads/fetchAds`);

    const responseData = await response.json();

    dispatch(clientsAdsSuccess({ clientsAds: responseData }));
  } catch (error) {
    dispatch(buySellFailure(error.message));
  }
};

export const searchAds = (title, city, brand) => async (dispatch) => {
  dispatch(searchAdsRequest());

  title = title.toUpperCase();

  brand = brand.toUpperCase();

  city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();

  let URL = "";

  try {
    if (title.length === 0 && brand.length === 0 && city.length !== 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?city=${city}`;
    } else if (title.length === 0 && brand.length !== 0 && city.length === 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}`;
    } else if (title.length !== 0 && brand.length === 0 && city.length === 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?title=${title}`;
    } else if (title.length !== 0 && brand.length !== 0 && city.length === 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}&title=${title}`;
    } else if (title.length === 0 && brand.length !== 0 && city.length !== 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}&city=${city}`;
    } else if (title.length !== 0 && brand.length === 0 && city.length !== 0) {
      URL = `https://mm.magma3c.com/ads/searchAds?title=${title}&city=${city}`;
    } else {
      URL = `https://mm.magma3c.com/ads/searchAds?brand=${brand}&title=${title}&city=${city}`;
    }

    const response = await fetch(URL);

    const responseData = await response.json();

    dispatch(searchAdsSuccess({ searchedAds: responseData }));
  } catch (error) {
    dispatch(buySellFailure(error.message));
  }
};
