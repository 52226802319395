import { useState, useEffect, useRef, useCallback } from "react";
import * as React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Userprovider from "./Compnents/MainDashboard/Users/UserProvider";
import Profile from "./Compnents/MainDashboard/Profile/Profile";
import UserActivity from "./Compnents/MainDashboard/Users/UserActivity";
import Services from "./Compnents/MainDashboard/Services/Services";
import About from "./Compnents/MainDashboard/About/About";
import FAQ from "./Compnents/MainDashboard/FAQ's/FAQ";
import MobileBrandDetails from "./Compnents/MainDashboard/MobileDetails/MobileBrandDetails";
import Reviews from "./Compnents/MainDashboard/reviews/Reviews";
import Users from "./Compnents/MainDashboard/Users/Users";
import Layout from "./Compnents/Layout/Layout";
import MainDashboard from "./Compnents/MainDashboard/DasboardPannel/MainDashboard";
import HitsGraph from "./Compnents/MainDashboard/Graphs/HitsGraph";
import MainNewsConatainer from "./Compnents/MainDashboard/news/MainNewsConatainer";
import NewsDetails from "./Compnents/MainDashboard/news/NewsDetails";
import AllPendingNews from "./Compnents/MainDashboard/news/AllPendingNews";
import AllRejectedNews from "./Compnents/MainDashboard/news/AllRejectedNews";
import MobileBrandsMainSec from "./Compnents/MainDashboard/Brands/MobileBrandsMainSec";
import AddNewsForm from "./Compnents/MainDashboard/news/AddNewsForm";
import AllPublishNews from "./Compnents/MainDashboard/news/AllPublishNews";
import MobileDetails from "./Compnents/MainDashboard/Mobiles/MobileDetails";
import Logout from "./Compnents/MainDashboard/LoginSignUp/Logout";
import AllMobiles from "./Compnents/MainDashboard/Mobiles/AllMobiles";
import GlossaryContainer from "./Compnents/MainDashboard/Glossary/GlossaryContainer";
import AddsContainer from "./Compnents/MainDashboard/Ads/AddsContainer";
import LogsContainer from "./Compnents/Logs/LogsContainer";
import BuyandSellMainContainer from "./Compnents/MainDashboard/BuyandSell/BuyandSellMainContainer";
import Record from "./Compnents/Logs/Record";
import TotalAds from  './Compnents/TotalAds';


import { logout } from "./Redux/UserReducer";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

function App() {
  // const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [logoutOpen, setLogoutOpen] = useState(false);

  const toggleLogout = () => {
    setSidebarOpen(!logoutOpen);
  };

  //REFERENCE VARIABLES

  const isMounted = useRef(true);
  const timeoutId = useRef(null);

  //DISATCH VARIABLE

  const dispatch = useDispatch();

  // THIS FUNCTION IS WRITTEN IN USECALLBACK SO THAT IT WILL NOT BE CALLED ON EACH UNNECESSARY RERENDERING

  // ========== 2  ===================

  const resetTimeout = useCallback(() => {
    //IF COMPONENT IS MOUNTED THEN START A TIMER CLEAR PREVIOUS TIMERS

    if (isMounted.current) {
      clearTimeout(timeoutId.current);

      //PRODUCTION

      timeoutId.current = setTimeout(() => {
        dispatch(logout());
      }, 600000); // approximately 10 minutes delay

      //DEVELOPMENT

      // timeoutId.current = setTimeout(() => {
      //   dispatch(logout());
      // }, 240000000);
    }
  }, [dispatch]); // Added dispatch to the dependency array

  // =============  1 ====================

  // USEEFFECT WILL BE CALLED FIRST AND START TIMER OF 30 SECONDS IN THIS 30 SECONDS IT WILL CONTINUE

  // TO RUN USEEFFECT AND AFTER 30 SECONDS IT WILL LOGOUT USER BUT IN THIS DURATION IF USER MOVE MOUSE THEN

  // TIMEOUT WILL BE CLEARED AND NEW TIMEOUT WILL BE CREATED !!!

  useEffect(() => {
    //OBSERVE EVENTS

    document.addEventListener("mousemove", resetTimeout);
    document.addEventListener("keydown", resetTimeout);

    resetTimeout(); // Start the timeout when the component mounts

    //RETURN VALUE WILL ONLY BE CALLED IF OUR APP COMPONENT IS UN MOUNTED WHICH WILL NOT HAPPEN IN REACT

    return () => {
      //REMOVE EVENT LISTENERS CLEAR TIMEOUT

      isMounted.current = false;
      document.removeEventListener("mousemove", resetTimeout);
      document.removeEventListener("keydown", resetTimeout);
      clearTimeout(timeoutId.current);
    };
  }, [resetTimeout]);

  return (
    <Userprovider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                toggleSidebar={toggleSidebar}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                toggleLogout={toggleLogout}
              />
            }
          >
            <Route index element={<MainDashboard />} />
            <Route
              path="mobileBrandsMainSec"
              element={<MobileBrandsMainSec />}
            />
            <Route path="users" element={<Users />} />
            <Route path="hitsgraph" element={<HitsGraph />} />
            <Route path="MobileBrandDetails" element={<MobileBrandDetails />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="profile" element={<Profile />} />
            <Route path="ads" element={<AddsContainer />} />
            <Route path="/useractivity/:uid" element={<UserActivity />} />
            <Route path="mainNewsContainer" element={<MainNewsConatainer />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="newsdetails/:title/:id" element={<NewsDetails />} />
            <Route path="news" element={<AllPublishNews />} />
            <Route path="allPendingNews" element={<AllPendingNews />} />
            <Route path="allRejectedNews" element={<AllRejectedNews />} />
            <Route path="addNewsForm" element={<AddNewsForm />} />
            <Route path="/MobileDetails/:model" element={<MobileDetails />} />
            <Route path="glossary" element={<GlossaryContainer />} />
            <Route path="logout" element={<Logout />} />
            <Route path="allmobiles" element={<AllMobiles />} />
            <Route path="logs" element={<LogsContainer />} />
            <Route
              path="buyandsellcontainer"
              element={<BuyandSellMainContainer />}
            />
            <Route path="/record" element={<Record />} />
            <Route path="/totalAds/:uid" element={<TotalAds  />} />
            
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </Userprovider>
  );
}

export default App;
