// export default MobileDetails;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "../../../Compnents/MyAlerts";
import { getMobileDetailsByModel } from "../../../Redux/SingleMobileDetails";
import { useDispatch, useSelector } from "react-redux";
import { updateMobileDetailsByModel, } from "../../../Redux/SingleMobileDetails";
import ImageUpload1 from "../../ImageUpload1";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const inputStyle = `text-[15px]  h-10  w-[200px] bg-transparent px-4  `;
const ptagStyle = `text-sm font-semibold w-[150px] px-4 py-2 border-r`;
const featureContainerStyle = ` border-y shadow-md shadow-gray-700 `;
const headingStyle = `text-[1rem] font-semibold text-white bg-gray-700 py-2 px-4`;
const containerstyles = `flex border-b h-[43px]  bg-white  `;

const MobileDetails = () => {

  const dispatch = useDispatch();

  const { token, currentUser } = useSelector((state) => state.user);

  const {imageUrl1, isLoading, uploaded1} = useSelector(state => state.imageUplaod)

  //STRING PROPERTIES

  const [imageurl, setImageurl] = useState("");
  const [model, setModel] = useState("-");
  const [brand, setBrand] = useState("-");
  const [description, setDescription] = useState("-");
  const [features, setFeatures] = useState("-");
  const [launched, setLaunched] = useState("-");
  const [resolution, setResolution] = useState("-");
  const [wlan, setWlan] = useState("-");
  const [usb, setUSB] = useState("-");
  const [nfc, setNFC] = useState("-");
  const [cpu, setCPU] = useState("-");
  const [gps, setGPS] = useState("-");
  const [colors, setColor] = useState("-");
  const [gpu, setGPU] = useState("-");
  const [sim, setSIM] = useState("-");
  const [bluetooth, setBluetooth] = useState("-");
  const [chipset, setChipset] = useState("-");
  const [technology, setTechnology] = useState("-");
  const [os, setOS] = useState("-");
  const [dimensions, setDimensions] = useState("-");
  const [TwoGBand, setTwoGBand] = useState("-");
  const [ThreeGBand, setThreeGBand] = useState("-");
  const [FourGBand, setFourGBand] = useState("-");
  const [createdOn, setCreatedOn] = useState("-");
  const [launchedDate, setLaunchedDate] = useState("-");
  const [dateUpdated, setDateUpdated] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  

  // NUMERIC PROPERTIES

  const [frontCam, setFrontCam] = useState(0);
  const [backCam, setBackCam] = useState(0);
  const [battery_Mah, setBatteryMah] = useState(0);
  const [rating, setRating] = useState(0);
  const [size, setSize] = useState(0);
  const [weight, setWeight] = useState(0);
  const [pricePkr, setPricepkr] = useState(0);
  const [priceUsd, setPriceUsd] = useState(0);
  const [rom, setROM] = useState(0);
  const [ram, setRAM] = useState(0);
  const [refreshrateFps, setRefreshRateFps] = useState(0);

  const [showDatePicker, setShowDatePicker] = useState(false);

  //REACT QUILL OPTIONS

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }, [{ list: "ordered" }]],
        [{ font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "blockquote", "strike"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "+1" },
          { indent: "-1" },
        ],
        ["link"],
      ],
    },
  };



  const handleTextClick = () => {
    setShowDatePicker(true);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    // Create a date object in UTC
    const utcDate = new Date(selectedDate + "T00:00:00Z");

    // Convert UTC date to Pakistan Standard Time (PKT)
    const options = {
      timeZone: "Asia/Karachi",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const pktDate = new Intl.DateTimeFormat("en-US", options).format(utcDate);

    setLaunchedDate(pktDate);
    setCreatedOn(pktDate);
    setDateUpdated(true);
    setShowDatePicker(false);
  };

  const id = useParams().model;

  const { mobileDetails } = useSelector((state) => state.singleMobileDetails);

  useEffect(() => {
    dispatch(getMobileDetailsByModel(id));
  }, []);

  useEffect(() => {
    
    if (mobileDetails) {
      console.log("what coming in description",mobileDetails.description)
      setImageurl(mobileDetails.imageurl);
      setBackCam(mobileDetails.backCam);
      setDescription(mobileDetails.description);
      setBrand(mobileDetails.brand);
      setROM(mobileDetails.rom);
      setFrontCam(mobileDetails.frontCam);
      setBatteryMah(mobileDetails.battery_Mah);
      setLaunched(mobileDetails.launched);
      setRating(mobileDetails.rating);
      setSize(mobileDetails.size);
      setWeight(mobileDetails.weight);
      setPricepkr(mobileDetails.pricePkr);
      setPriceUsd(mobileDetails.priceUsd);
      setGPS(mobileDetails.gps);
      setCPU(mobileDetails.cpu);
      setNFC(mobileDetails.nfc);
      setROM(mobileDetails.rom);
      setUSB(mobileDetails.usb);
      setWlan(mobileDetails.wlan);
      setResolution(mobileDetails.resolution);
      setRAM(mobileDetails.ram);
      setROM(mobileDetails.rom);
      setGPU(mobileDetails.gpu);
      setSIM(mobileDetails.sim);
      setRefreshRateFps(mobileDetails.refreshrateFps);
      setBluetooth(mobileDetails.bluetooth);
      setModel(mobileDetails.model);
      setChipset(mobileDetails.chipset);
      setColor(mobileDetails.colors);
      setFeatures(mobileDetails.features);
      // setModel(mobileDetails.model);
      setTechnology(mobileDetails.technology);
      setOS(mobileDetails.os);
      setDimensions(mobileDetails.dimensions);
      setTwoGBand(mobileDetails["2GBand"]);
      setThreeGBand(mobileDetails["3GBand"]);
      setFourGBand(mobileDetails["4GBand"]);
      setCreatedOn(mobileDetails.createdOn);
     

      // Convert seconds to milliseconds
      const milliseconds = mobileDetails.createdOn.seconds * 1000;

      // Create a Date object
      let date = new Date(milliseconds);

      // Format the date
      date = date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      setLaunchedDate(date);
    }
  }, [mobileDetails]);

  function handleDefination(newValue) {
    setDescription(newValue);
  }

  function handleSubmit(e) {
    e.preventDefault();

    //ACTUAL DATA

    const data = {
      model: model.toLocaleUpperCase(),
      brand,
      description,
      features,
      launched,
      resolution,
      wlan,
      usb,
      nfc,
      cpu,
      gps,
      colors,
      gpu,
      sim,
      bluetooth,
      chipset,
      technology,
      os,
      dimensions,
      frontCam,
      backCam,
      battery_Mah,
      rating,
      size,
      weight,
      pricePkr,
      priceUsd,
      rom,
      ram,
      refreshrateFps,
      "2GBand": TwoGBand,
      "3GBand": ThreeGBand,
      "4GBand": FourGBand,
      createdOn,
      imageurl:imageUrl1.length >0 ?imageUrl1 :imageurl
    };

    dispatch(updateMobileDetailsByModel(id, data,currentUser));
    setIsSubmitted(true);
  }

  return (
    <div className="text-sm text-gray-700 py-2 my-4 ">
      <div className="flex justify-between px-10">
        {isSubmitted && (
          <Alert model={mobileDetails.model} onClose={setIsSubmitted} />
        )}

        <div className=" py-3 flex border-green-600 ">
          <img
            className="h-[230px] w-[300px] object-contain  shadow-md "
            src={imageurl}
            alt="Mobile"
          />

          {/* <input type="file" onChange={handleFileChange} /> */}
        </div>
        <div className=" flex items-center">
          <img
            className="h-[90px] w-[100px] object-contain  "
            src={imageurl}
            alt="Mobile"
          />
          <img
            className="h-[90px] w-[100px] object-contain  "
            src={imageurl}
            alt="Mobile"
          />
          <img
            className="h-[90px] w-[100px] object-contain  "
            src={imageurl}
            alt="Mobile"
          />
        </div>
      </div>

      <div className=" px-12 py-3">
        <div>
          <form onSubmit={handleSubmit}>
            {/* Edit image */}

            <div className="my-2">
              <h1 className={headingStyle}>Edit Image</h1>
              <div className="flex space-x-3 mb-3 shadow-md shadow-gray-700 ">
                <div className="w-[430px] ">
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Image</p>
                    <ImageUpload1 className=" text-sm text-gray-700 outline-none  rounded-sm "/>
                  </div>
                </div>
              </div>
            </div>

            {/* 5) builds  */}
            <div className="my-2">
              <h1 className={headingStyle}>Builds</h1>
              <div className="flex space-x-3 mb-3 shadow-md shadow-gray-700 ">
                <div className="w-[430px] ">
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Model</p>
                    <input
                      required
                      type="text"
                      value={model}
                      // readOnly
                      onChange={(e) => setModel(e.target.value)}
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Brand</p>
                    <input
                      required
                      type="text"
                      value={brand}
                      // readOnly
                      onChange={(e) => setBrand(e.target.value)}
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Color</p>
                    <input
                      required
                      type="text"
                      value={colors}
                      onChange={(e) => setColor(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Weight</p>
                    <input
                      required
                      type="number"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Dimensions</p>
                    <input
                      required
                      type="text"
                      value={dimensions}
                      onChange={(e) => setDimensions(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Launched</p>
                    <select name="" id="" className={inputStyle}>
                      <option value="">Yes</option>
                      <option value="">No</option>
                    </select>
                    {/* <input
                      required
                      type="text"
                      value={launched}
                      onChange={(e) => {
                        setLaunched(e.target.value);
                        setDateUpdated(true);
                      }}
                      autoFocus
                      className={inputStyle}
                    /> */}
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Os</p>
                    <input
                      required
                      type="text"
                      value={os}
                      onChange={(e) => setOS(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Launched Date</p>
                    <input
                      required
                      type="text"
                      value={createdOn.split("T")[0]}
                      onChange={(e) => setTechnology(e.target.value)}
                      onClick={handleTextClick}
                      // readOnly
                      className={inputStyle}
                    />

                    {showDatePicker && (
                      <input
                        required
                        type="date"
                        onChange={handleDateChange}
                        onBlur={() => setShowDatePicker(false)} // Hide the date picker when it loses focus
                      />
                    )}
                  </div>
                </div>
                <div className="   bg-white">
                  <ReactQuill
                    theme="snow"
                    value={description}
                    modules={modules}
                    onChange={handleDefination}
                    className="w-[450px] h-[280px]"
                    required
                  />
                </div>
              </div>
            </div>
            {/* builds ends  */}
            <div className="grid  gap-3 grid-cols-2">
              {/* 1)  PRICE  */}
              <div>
                <div className={featureContainerStyle}>
                  <h1 className={headingStyle}>Price</h1>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Price-usd</p>
                    <input
                      required
                      type="text"
                      value={priceUsd}
                      onChange={(e) => setPriceUsd(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Price Pkr</p>
                    <input
                      required
                      type="text"
                      value={pricePkr}
                      onChange={(e) => setPricepkr(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                </div>
              </div>
              {/* PRICE  */}
              {/* 2)  camera starts */}
              <div>
                <h1 className={headingStyle}>Camera's</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Frontcam</p>
                    <input
                      required
                      type="number"
                      value={frontCam}
                      onChange={(e) => setFrontCam(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Backcam</p>
                    <input
                      required
                      type="number"
                      value={backCam}
                      onChange={(e) => setBackCam(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                </div>
              </div>
              {/* camera ends */}
              {/* 3) MEMORY */}
              <div>
                <h1 className={headingStyle}>Memory</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Ram</p>
                    <div className="flex justify-start">
                      <input
                        required
                        type="text"
                        value={ram}
                        onChange={(e) => setRAM(e.target.value)}
                        autoFocus
                        className={inputStyle}
                      />
                      <span>GB</span>
                    </div>
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Rom</p>
                    <div>
                      <input
                        required
                        type="text"
                        value={rom}
                        onChange={(e) => setROM(e.target.value)}
                        autoFocus
                        className={inputStyle}
                      />
                      <span>GB</span>
                    </div>
                  </div>
                </div>
              </div>
              {/*MEMORY */}
              {/* 4) BATTERY */}
              <div>
                <h1 className={headingStyle}> Battery</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}> Batterymah</p>
                    <input
                      required
                      type="number"
                      value={battery_Mah}
                      onChange={(e) => setBatteryMah(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                </div>
              </div>
              {/* BATTERY */}
              {/* 6)  DISPLAY */}
              <div>
                <h1 className={headingStyle}>Display</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Resolution</p>
                    <input
                      type="text"
                      value={resolution}
                      onChange={(e) => setResolution(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Size</p>
                    <input
                      type="number"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Technology</p>
                    <input
                      type="text"
                      value={technology}
                      onChange={(e) => setTechnology(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* DISPLAY */}
              {/* 7)  processor start */}
              <div>
                <h1 className={headingStyle}>Processor</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Cpu</p>
                    <input
                      type="text"
                      value={cpu}
                      onChange={(e) => setCPU(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Chipset</p>
                    <input
                      type="text"
                      value={chipset}
                      onChange={(e) => setChipset(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Gpu</p>
                    <input
                      type="text"
                      value={gpu}
                      onChange={(e) => setGPU(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* processor ends */}
              {/* 8) BANDS  */}
              <div>
                <h1 className={headingStyle}>Bands</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>2G BAND</p>
                    <input
                      type="text"
                      value={TwoGBand}
                      onChange={(e) => setTwoGBand(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>3G BAND</p>
                    <input
                      type="text"
                      value={ThreeGBand}
                      onChange={(e) => setThreeGBand(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>4G BAND</p>
                    <input
                      type="text"
                      value={FourGBand}
                      onChange={(e) => setFourGBand(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* BANDS  */}
              {/*9) COMMUNICATION */}
              <div>
                <h1 className={headingStyle}>Communications</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Bluetooth</p>
                    <input
                      type="text"
                      value={bluetooth}
                      onChange={(e) => setBluetooth(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Wlan</p>
                    <input
                      type="text"
                      value={wlan}
                      onChange={(e) => setWlan(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Gps</p>
                    <input
                      type="text"
                      value={gps}
                      onChange={(e) => setGPS(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>NFC</p>
                    <select name="" id="" className={inputStyle}>
                      <option value="">Yes</option>
                      <option value="">No</option>
                    </select>
                    {/* <input
                      type="text"
                      value={nfc}
                      onChange={(e) => setNFC(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    /> */}
                  </div>
                </div>
              </div>
              {/* COMMUNICATION */}
              {/* GENERAL FEATURES  start*/}
              <div>
                <h1 className={headingStyle}>Features</h1>
                <div className={featureContainerStyle}>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Usb</p>
                    <input
                      type="text"
                      value={usb}
                      onChange={(e) => setUSB(e.target.value)}
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Features</p>
                    <input
                      type="text"
                      value={features}
                      onChange={(e) => setFeatures(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>SIM</p>
                    <input
                      type="text"
                      value={sim}
                      onChange={(e) => setSIM(e.target.value)}
                      autoFocus
                      className={inputStyle}
                      required
                    />
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Rom</p>
                    <div>
                      <input
                        type="text"
                        value={rom}
                        onChange={(e) => setROM(e.target.value)}
                        autoFocus
                        className={inputStyle}
                        required
                      />
                      <span>GB</span>
                    </div>
                  </div>
                  <div className={containerstyles}>
                    <p className={ptagStyle}>Rate Fps</p>
                    <input
                      required
                      type="text"
                      value={refreshrateFps}
                      onChange={(e) => setRefreshRateFps(e.target.value)}
                      autoFocus
                      className={inputStyle}
                    />
                  </div>
                </div>
              </div>
              {/*MEMORY */}
            </div>
            {isSubmitted == false && (
              <button
                type="submit"
                className="bg-slate-600 text-white w-full p-2 hover:bg-slate-400 mt-3 duration-300  "
              >
                UPDATE !!
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default MobileDetails;
