import React from 'react'

import AdsDetails from './AdsDetails'

const AddsContainer = () => {
  return (
    <div><AdsDetails/></div>
  )
}

export default AddsContainer