import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
import { getRejectedNews } from "../../../Redux/NewsReducer";

const RejectedNews = () => {
  const { rejectedNews, status } = useSelector((state) => state.news);

  const dispatch = useDispatch();

  const settings = {
    infinite: false,
    speed: 700,
    slidesToShow: Math.min(3, rejectedNews.length),
    slidesToScroll: 2,
    autoplay: false,
  };

  useEffect(() => {
    dispatch(getRejectedNews());
  }, []);

  return (
    <div className="trending-news px-3 mt-4 rounded-md space-y-3 2xl:h-[450px] h-[250px] py-3 border  border-gray-700 my-3 bg-white shadow-md shadow-gray-500">
      <div className="flex justify-between">
        <h1 className="2xl:text-[2rem] font-semibold text-gray-600 mb-2">
          Rejected News
        </h1>
        <Link
          className="2xl:text-[2rem] text-sm underline text-blue-500 font-semibold px-4 2xl:py-2"
          to="/allRejectedNews"
        >
          View all
        </Link>
      </div>
      {status == "loading" ? (
        <div className="h-full py-11">
          <Loader type="bubble-scale" bgColor={"#AEB9B8"} size={50} />
        </div>
      ) : (
        <Slider {...settings}>
          {rejectedNews &&
            rejectedNews.map((news) => (
              <Link to={`/newsdetails/${news.title}/${news._id}`} key={news._id}>
                <div className="hover:scale-110 duration-300 mx-2 rounded-lg 2xl:h-[340px] md:h-[170px] 2xl:w-[540px] lg:w-[320px] md:w-[200px] h-36  relative">
                  <img
                    src={news.image1}
                    alt="news images"
                    className="relative rounded-lg 2xl:w-[540px] lg:w-[300px] md:w-[200px] inset-0 2xl:h-[300px] md:h-[170px] object-cover bg-no-repeat shadow-md"
                  />
                  <div className="2xl:w-[480px] lg:w-[300px] md:w-[200px] 2xl:text-[1.8rem] line-clamp-2 text-[0.9rem]  md:pt-[6rem] 2xl:pt-[12rem] pt-[4.5rem] absolute inset-0 z-50  text-white overflow-x-hidden px-2">
                  <h1>{typeof news.title === 'string' ? news.title : "No title available"}</h1>

                  </div>
                </div>
              </Link>
            ))}
        </Slider>
      )}
    </div>
  
  );
};

export default RejectedNews;
