import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../Redux/UsersReducer.js";

const totalUsers = [
  { usersRole: "Users", numbers: 534, percentage: "11%" },
  { usersRole: "Admin", numbers: 34, percentage: "0.14%" },
  { usersRole: "Publishers", numbers: 74, percentage: "7%" },
  { usersRole: "Editors", numbers: 8, percentage: "14%" },
];

const UsersCategories = () => {

  const { users, status } = useSelector((state) => state.users);

  const [manager, setManager] = useState("");

  const [publiser, setPublisher] = useState("");

  const [glossaryeditor, setGlossary] = useState("");

  const [mobileseditor, setMobilesEditor] = useState("");

  const [managerPercentage, setManagerPercentage] = useState("");

  const [publiserPercentage, setPublisherPercentage] = useState("");

  const [glossaryeditorPercentage, setGlossaryPercentage] = useState("");

  const [mobileseditorPercentage, setMobilesEditorPercentage] = useState("");

  function getCountOfRoles() {
    
    let managersCount = users.filter((user) => user.role == "manager");

    setManager(managersCount.length);

    let publisherCount = users.filter((user) => user.role == "publisher");

    setPublisher(publisherCount.length);

    let glossaryCount = users.filter((user) => user.role == "glossaryeditor");

    setGlossary(glossaryCount.length);

    let mobilesEditorCount = users.filter(
      (user) => user.role == "mobileseditor"
    );

    setMobilesEditor(mobilesEditorCount.length);

    let managerP = ( managersCount.length/ users.length )*100;

    setManagerPercentage(managerP.toFixed(2));

    let publisherP = ( publisherCount.length/ users.length )*100;

    setPublisherPercentage(publisherP);

    let glossaryP = ( glossaryCount.length/ users.length )*100;

    setGlossaryPercentage(glossaryP.toFixed(2));

    let mobilesEditorP = ( mobilesEditorCount.length/ users.length )*100;

    setMobilesEditor(mobilesEditorP.toFixed(2));

  }

  useEffect(() => {
    if (users.length > 0) {
      getCountOfRoles();
    }
  }, [users]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div className="grid lg:grid-cols-4 grid-cols-4 md:gap-3 gap-2 my-2 lg:w-full ">
      <div className="bg-white 2xl:h-[8rem] md:h-[4rem] h-[3rem]  lg:w-full  sm:w-[175px] rounded-md lg:py-3 py-1 md:px-3  px-2 border shadow-md  border-gray-200 ">
        {/* <div className="w-full md:space-y-0 space-y-2"> */}
        <h3 className=" 2xl:text-[2rem] md:text-[14px] text-[11px] md:font-medium font-bold 2xl:m-2">
          Manager
        </h3>
        <div className="flex justify-between items-center py-1 2xl:m-2">
          <h1 className="2xl:text-3xl md:text-xl text-[12px] text-gray-900 md:font-semibold  font-semibold">
            {manager}
          </h1>

          <p className="border border-gray-500 2xl:h-10 2xl:pt-2 md:h-5 text-center 2xl:w-28 w-12 px-1 2xl:text-[30px] text-[12px] items-end 2xl:m-2">
            {managerPercentage}%
          </p>
        </div>
      </div>

      <div className="bg-white 2xl:h-[8rem] md:h-[4rem] h-[3rem]  lg:w-full  sm:w-[175px] rounded-md lg:py-3 py-1 md:px-3  px-2 border shadow-md  border-gray-200 ">
        {/* <div className="w-full md:space-y-0 space-y-2"> */}
        <h3 className=" 2xl:text-[2rem] md:text-[14px] text-[11px] md:font-medium font-bold 2xl:m-2">
          News Editor
        </h3>
        <div className="flex justify-between items-center py-1 2xl:m-2">
          <h1 className="2xl:text-3xl md:text-xl text-[12px] text-gray-900 md:font-semibold  font-semibold">
            {publiser}
          </h1>

          <p className="border border-gray-500 2xl:h-10 2xl:pt-2 md:h-5 text-center 2xl:w-28 w-12 px-1 2xl:text-[30px] text-[12px] items-end 2xl:m-2">
            {publiserPercentage}%
          </p>
        </div>
      </div>

      <div className="bg-white 2xl:h-[8rem] md:h-[4rem] h-[3rem]  lg:w-full  sm:w-[175px] rounded-md lg:py-3 py-1 md:px-3  px-2 border shadow-md  border-gray-200 ">
        {/* <div className="w-full md:space-y-0 space-y-2"> */}
        <h3 className=" 2xl:text-[2rem] md:text-[14px] text-[11px] md:font-medium font-bold 2xl:m-2">
          Glossary Editor
        </h3>
        <div className="flex justify-between items-center py-1 2xl:m-2">
          <h1 className="2xl:text-3xl md:text-xl text-[12px] text-gray-900 md:font-semibold  font-semibold">
            {glossaryeditor}
          </h1>

          <p className="border border-gray-500 2xl:h-10 2xl:pt-2 md:h-5 text-center 2xl:w-28 w-12 px-1 2xl:text-[30px] text-[12px] items-end 2xl:m-2">
            {glossaryeditorPercentage}%
          </p>
        </div>
      </div>

      <div className="bg-white 2xl:h-[8rem] md:h-[4rem] h-[3rem]  lg:w-full  sm:w-[175px] rounded-md lg:py-3 py-1 md:px-3  px-2 border shadow-md  border-gray-200 ">
        {/* <div className="w-full md:space-y-0 space-y-2"> */}
        <h3 className=" 2xl:text-[2rem] md:text-[14px] text-[11px] md:font-medium font-bold 2xl:m-2">
          Mobiles Editor
        </h3>
        <div className="flex justify-between items-center py-1 2xl:m-2">
          <h1 className="2xl:text-3xl md:text-xl text-[12px] text-gray-900 md:font-semibold  font-semibold">
            {mobileseditor}
          </h1>

          <p className="border border-gray-500 2xl:h-10 2xl:pt-2 md:h-5 text-center 2xl:w-28 w-12 px-1 2xl:text-[30px] text-[12px] items-end 2xl:m-2">
            {mobileseditorPercentage}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default UsersCategories;
