import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { signup } from "../../../Redux/UserReducer";
import { IoMdArrowRoundBack } from "react-icons/io";

const UserAddForm = ({
  users,
  submitForm,
  handleChange,
  handleFormVisible,
}) => {
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [role, setRole] = useState("");

  const dispatch = useDispatch();

  function handleSubmit() {
    const user = { name, email, password, role };

    dispatch(signup(user));

    handleFormVisible();
  }

  return (
    <div className=" fixed inset-0 z-50 bg-black/90 py-7 h-[850px] ">
      <button onClick={handleFormVisible} className="text-white mx-5" title="Go back">
        <IoMdArrowRoundBack size={20} />
      </button>
      <form
        onSubmit={handleSubmit}
        action=""
        className=" bg-white w-[600px] mx-auto  text-black px-7 py-6  space-y-4"
      >
        <h1 className="text-center font-semibold text-2xl mb-3">
          Add News Details
        </h1>
        <div>
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Name
          </label>
          <input
            required
            type="name"
            name="name"
            className="w-full h-12 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter Name"
          />
        </div>
        <div>
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Email
          </label>
          <input
            required
            type="text"
            name="email"
            className="w-full h-12 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Enter email"
          />
        </div>
        <div>
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Password
          </label>
          <input
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full h-12 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            placeholder="Password"
            minlength="6"
          />
        </div>

        <div>
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Select Role
          </label>
          <select
            className="w-full h-12 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
            onChange={(e) => setRole(e.target.value)}
            name="role"
            value={role}
            required
          >
            <option value="" className="w-[30px] 2xl:text-sm">
              Select a Role
            </option>

            <option value="manager" className="w-[30px] 2xl:text-sm">
              Manager
            </option>

            <option value="publisher" className="w-[30px] 2xl:text-sm">
              Publisher
            </option>

            <option value="mobileseditor" className="w-[30px] 2xl:text-sm">
              Mobiles and Brands Editor
            </option>

            <option value="glossaryeditor" className="w-[30px] 2xl:text-sm">
              Glossary Editor
            </option>

            <option value="adseditor" className="w-[30px] 2xl:text-sm">
              Ads Editor
            </option>
          </select>
        </div>
        <button
          type="submit"
          className="hover:bg-gray-700 bg-gray-500 2xl:rounded-md  md:w-full w-[230px] 2xl:h-[100px]  md:h-[40px] h-[30px] text-white font-semibold  md:pt-0 duration-150 px-3 outline-none 2xl:text-[1.9rem]"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default UserAddForm;
