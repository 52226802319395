//Header files

//Built-in Modules

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';

import {
  getUserLogs,
  mobilesPerBrand,
  getAdsLogs,
  adsPerBrand,
} from "../../Redux/logsReducer";

//Chart modules
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Link, useNavigate } from "react-router-dom";

// Registering necessary chart elements with Chart.js for both bar and line charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

export default function Record() {
  const dispatch = useDispatch();

  const { userLogs, brandsMobilesCount, adsLogs, adsPerBrandRecord } =
    useSelector((state) => state.logs);

  const [mobiles, setMobiles] = useState(false);

  const [news, setNews] = useState(false);

  const [reviews, setReviews] = useState(false);

  const [ads, setAds] = useState(false);

  // Calculate the count of mobile clicks per brand

  const countBrands = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    userLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(mobilesPerBrand(updatedCounts));
  };

  // Bar Chart

  useEffect(() => {
    if (userLogs.length > 0) countBrands();
  }, [userLogs]);

  useEffect(() => {
    dispatch(getUserLogs());
  }, [dispatch]);

  // Calculate the count of mobile clicks per brand
  const countBrandsForAds = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    adsLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(adsPerBrand(updatedCounts));
  };

  // Define an array of colors for each bar
  const barColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#FF9F40",
    "#9966FF",
    "#FF3366",
    "#FF33CC",
    "#33FF57",
    "#FF5733",
    "#33B5E5",
    "#FFB74D",
    "#009688",
    "#E91E63",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#00BCD4",
    "#FF5722",
    "#2196F3",
    "#673AB7",
    "#9C27B0",
    "#FF9800",
    "#FFEB3B",
    "#607D8B",
    "#FF4081",
  ];

  // Preparing the data for the Bar chart
  const chartData = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Clicks per Brand",
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(
          0,
          Object.keys(brandsMobilesCount).length
        ), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options for better presentation
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Mobile Clicks per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Brands",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Click Count",
        },
      },
    },
  };

  // Pie Chart

  const navigate = useNavigate();

  useEffect(() => {
    if (adsLogs.length > 0) countBrandsForAds();
  }, [adsLogs]);

  useEffect(() => {
    dispatch(getAdsLogs());
  }, [dispatch]);

  // Prepare the data for the Pie Chart
  const pieChartData = {
    labels: Object.keys(adsPerBrandRecord), // Brand names
    datasets: [
      {
        data: Object.values(adsPerBrandRecord), // Click counts
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#F7464A",
          "#8E44AD",
          "#2ECC71",
          "#E74C3C",
          "#3498DB",
          "#9B59B6",
          "#F39C12",
          "#D35400",
          "#1ABC9C",
          "#34495E",
          "#2C3E50",
          "#16A085",
          "#D35400",
          "#F1C40F",
          "#7F8C8D",
          "#BDC3C7",
        ], // Colors for each brand segment
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#F7464A",
          "#8E44AD",
          "#2ECC71",
          "#E74C3C",
          "#3498DB",
          "#9B59B6",
          "#F39C12",
          "#D35400",
          "#1ABC9C",
          "#34495E",
          "#2C3E50",
          "#16A085",
          "#D35400",
          "#F1C40F",
          "#7F8C8D",
          "#BDC3C7",
        ], // Hover colors
      },
    ],
  };

  // Function to handle pie chart sector clicks
  const handlePieClick = () => {
    navigate("/logs"); // Opens the single link in a new tab
  };

  // Chart options to handle click events
  const options = {
    onClick: handlePieClick, // Set the click handler for all sectors
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}`; // Customize the tooltip if necessary
          },
        },
      },
    },
  };

  // Line Graph

  const [counts, setCounts] = useState([]);
  const [dates, setDates] = useState([]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://mm.magma3c.com/logs/lineGraph"); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCounts(data.counts);
        setDates(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForNews = {
    labels: dates, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Clicks On News", // Label for the dataset
        data: counts, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  // Chart options for the line chart
  const optionsForNews = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Clicks",
        },
      },
    },
  };

  // Line Chart #2

  const [countsForReviews, setCountsForReviews] = useState([]);
  const [datesForReviews, setDatesForReviews] = useState([]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://mm.magma3c.com/logs/lineGraphForReviews"
        ); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCountsForReviews(data.counts);
        setDatesForReviews(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForReviews = {
    labels: datesForReviews, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Clicks On Reviews", // Label for the dataset
        data: countsForReviews, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  // Chart options for the line chart
  const optionsForReviews = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Clicks",
        },
      },
    },
  };

  // Function to generate PDF
  const generatePDF = () => {
    const element = document.getElementById("content-to-save");
    if (element) {
      const options = {
        margin: [10, 10, 10, 10], // Set margins (top, right, bottom, left)
        filename: "download.pdf",  // File name of the generated PDF
        image: { type: "jpeg", quality: 1 }, // Set image format and quality (adjust quality for higher resolution)
        html2canvas: {
          scale: 3, // Increase scale to enhance the resolution (higher value for better resolution)
          letterRendering: true, // Improve text rendering
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Set PDF format and page orientation
      };
      
      html2pdf().from(element).set(options).save();
    } else {
      console.error("Element not found");
    }
  };
  
  

  return (
    <div>

       {/* Button to generate PDF */}
       <button onClick={generatePDF} className="z-[60] absolute bg-red-700 text-white p-2 rounded-sm hover:bg-red-500">Save as PDF</button>
      
      <h1 className="text-blue-950 text-4xl text-center mb-4">
        TOTAL CLICKS TILL NOW
      </h1>

      {/* GRAPHS */}

      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4"  id="content-to-save">
        <div
          className="shadow bg-white hover:bg-slate-400 flex justify-center items-center rounded-md p-4 cursor-pointer "
          onClick={() => {
            setMobiles(true);
          }}
        >
          <div className="w-full">
            <Bar data={chartData} options={chartOptions} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              MOBILES CLICKS
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white flex justify-center items-center rounded-md p-4 cursor-pointer"
          onClick={() => {
            setNews(true);
          }}
        >
          <div className="w-full">
            <Line data={dataForNews} options={optionsForNews} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              NEWS CLICKS
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white flex justify-center items-center rounded-md p-4 cursor-pointer"
          onClick={() => {
            setReviews(true);
          }}
        >
          <div className="w-full">
            <Line data={dataForReviews} options={optionsForReviews} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800">
              REVIEWS CLICKS
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white flex justify-center items-center rounded-md p-4 cursor-pointer"
          onClick={() => {
            setAds(true);
          }}
        >
          <div className="w-full h-60  flex justify-between flex-col items-center">
            <Pie data={pieChartData} options={options} />
            <div className="bg-slate-600 text-white text-center w-[100%] hover:bg-slate-800">
              ADS CLICKS
            </div>
          </div>
        </div>
      </div>

      {mobiles && (
        
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setMobiles(false)}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%] ">
          <Link to={"/logs"}>
            <Bar data={chartData} options={chartOptions} />
            </Link>
          </div>
          {/* Table displaying brand counts */}
          {/* <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border-b p-2 text-left">Brand</th>
                <th className="border-b p-2 text-left">Clicks</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(brandsMobilesCount).map(([brand, count]) => (
                <tr key={brand}>
                  <td className="border-b p-2">{brand}</td>
                  <td className="border-b p-2">{count}</td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
       
      )}

      {ads && (
         
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setAds(false)}
        >
          {/* No Of Clicks On Ads */}

          <div className="p-6 bg-white w-[100%] md:w-[50%] md:h-[80%] flex justify-center items-center">
          <Link to={"/logs"}>
            <Pie data={pieChartData} options={options} />
            </Link>
          </div>
        </div>
      
      )}

      {news && (
        
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setNews(false)}
        >
          {/* Displaying the Line Chart */}

          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
          <Link to={"/logs"}>
            <Line data={dataForNews} options={optionsForNews} />
            </Link>
          </div>
        </div>
        
      )}

      {reviews && (
       
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setReviews(false)}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
          <Link to={"/logs"}>
            <Line data={dataForReviews} options={optionsForReviews} />
            </Link>
          </div>
        </div>
       
      )}
    </div>
  );
}
